export async function readFirstLine(file: File): Promise<string[]> {
  return new Promise<string[]>((resolve, reject) => {
    let reader = new FileReader();

    reader.onload = (event: ProgressEvent<FileReader>) => {
      let contents = event.target?.result as string;
      let firstLine = contents.split('\r\n')[0].replace(/"/g, '');
      let headers = firstLine!.split(',');
      resolve(headers);
    };

    reader.onerror = (event: ProgressEvent<FileReader>) => {
      reject(event.target?.error);
    };

    reader.readAsText(file.slice(0, 300));
  });
}

export function validateHeaders(
  actualHeaders: string[],
  expectedHeaders: string[]
): string[] {
  let errors: string[] = [];

  if (actualHeaders.length > expectedHeaders.length) {
    errors.push(
      `The number of columns is bigger than expected. Please check your file again.`
    );
    return errors;
  }

  const missingHeaderNames: string[] = checkHeaderNames(
    actualHeaders,
    expectedHeaders
  );
  if (missingHeaderNames.length > 0) {
    let headerError = `All columns are not present or columns are not named correctly. Missing columns: `;
    missingHeaderNames.forEach((missingHeader, index) => {
      const separator: string =
        index !== missingHeaderNames.length - 1 ? ', ' : '';
      headerError = headerError.concat(missingHeader + separator);
    });
    errors.push(`${headerError}. Please check your file again.`);
    return errors;
  }

  if (missingHeaderNames?.length === 0) {
    const headersInWrongOrder: string[] = checkHeadersOrder(
      actualHeaders,
      expectedHeaders
    );
    let wrongOrderColumns: string = '';
    headersInWrongOrder.forEach((wrongOrderHeader, index, array) => {
      const separator: string = index !== array.length - 1 ? ', ' : '';
      wrongOrderColumns = wrongOrderColumns.concat(wrongOrderHeader, separator);
    });
    if (wrongOrderColumns.length > 0) {
      errors.push(
        `Columns do not appear in the right order (${wrongOrderColumns}). Please check your file again.`
      );
      return errors;
    }
  }
  return errors;
}

function checkHeaderNames(
  fields: string[],
  expectedHeaders: string[]
): string[] {
  return expectedHeaders.filter((column) => !fields.includes(column));
}

function checkHeadersOrder(
  fields: string[],
  expectedHeaders: string[]
): string[] {
  let wrongOrderHeaders: string[] = [];
  expectedHeaders.forEach((column, index) => {
    if (fields.includes(column)) {
      if (fields[index] != column) {
        wrongOrderHeaders.push(column);
      }
    }
  });
  return wrongOrderHeaders;
}
