import { Directive, Host } from '@angular/core';
import { DxiColumnComponent } from 'devextreme-angular/ui/nested';
import {
  styleDefaultTrafficLight,
  styleMediumGeocodeLevel,
  styleStrongGeocodeLevel,
  styleWeakGeocodeLevel
} from 'src/app/spatial-modeller/helpers/geocoding-template.helper';

@Directive({
  selector: '[atlasDxiColumnGeocodeTemplate]'
})
export class AtlasDxiColumnGeocodeTemplateDirective {
  constructor(@Host() dataGridColumn: DxiColumnComponent) {
    dataGridColumn.cellTemplate = function (cellElement: any, cellInfo: any) {
      styleDefaultTrafficLight(cellInfo.text, cellElement);
      styleWeakGeocodeLevel(cellInfo.text, cellElement);
      styleMediumGeocodeLevel(cellInfo.text, cellElement);
      styleStrongGeocodeLevel(cellInfo.text, cellElement);
    };
  }
}
