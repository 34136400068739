import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MapService } from './map.service';
import {
  getIconBasedOnAttribute,
  getThematicColor
} from '../helpers/thematic-style-helper';
import { MapThematicScales } from '../types/map-thematic-scales';
import {
  buildMapLayerLegend,
  isLabelLayer
} from '../helpers/map-Legend-helper';
import { MapLayerTypes } from '../types/map-layer.types';

@Injectable({
  providedIn: 'root'
})
export class MapLayerStyleService {
  constructor(private mapService: MapService) {}

  private originalMapColours: Map<string, any[]> = new Map();
  private spotlightedDomainsOnLayer: Map<string, Set<string>> = new Map();
  private originalMapIcons: Map<string, any[]> = new Map();

  public initializeSpotLightLayerColors(layerId: string, colors: any[]) {
    if (!this.originalMapColours.has(layerId)) {
      this.originalMapColours.set(layerId, JSON.parse(JSON.stringify(colors))); // Deep clone
    }
    if (!this.spotlightedDomainsOnLayer.has(layerId)) {
      this.spotlightedDomainsOnLayer.set(layerId, new Set());
    }
  }

  public initializeSpotLightLayerIcons(layerId: string, icons: any[]) {
    if (!this.originalMapIcons.has(layerId)) {
      this.originalMapIcons.set(layerId, JSON.parse(JSON.stringify(icons))); // Deep clone
    }
    if (!this.spotlightedDomainsOnLayer.has(layerId)) {
      this.spotlightedDomainsOnLayer.set(layerId, new Set());
    }
  }

  public clearAllSpotLightLayers() {
    this.spotlightedDomainsOnLayer = new Map();
    this.originalMapColours = new Map();
    this.originalMapIcons = new Map();
  }

  public clearSpotLightLayerColors(layerId: string) {
    this.originalMapColours.delete(layerId);
    this.spotlightedDomainsOnLayer.delete(layerId);
    this.originalMapIcons.delete(layerId);
  }

  public setOriginalMapIcons(layerId: string, icons: any[]) {
    if (!this.originalMapIcons.has(layerId)) {
      this.originalMapIcons.set(layerId, [...icons]);
    }
  }

  public getOriginalMapIcons(layerId: string): any[] | null {
    return this.originalMapIcons.get(layerId) || null;
  }

  public setOriginalMapColours(layerId: string, colors: any[]) {
    if (!this.originalMapColours.has(layerId)) {
      this.originalMapColours.set(layerId, [...colors]);
    }
  }

  public getOriginalMapColours(layerId: string): any[] | null {
    return this.originalMapColours.get(layerId) || null;
  }

  public getSpotlightedDomainsOnLayer(layerId: string): Set<string> {
    return this.spotlightedDomainsOnLayer.get(layerId) || new Set();
  }

  public toggleSpotlight(layerId: string, domain: string) {
    const targetLayer = this.mapService.getLayer(layerId);
    if (!targetLayer) {
      console.warn(`Layer with ID "${layerId}" not found.`);
      return;
    }

    const deSelectColor = targetLayer.props.nonSpotlightColour;

    if (this.isLogoLayer(targetLayer)) {
      const deSelectIcon = targetLayer.props.nonSpotlightIcon;
      this.toggleLogoSpotlight(layerId, domain, deSelectIcon, targetLayer);
    } else {
      this.toggleGeneralSpotlight(layerId, domain, deSelectColor, targetLayer);
    }
  }

  public toggleLogoSpotlight(
    layerId: string,
    domain: string,
    deSelectIcon: string,
    targetLayer: any
  ) {
    const { type } = targetLayer.props;

    const allDomains = targetLayer.props.iconDomain;

    const originalIcons = this.getOriginalMapIcons(layerId);
    if (!originalIcons) {
      console.warn(`Original icons for layer "${layerId}" not found.`);
      return;
    }

    const spotlightedDomains = this.toggleDomainInSpotlight(layerId, domain);
    const updatedIcons = this.getUpdatedIcons(
      allDomains,
      spotlightedDomains,
      originalIcons,
      deSelectIcon
    );

    this.updateLayerWithIconSpotlight(layerId, targetLayer, updatedIcons);
  }

  // toggleGeneralSpotlight All layers expect Logos that have no thematic colors
  public toggleGeneralSpotlight(
    layerId: string,
    domain: string,
    deSelectColor: any[],
    targetLayer: any
  ) {
    const { type } = targetLayer.props;
    const { thematicScaleKey, scaleConfigKey } = this.getThematicKeys(type);

    const thematicConfig = targetLayer.props[scaleConfigKey];
    const { domain: allDomains } = thematicConfig;

    const originalColors = this.getOriginalMapColours(layerId);
    if (!originalColors) {
      console.warn(`Original colors for layer "${layerId}" not found.`);
      return;
    }

    const spotlightedDomains = this.toggleDomainInSpotlight(layerId, domain);
    const updatedColors = this.getUpdatedColors(
      allDomains,
      spotlightedDomains,
      originalColors,
      deSelectColor
    );

    this.updateLayerWithSpotlight(
      layerId,
      targetLayer,
      scaleConfigKey,
      thematicScaleKey,
      thematicConfig,
      updatedColors
    );
  }

  public changeLabelStyle(
    layerId: string,
    style: {
      getColor: number[];
      getBackgroundColor: number[];
      getBorderColor: number[];
      getBorderWidth: number;
      getSize: number;
      labelAttr: string;
    }
  ) {
    const targetLayer = this.mapService.getLayer(layerId);

    if (!targetLayer) {
      console.warn(`Layer with ID "${targetLayer}" not found.`);
      return;
    }

    const {
      getColor,
      getBackgroundColor,
      getBorderColor,
      getBorderWidth,
      getSize,
      labelAttr
    } = style;

    const updateLayerProps = {
      ...targetLayer,
      props: {
        ...(targetLayer.props || {}),
        getColor,
        getBackgroundColor,
        getBorderColor,
        getBorderWidth,
        getSize,
        labelAttr
      }
    };

    // Update the layer with new thematic configuration
    this.mapService.updateLayer(targetLayer.id, {
      getColor,
      getBackgroundColor,
      getBorderColor,
      getBorderWidth,
      getSize,
      labelAttr,

      legendInfo: buildMapLayerLegend(
        updateLayerProps.props.type,
        updateLayerProps.props
      ),
      updateTriggers: {
        getColor: [...getColor],
        labelAttr: [...labelAttr],
        getBackgroundColor: [...getBackgroundColor],
        getBorderColor: [...getBorderColor],
        getBorderWidth: getBorderWidth,
        getSize: getSize,
        legendInfo: JSON.stringify(updateLayerProps.props)
      }
    });
  }

  public changeThematicStyle(
    layerId: string,
    style: {
      fillThematicScale: MapThematicScales;
      fillThematicScaleConfig: {
        attr: string;
        domain: number[];
        colors: any;
      };
    }
  ) {
    const targetLayer = this.mapService.getLayer(layerId);

    if (!targetLayer) {
      console.warn(`Layer with ID "${targetLayer}" not found.`);
      return;
    }
    const { fillThematicScale, fillThematicScaleConfig } = style;

    const updateLayerProps = {
      ...targetLayer,
      props: {
        ...(targetLayer.props || {}),
        fillThematicScale,
        fillThematicScaleConfig
      }
    };

    // Update the layer with new thematic configuration
    this.mapService.updateLayer(targetLayer.id, {
      fillThematicScaleConfig: style.fillThematicScaleConfig,
      fillThematicScale: style.fillThematicScale,
      getFillColor: getThematicColor(
        fillThematicScale,
        fillThematicScaleConfig
      ),
      legendInfo: buildMapLayerLegend(
        updateLayerProps.props.type,
        updateLayerProps.props
      ),
      updateTriggers: {
        getFillColor: [...fillThematicScaleConfig.colors],
        legendInfo: JSON.stringify(updateLayerProps.props)
      }
    });
  }

  private isLogoLayer(layer: any) {
    const layerType = layer?.props?.type as MapLayerTypes | undefined;

    // Check if the type matches the desired values
    if (layerType === 'ThematicIcon' || layerType === 'ThematicIconTileSet') {
      return layer.props?.iconThematicScaleConfig === undefined ? true : false;
    }

    return false;
  }

  private updateLayerWithIconSpotlight(
    layerId: string,
    targetLayer: any,
    updatedIcons: any[]
  ): void {
    this.mapService.updateLayer(layerId, {
      icons: updatedIcons,
      getIcon: (props: any) => {
        return getIconBasedOnAttribute(
          targetLayer.props.iconAttribute,
          targetLayer.props.iconDomain,
          updatedIcons,
          targetLayer.props.iconSourceUrl,
          targetLayer.props.iconOther,
          targetLayer.props.iconMask,
          targetLayer.props.iconAnchorY,
          targetLayer.props.iconWidth,
          targetLayer.props.iconHeight,
          props
        );
      },

      updateTriggers: {
        getIconColor: [...updatedIcons],
        getIcon: [...updatedIcons]
      }
    });
  }

  private updateLayerWithSpotlight(
    layerId: string,
    targetLayer: any,
    scaleConfigKey: string,
    thematicScaleKey: string,
    thematicConfig: any,
    updatedColors: any[]
  ): void {
    const updatedConfig = {
      ...thematicConfig,
      colors: updatedColors
    };

    this.mapService.updateLayer(layerId, {
      [scaleConfigKey]: updatedConfig,
      [this.getColourKey(scaleConfigKey)]: getThematicColor(
        targetLayer.props[thematicScaleKey],
        updatedConfig
      ),
      updateTriggers: {
        [this.getColourKey(scaleConfigKey)]: updatedColors
      }
    });
  }

  private getColourKey(scaleConfigKey: string): string {
    if (scaleConfigKey === 'lineThematicScaleConfig') return 'getLineColor';
    if (scaleConfigKey === 'iconThematicScaleConfig') return 'getIconColor';
    return 'getFillColor';
  }

  private toggleDomainInSpotlight(
    layerId: string,
    domain: string
  ): Set<string> {
    const spotlightedDomains = this.getSpotlightedDomainsOnLayer(layerId);

    if (spotlightedDomains.has(domain)) {
      spotlightedDomains.delete(domain);
    } else {
      spotlightedDomains.add(domain);
    }

    this.spotlightedDomainsOnLayer.set(layerId, spotlightedDomains);
    return spotlightedDomains;
  }

  private getUpdatedIcons(
    allDomains: any[],
    spotlightedDomains: Set<string>,
    originalIcons: any[],
    deselectIcon: any
  ): any[] {
    return spotlightedDomains.size === 0
      ? originalIcons
      : allDomains.map((d, index) =>
          spotlightedDomains.has(d) ? originalIcons[index] : deselectIcon
        );
  }

  private getUpdatedColors(
    allDomains: any[],
    spotlightedDomains: Set<string>,
    originalColors: any[],
    deselectColor: any[]
  ): any[] {
    return spotlightedDomains.size === 0
      ? originalColors
      : allDomains.map((d, index) =>
          spotlightedDomains.has(d) ? originalColors[index] : deselectColor
        );
  }

  private getThematicKeys(type: string): {
    thematicScaleKey: string;
    scaleConfigKey: string;
  } {
    switch (type) {
      case 'ThematicLine':
      case 'ThematicLineTileSet':
        return {
          thematicScaleKey: 'lineThematicScale',
          scaleConfigKey: 'lineThematicScaleConfig'
        };
      case 'ThematicIcon':
      case 'ThematicIconTileSet':
        return {
          thematicScaleKey: 'iconThematicScale',
          scaleConfigKey: 'iconThematicScaleConfig'
        };
      default:
        return {
          thematicScaleKey: 'fillThematicScale',
          scaleConfigKey: 'fillThematicScaleConfig'
        };
    }
  }
}
