import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Auth } from '@aws-amplify/auth';
import { BehaviorSubject, from, Observable, of, throwError } from 'rxjs';
import { AuthOptions } from '../models/auth-options';
import * as fromGazetteerStore from 'src/app/shared/atlas-gazetteer/store';
import * as fromScenarioStore from 'src/app/spatial-modeller-store';
import * as fromLocatorStore from 'src/app/locator-store';
import * as fromProfilerStore from 'src/app/profiler-store';
import * as fromUIStore from 'src/app/core/store';
import { Store } from '@ngrx/store';
import { searchTextCleared } from 'src/app/shared/atlas-gazetteer/store/actions/gazetteer.actions';
import { catchError, switchMap, take } from 'rxjs/operators';
import { clearScenarioStateOnLogoff } from 'src/app/spatial-modeller-store/actions/scenario.actions';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {
  logoutClearSpotlightLayers,
  resetChangeLocationState
} from 'src/app/core/store/actions/spatial-modeller-ui.actions';
import { clearLocatorLibraryStateOnLogoff } from 'src/app/locator-store/actions/locator-library.actions';
import { resetHasLocatorFeatureLoaded } from 'src/app/core/store/actions/locator-ui.actions';
import { clearProfilerLibraryStateOnLogoff } from 'src/app/profiler-store/actions/profiler-library.actions';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { clearAppFeaturesStateOnLogoff } from 'src/app/core/store/actions/app-feature-ui.actions';
import { UserLoginUsageService } from './user-login-usage.service';
import { DataDogService } from 'src/app/core/services/datadog.service';
import { MapLayerStyleService } from 'src/app/shared/atlas-mapping/services/map-layer-style-service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  userInfo$: BehaviorSubject<any> = new BehaviorSubject(null);
  authServiceError$: BehaviorSubject<string> = new BehaviorSubject('');
  successActionText$: BehaviorSubject<string> = new BehaviorSubject('');
  currentJwtToken$: BehaviorSubject<string> = new BehaviorSubject('');
  currentRoute: string;
  resetPasswordSuccessText =
    'Password has been successfully reset. Please login using your email and new password.';
  private currentJwtToken: string = '';
  private authInfo = {
    username: '',
    password: ''
  };

  constructor(
    private router: Router,
    private gazetteerStore$: Store<fromGazetteerStore.State>,
    private scenarioStore$: Store<fromScenarioStore.State>,
    private locatorStore$: Store<fromLocatorStore.State>,
    private profilerStore$: Store<fromProfilerStore.State>,
    private localStorageService: LocalStorageService,
    private UIStore$: Store<fromUIStore.State>,
    private http: HttpClient,
    private loginUsageService: UserLoginUsageService,
    private datadogService: DataDogService
  ) {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        this.currentRoute = event.url;
      }
    });

    this.getJwtToken()
      .pipe(
        take(1),
        catchError(() => of(null)) // Hiding the error message to the user
      )
      .subscribe();
    this.getUserInfo();
  }

  public setAuthAction(action: AuthOptions) {
    this.router.navigate([`/${action}`]);
  }

  public removeErrorMessage() {
    this.authServiceError$.next('');
  }

  public removeSuccessActionMessage() {
    this.successActionText$.next('');
  }

  public signUp(
    firstName: string,
    surname: string,
    tenantId: number,
    email: string,
    password: string
  ) {
    const user = {
      username: email,
      password: password,
      attributes: {
        email: email,
        given_name: firstName,
        family_name: surname,
        'custom:tenant_id': `${tenantId}`
      }
    };
    Auth.signUp(user)
      .then((data) => {
        this.authInfo.username = email;
        this.authServiceError$.next('');
        this.router.navigate(['/verify-new-user']);
      })
      .catch((err) => {
        this.authServiceError$.next(
          'Error: user has not been created. Please fill the form with the information required'
        );
      });
  }

  public verifySignupCode(verificationCode: string) {
    // After retrieving the confirmation code from the user
    Auth.confirmSignUp(this.authInfo.username, verificationCode, {
      // Optional. Force user confirmation irrespective of existing alias. By default set to True.
      forceAliasCreation: true
    })
      .then((data) => {
        this.clearAuthInfo();
        this.router.navigate(['/login']);
      })
      .catch((err) =>
        this.authServiceError$.next(
          'Error: user has not been created. Please fill the form with the information required'
        )
      );
  }

  public login(email: string, password: string) {
    this.authInfo = {
      username: email,
      password: password
    };

    this.removeSuccessActionMessage();

    Auth.signIn(this.authInfo)
      .then((user) => {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          this.setUser(user);
          this.router.navigate(['/first-time-login']);
        } else if (user.challengeName === 'SMS_MFA') {
          this.setUser(user, false);
          this.router.navigate(['/mfa-verification']);
        } else {
          this.setUser(user);
          this.router.navigate(['/home']);
          this.getJwtToken()
            .pipe(
              take(1),
              catchError(() => of(null)) // Hiding the error message to the user
            )
            .subscribe();
          this.CheckUserIfExist().pipe(take(1)).subscribe();
          this.loginUsageService.updateLoginUsage().pipe(take(1)).subscribe();
        }
      })
      .catch((err) => {
        this.setInvalidUser('Invalid email or password. Please try again');
      });
  }

  public verifyLoginCode(verificationCode: string) {
    this.userInfo$.pipe(take(1)).subscribe((userInfo: any) => {
      if (userInfo) {
        Auth.confirmSignIn(userInfo, verificationCode, 'SMS_MFA')
          .then((user) => {
            this.getUserInfo();
            this.getJwtToken()
              .pipe(
                take(1),
                catchError(() => of(null)) // Hiding the error message to the user
              )
              .subscribe();
            this.CheckUserIfExist().pipe(take(1)).subscribe();
            this.loginUsageService.updateLoginUsage().pipe(take(1)).subscribe();
          })
          .catch((err) => {
            let errorMessage: string = '';
            switch (err.code) {
              case 'CodeMismatchException':
                errorMessage =
                  'The code you have entered is invalid. Please check the code again before reentering.';
                break;
              case 'NotAuthorizedException':
                errorMessage =
                  'The code you have entered has expired after 15 minutes.';
                break;
              default:
                errorMessage =
                  'The code you have entered is invalid. Please check the code again before reentering.';
            }
            this.authServiceError$.next(errorMessage);
          });
      }
    });
  }

  // Neither Amplify nor Cognito offer a resend MFA code method.
  // A workaround has to be implemented from:
  // https://github.com/aws-amplify/amplify-js/issues/6676
  public resendMFALoginCode() {
    Auth.signIn(this.authInfo)
      .then((user) => {
        this.setUser(user, false);
        if (user.challengeName === 'SMS_MFA') {
          this.router.navigate(['/mfa-verification']);
        }
      })
      .catch((err) => {
        this.setInvalidUser('Error resending the MFA verification code.');
      });
  }

  getCurrentJwtToken() {
    return this.currentJwtToken;
  }

  private setJWTToken(token: string) {
    if (this.currentJwtToken !== token) {
      this.currentJwtToken = token;
      this.currentJwtToken$.next(token);
    }
  }

  private setUser(user: any, clearAuthInfo: boolean = true) {
    this.userInfo$?.next(user);
    this.authServiceError$.next('');
    if (clearAuthInfo) {
      this.clearAuthInfo();
    }

    if (user) {
      this.clearCachedLibrariesAndScenariosIfTenantChanged(user);
    }
  }

  private clearCachedLibrariesAndScenariosIfTenantChanged(user: any) {
    let lastAccessedTenant = this.localStorageService.get('tenant-name');

    if (user.attributes) {
      if (lastAccessedTenant != user.attributes['custom:tenant_name']) {
        this.localStorageService.set('locator-library-id', 0);
        this.localStorageService.set('profiler-library-id', 0);
        this.localStorageService.set('scenario-id', 0);
        this.localStorageService.set('selected-feature', '');
      }

      this.localStorageService.set(
        'tenant-name',
        user.attributes['custom:tenant_name']
      );

      this.datadogService.addGlobalContextProperty(
        'tenant-name',
        user.attributes['custom:tenant_name']
      );
    }
  }

  private setInvalidUser(errorMessage: string) {
    this.authServiceError$.next(errorMessage);
    this.currentRoute !== '/legal'
      ? this.router.navigate(['/'])
      : this.router.navigate(['/legal']);
    this.userInfo$?.next(null);
  }

  private CheckUserIfExist() {
    let url = `${environment.baseUrl}api/users/user-exist`;
    return this.http.get(url);
  }

  private clearAuthInfo() {
    this.authInfo = {
      username: '',
      password: ''
    };
  }

  public logout() {
    Auth.signOut({ global: true })
      .then((data) => {
        this.gazetteerStore$.dispatch(searchTextCleared());
        this.scenarioStore$.dispatch(clearScenarioStateOnLogoff());
        this.locatorStore$.dispatch(clearLocatorLibraryStateOnLogoff());
        this.profilerStore$.dispatch(clearProfilerLibraryStateOnLogoff());

        this.UIStore$.dispatch(resetChangeLocationState());
        this.UIStore$.dispatch(resetHasLocatorFeatureLoaded());
        this.UIStore$.dispatch(clearAppFeaturesStateOnLogoff());

        this.UIStore$.dispatch(logoutClearSpotlightLayers());

        this.setInvalidUser('');
      })
      .catch((err) => {
        this.setInvalidUser('');
      });
  }

  public forgotPassword() {
    this.removeErrorMessage();
    this.removeSuccessActionMessage();
    this.router.navigate(['/reset-password']);
  }

  public sendEmailForgotPassword(email: string) {
    Auth.forgotPassword(email)
      .then((data) => {
        this.authInfo.username = email;
        this.authInfo.password = '';
        this.router.navigate(['/verify-new-password']);
      })
      .catch((err) => {
        this.authServiceError$.next(`Error: ${err.message}`);
      });
  }

  public confirmPasswordAfterForgotPassword(
    verificationCode: string,
    newPassword: string
  ) {
    this.userInfo$?.next(null);
    this.isEmailAvailable()
      ? Auth.forgotPasswordSubmit(
          this.authInfo.username,
          verificationCode,
          newPassword
        )
          .then((data) => {
            this.clearAuthInfo();
            this.redirectAfterPasswordReset();
          })
          .catch((err) => {
            if (err?.code === 'InvalidPasswordException') {
              this.authServiceError$.next(
                `Error: please double check the password requirements and try again.`
              );
            } else {
              this.authServiceError$.next(`Error: ${err.message}`);
            }
          })
      : this.router.navigate(['/login']);
  }

  public setPasswordAfterFirstTimeLogin(newPassword: string) {
    this.userInfo$.pipe(take(1)).subscribe((userInfo: any) => {
      if (userInfo) {
        Auth.completeNewPassword(userInfo, newPassword)
          .then(() => {
            this.redirectAfterPasswordReset();
          })
          .catch((err) => {
            if (err?.code === 'InvalidPasswordException') {
              this.authServiceError$.next(
                `Error: please double check your password requirements and try again.`
              );
            } else {
              this.authServiceError$.next(`Error: ${err.message}`);
            }
          });
      }
    });
  }

  private redirectAfterPasswordReset() {
    this.removeErrorMessage();
    this.successActionText$.next(this.resetPasswordSuccessText);
    this.router.navigate(['/login']);
  }

  public getUserInfo() {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        this.setUser(user);
        this.router.navigate(['/home']);
      })
      .catch((err) => {
        this.setInvalidUser('');
      });
  }

  public isEmailAvailable() {
    return this.authInfo.username !== '';
  }

  public federateSignIn() {
    Auth.federatedSignIn({ customProvider: 'AzureAD' });
    this.getJwtToken()
      .pipe(
        take(1),
        catchError(() => of(null)) // Hiding the error message to the user
      )
      .subscribe();
  }

  public isUserAuthenticated() {
    return from(Auth.currentAuthenticatedUser()).pipe(
      switchMap(() => {
        return of(true);
      }),
      catchError(() => {
        return of(false);
      })
    );
  }

  public getJwtToken(): Observable<string> {
    return from(Auth.currentSession()).pipe(
      switchMap((session: any) => {
        const jwtToken = session.getIdToken().getJwtToken();
        this.setJWTToken(jwtToken);
        return of(jwtToken);
      }),
      catchError((err: any) => {
        this.setJWTToken('');
        this.logout();
        return throwError(err);
      })
    );
  }
}
