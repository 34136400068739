import { HttpClient } from '@angular/common/http';
import { Part } from '@aws-sdk/client-s3';
import { firstValueFrom } from 'rxjs';

// The size at which point to upload using multipart upload
const singleFileUploadSize = 10 * 1024 * 1024; // 10MB in bytes

// Each mulitpart part size must be at least 5MB
const multipartFileUploadMinimumSize = 5 * 1024 * 1024; // 5MB in bytes

export async function uploadFileInOneGo(
  file: File,
  httpClient: HttpClient,
  atlasImportUrl: string,
  guidFileName: string
) {
  const fileData = await readFileAsText(file);
  const response: any = await getPresignedUrl(
    httpClient,
    guidFileName,
    atlasImportUrl
  );
  await uploadFileAsText(httpClient, response.presignedUrl, fileData);
}

export async function uploadFileMultiPart(
  file: File,
  uploadId: string,
  guidFileName: string,
  httpClient: HttpClient,
  atlasImportUrl: string
) {
  let eTagParts: Part[] = [];
  const partCount = Math.floor(file.size / multipartFileUploadMinimumSize);
  const uploadPromises: Promise<void>[] = [];
  const partSize = Math.ceil(file.size / partCount);

  // Divide file by partCount and upload each part in parallel.
  for (let i = 0; i < partCount; i++) {
    const start = i * partSize;
    const end = start + partSize;
    const partNumber = i + 1;

    // Set up all the uploads as async functions that return a promise.
    uploadPromises.push(
      (async () => {
        const response: any = await getMultipartPresignedUrl(
          httpClient,
          guidFileName,
          uploadId,
          partNumber,
          atlasImportUrl
        );
        const fileDataChunk = await readFileChunk(file, start, end);
        const uploadResponse: any = await uploadFileAsBuffer(
          httpClient,
          response.presignedUrl,
          fileDataChunk
        );
        const etag = uploadResponse.headers.get('ETag')!.replace(/"/g, '');
        eTagParts.push({ ETag: etag, PartNumber: partNumber });
      })()
    );
  }

  // Invoke all the upload functions and wait for completion.
  await Promise.all(uploadPromises);
  return eTagParts;
}

async function uploadFileAsText(
  httpClient: HttpClient,
  url: string,
  fileData: any
) {
  const headers = { 'Content-Type': 'text/csv' };
  const httpResponse$ = httpClient.put(url, fileData, { headers });
  return await firstValueFrom(httpResponse$);
}

async function readFileAsText(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e: any) => resolve(e.target.result);
    reader.onerror = (e: any) => reject(e.target.error);
    reader.readAsText(file);
  });
}

async function getPresignedUrl(
  httpClient: HttpClient,
  filename: string,
  atlasImportUrl: string
) {
  return httpClient
    .get<{ presignedUrl: string }>(
      `${atlasImportUrl}/presigned-url/${filename}`
    )
    .toPromise();
}

async function getMultipartPresignedUrl(
  httpClient: HttpClient,
  filename: string,
  uploadId: string,
  partNumber: number,
  atlasImportUrl: string
) {
  const httpResponse$ = httpClient.get<{ presignedUrl: string }>(
    `${atlasImportUrl}/presigned-url/${filename}/${uploadId}/${partNumber}`
  );
  return await firstValueFrom(httpResponse$);
}

async function uploadFileAsBuffer(
  httpClient: HttpClient,
  url: string,
  data: ArrayBuffer
) {
  const headers = { 'Content-Type': 'application/octet-stream' };
  const httpResponse$ = httpClient.put(url, data, {
    headers,
    observe: 'response'
  });
  return await firstValueFrom(httpResponse$);
}

async function readFileChunk(
  file: File,
  start: number,
  end: number
): Promise<ArrayBuffer> {
  const fileSlice = file.slice(start, end);
  return await fileSlice.arrayBuffer();
}
