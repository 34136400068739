import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';

export const isAnyFeatureAllowed = createSelector(
  fromFeature.getAppFeatureUIState,
  (state) =>
    state?.locatorFeature ||
    state?.spatialModellerFeature ||
    state?.profilerFeature
);

export const getInitialMapView = createSelector(
  fromFeature.getAppFeatureUIState,
  (state) =>
    state && {
      latitude: state.initialMapPosition.latitude,
      longitude: state.initialMapPosition.longitude,
      zoom: state.zoom
    }
);

export const getRemoveSystemLayers = createSelector(
  fromFeature.getAppFeatureUIState,
  (state) => state && state.removeSystemLayers
);

export const getPolygonDefaults = createSelector(
  fromFeature.getAppFeatureUIState,
  (state) =>
    state && {
      steps: state.locatorShapeDefaults?.polygon?.steps,
      radiusInKilometers:
        state.locatorShapeDefaults?.polygon?.radiusInKilometers
    }
);

export const getLocatorShapesDefaults = createSelector(
  fromFeature.getAppFeatureUIState,
  (state) => state && state.locatorShapeDefaults
);

export const getNetworkPlanningDefaults = createSelector(
  fromFeature.getAppFeatureUIState,
  (state) => state && state.networkPlanningDefaults
);

export const getDefaultDistanceUnit = createSelector(
  fromFeature.getAppFeatureUIState,
  (state) => state && state.distanceUnit
);

export const getAppSelectedFeature = createSelector(
  fromFeature.getUIModuleState,
  (state) =>
    state &&
    determineSelectedFeature(
      state.spatialModellerUI.featureSelected,
      state.locatorUI.featureSelected,
      state.profilerUI.featureSelected
    )
);

export const getSpatialModellerLiveModeDisabled = createSelector(
  fromFeature.getAppFeatureUIState,
  (state) =>
    state && state.networkPlanningDefaults
      ? state.networkPlanningDefaults.liveModeDisabled
      : false
);

export const getNetworkPlanningReadonlySystem = createSelector(
  fromFeature.getAppFeatureUIState,
  (state) =>
    state && state.networkPlanningDefaults
      ? state.networkPlanningDefaults.readOnlySystem
      : false
);

export const getNetworkPlanningUseTilesetForBaseLayers = createSelector(
  fromFeature.getAppFeatureUIState,
  (state) =>
    state && state.networkPlanningDefaults
      ? state.networkPlanningDefaults.useTilesetForBaseLayers
      : false
);

export const getNetworkPlaningDisableImportLocations = createSelector(
  fromFeature.getAppFeatureUIState,
  (state) =>
    state && state.networkPlanningDefaults
      ? state.networkPlanningDefaults.disableImportLocations
      : false
);

export const getDefaultDriveCatchmentSpeed = createSelector(
  fromFeature.getAppFeatureUIState,
  (state) => state && state.driveCatchmentSpeed
);

export const getDefaultDisableZoomToExtent = createSelector(
  fromFeature.getAppFeatureUIState,
  (state) => state && state.disableZoomToExtent
);

export const getDefaultHideLegendOnMapDownload = createSelector(
  fromFeature.getAppFeatureUIState,
  (state) => state && state.hideLegendOnMapDownload
);

export const getHideUsageSection = createSelector(
  fromFeature.getAppFeatureUIState,
  (state) => state && state.hideUsageSection
);

export const getDefaultTooltipLayout = createSelector(
  fromFeature.getAppFeatureUIState,
  (state) => state && state.tooltipLayout
);

export const getGazetteerCountryRestriction = createSelector(
  fromFeature.getAppFeatureUIState,
  (state) => state && state.gazetteerCountryRestriction
);

export const allowInternalUsersUsage = createSelector(
  fromFeature.getAppFeatureUIState,
  (state) => state && state.allowInternalUsersUsage
);

export const getDisableImportLocations = createSelector(
  fromFeature.getAppFeatureUIState,
  (state) =>
    state && state.catchmentReportingDefaults
      ? state.catchmentReportingDefaults.disableImportLocations
      : false
);

export const getCatchmentReportingReadonlySystem = createSelector(
  fromFeature.getAppFeatureUIState,
  (state) =>
    state && state.catchmentReportingDefaults
      ? state.catchmentReportingDefaults.readOnlySystem
      : false
);

export function determineSelectedFeature(
  isSpatialModellerFeatureSelected: boolean,
  isLocatorFeatureSelected: boolean,
  isProfilerFeatureSelected: boolean
) {
  if (isSpatialModellerFeatureSelected) {
    return 'SpatialModeler';
  }

  if (isLocatorFeatureSelected) {
    return 'Locator';
  }

  if (isProfilerFeatureSelected) {
    return 'Profiler';
  }

  return '';
}

export const isSmFeatureDisplayedInGazetteerCarousel = createSelector(
  fromFeature.getUIModuleState,
  (state) =>
    state &&
    state.spatialModellerUI.spatialModellerFeatureAllowed &&
    (state.locatorUI.locatorFeatureAllowed ||
      state.profilerUI.profilerFeatureAllowed)
);

export const isProfilerDisplayedInGazetteerCarousel = createSelector(
  fromFeature.getUIModuleState,
  (state) =>
    state &&
    state.profilerUI.profilerFeatureAllowed &&
    (state.locatorUI.locatorFeatureAllowed ||
      state.spatialModellerUI.spatialModellerFeatureAllowed)
);

export const isCRReadOnlyDisplayedInGazetteerCarousel = createSelector(
  fromFeature.getUIModuleState,
  (state) =>
    state &&
    state.locatorUI.locatorFeatureAllowed &&
    state.appFeatureUI.catchmentReportingDefaults != null &&
    state.appFeatureUI.catchmentReportingDefaults?.readOnlySystem &&
    (state.profilerUI.profilerFeatureAllowed ||
      state.spatialModellerUI.spatialModellerFeatureAllowed)
);

export const isProfilerAndSMFeatureAllowed = createSelector(
  fromFeature.getUIModuleState,
  (state) =>
    state.profilerUI.profilerFeatureAllowed ||
    state.spatialModellerUI.spatialModellerFeatureAllowed
);

export const isDefaultMultiCatchmentDisplayedInGazetteerCarousel =
  createSelector(
    fromFeature.getUIModuleState,
    isProfilerAndSMFeatureAllowed,
    (state, isProfilerAndSMAllowed) =>
      state &&
      state.locatorUI.locatorFeatureAllowed &&
      state.appFeatureUI.catchmentReportingDefaults != null &&
      state.appFeatureUI.catchmentReportingDefaults?.multiCatchmentMode &&
      isProfilerAndSMAllowed
  );

export const getCatchmentReportingUseLibraryLocationsLayer = createSelector(
  fromFeature.getAppFeatureUIState,
  (state) =>
    state && state.catchmentReportingDefaults
      ? state.catchmentReportingDefaults.showLibraryLocationsLayer
      : false
);

export const getCatchmentReportingImportUsingGeocoding = createSelector(
  fromFeature.getAppFeatureUIState,
  (state) =>
    state && state.catchmentReportingDefaults
      ? state.catchmentReportingDefaults.importUsingGeocoding
      : false
);

export const getNetworkPlanningImportUsingGeocoding = createSelector(
  fromFeature.getAppFeatureUIState,
  (state) =>
    state && state.networkPlanningDefaults
      ? state.networkPlanningDefaults?.importUsingGeocoding
      : false
);

export const getIsUATMode = createSelector(
  fromFeature.getAppFeatureUIState,
  (state) => state && state.isUATMode
);

export const showSummaryStatsReport = createSelector(
  fromFeature.getAppFeatureUIState,
  (state) =>
    state && state.catchmentReportingDefaults
      ? state.catchmentReportingDefaults.showSummaryStatsReport
      : false
);

export const getDefaultIsSimpleStatsView = createSelector(
  fromFeature.getAppFeatureUIState,
  (state) => state && state.isSimpleStatsView
);

export const getDefaultMultiCatchmentMode = createSelector(
  fromFeature.getAppFeatureUIState,
  (state) =>
    state && state.catchmentReportingDefaults
      ? state.catchmentReportingDefaults.multiCatchmentMode
      : false
);

export const getDefaultMultipleCatchmentsSelection = createSelector(
  fromFeature.getAppFeatureUIState,
  (state) =>
    state && state.catchmentReportingDefaults?.multipleCatchmentsSelection
);

export const getDefaultApplyMultiCatchmentsToExistingLocations = createSelector(
  fromFeature.getAppFeatureUIState,
  (state) =>
    state && state.catchmentReportingDefaults
      ? state.catchmentReportingDefaults.applyMultiCatchmentsToExistingLocations
      : false
);
