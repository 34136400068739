import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { Point } from 'src/app/shared/atlas-gazetteer/models/point.model';

import { environment } from 'src/environments/environment';
import { LocatorLibrary } from '../models/locator-library';
import { LocatorLibraryData } from '../models/locator-library-data';
import { LocatorLofiQueryResult } from '../models/locator-lofi-report';
import { LocatorPowerBiReportingInfo } from '../models/locator-power-bi-reporting-info';
import { LocatorShape } from '../models/locator-shape';
import { LocatorShapeTypes } from '../types/locator-shape.types';
import { LocatorReferenceLibrary } from '../models/locator-reference-library';
import { DriveCatchmentSpeeds } from 'src/app/core/enums/drive-catchment-speeds.enum';
import { LoadOptions } from 'devextreme/data';
import { LocatorPaginationResult } from '../models/locator-pagination-result';
import { map, switchMap, takeWhile, timer } from 'rxjs';
import { LocatorImportStatus } from '../models/locator-import-status';
import { Store } from '@ngrx/store';
import * as appFeatureStore from 'src/app/core/store';
import * as locatorStore from 'src/app/locator-store';

@Injectable({ providedIn: 'root' })
export class LocatorService {
  private atlasLocatorLibrariesApiUrl = `${environment.baseUrl}api/locator-libraries/`;
  private atlasLocatorReportingInfoApiUrl = `${environment.baseUrl}api/locator/`;

  statusPollDelay = 1000;
  isLocatorFeatureSelected: boolean = false;
  currentLocatorLibraryId: number;

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private appFeatureStore$: Store<appFeatureStore.State>,
    private locatorStore$: Store<locatorStore.State>
  ) {
    this.appFeatureStore$
      .select(appFeatureStore.isLocatorFeatureSelected)
      .subscribe(
        (isLocatorFeatureSelected) =>
          (this.isLocatorFeatureSelected = isLocatorFeatureSelected)
      );

    this.locatorStore$
      .select(locatorStore.getLocatorLibraryId)
      .subscribe((libraryId) => (this.currentLocatorLibraryId = libraryId));
  }

  getAllLibraries() {
    return this.http.get<LocatorLibrary[]>(
      `${this.atlasLocatorLibrariesApiUrl}`
    );
  }

  createLibrary(libraryRequest: { name: string }) {
    return this.http.post<LocatorLibrary>(
      `${this.atlasLocatorLibrariesApiUrl}`,
      libraryRequest
    );
  }

  updateLibrary(
    id: number,
    libraryRequest: {
      name: string;
    }
  ) {
    return this.http.put<LocatorLibrary>(
      `${this.atlasLocatorLibrariesApiUrl}${id}`,
      libraryRequest
    );
  }

  getLocatorLibraryById(id: number) {
    return this.http.get<LocatorLibrary>(
      `${this.atlasLocatorLibrariesApiUrl}${id}`
    );
  }

  getLocatorDataById(locatorDataId: number) {
    return this.http.get<LocatorLibraryData>(
      `${this.atlasLocatorLibrariesApiUrl}library-data/${locatorDataId}`
    );
  }

  getLocatorDataByLibrarySearch(id: number, loadOptions: LoadOptions) {
    const isNotEmpty = (value: unknown) =>
      value !== undefined && value !== null && value !== '';
    let params: HttpParams = new HttpParams();
    [
      'filter',
      'group',
      'groupSummary',
      'parentIds',
      'requireGroupCount',
      'requireTotalCount',
      'searchExpr',
      'searchOperation',
      'searchValue',
      'select',
      'sort',
      'skip',
      'take',
      'totalSummary',
      'userData'
    ].forEach(function (i) {
      const optionValue = loadOptions[i as keyof LoadOptions];
      if (i in loadOptions && isNotEmpty(optionValue)) {
        params = params.set(i, JSON.stringify(optionValue));
      }
    });

    return this.http.get<LocatorPaginationResult>(
      `${this.atlasLocatorLibrariesApiUrl}${id}/library-data-search`,
      { params }
    );
  }

  getLastedEditedLocatorLibrary() {
    return this.http.get<LocatorLibrary>(
      `${this.atlasLocatorLibrariesApiUrl}last-edited`
    );
  }

  getLofiReportForLibraryAndShape(
    libraryId: number,
    libraryDataPointId: number,
    shapeId: number
  ) {
    return this.http.get<LocatorLofiQueryResult[]>(
      `${this.atlasLocatorLibrariesApiUrl}${libraryId}/library-data/${libraryDataPointId}/shapes/${shapeId}/lofi-report`
    );
  }

  getReferenceLibraries() {
    return this.http.get<LocatorReferenceLibrary[]>(
      `${this.atlasLocatorReportingInfoApiUrl}reference-libraries`
    );
  }

  importReferenceLibrary(currentLibraryId: number, referenceLibraryId: number) {
    let request = {
      CurrentLibraryId: currentLibraryId,
      ReferenceLibraryId: referenceLibraryId
    };
    return this.http.post<LocatorLibrary>(
      `${this.atlasLocatorReportingInfoApiUrl}reference-libraries/import`,
      request
    );
  }

  canReferenceLibraryBeImported(referenceLibraryId: number, libraryId: number) {
    return this.http.get<any>(
      `${this.atlasLocatorReportingInfoApiUrl}reference-libraries/${referenceLibraryId}/libraries/${libraryId}/exceeded-library-limit`
    );
  }

  //TODO Remove When Atlas-263 implementation is finished
  getPowerBiReportingInfo() {
    return this.http.get<LocatorPowerBiReportingInfo>(
      `${this.atlasLocatorReportingInfoApiUrl}reporting-info`
    );
  }

  getPowerBiReportCollection() {
    return this.http.get<LocatorPowerBiReportingInfo[]>(
      `${this.atlasLocatorReportingInfoApiUrl}pbi-reports`
    );
  }

  createLocatorLibraryTemporaryPoint(
    libraryId: number,
    location: Point,
    name: string,
    freeFormGeojson: string,
    freeformShapeName: string
  ) {
    if (name.trim().length == 0) {
      name = 'Temp_' + Math.round(new Date().getTime() / 1000);
    }

    var defaultShape = this.localStorageService.get('locator-default-shape');

    return this.http.post<LocatorLibraryData>(
      `${this.atlasLocatorLibrariesApiUrl}${libraryId}/library-data?defaultshape=${defaultShape}`,
      {
        id: 0,
        name: name,
        libraryId: libraryId,
        latitude: location.latitude,
        longitude: location.longitude,
        isTemporary: false,
        FreeFormGeoJson: freeFormGeojson,
        freeformShapeName
      }
    );
  }

  deleteLocatorLibrary(libraryId: number) {
    return this.http.delete(`${this.atlasLocatorLibrariesApiUrl}${libraryId}`);
  }

  generateLofiReportForLibraryAndShape(
    libraryId: number,
    locationDataId: number,
    shapeId: number
  ) {
    return this.http.post<LocatorLofiQueryResult[]>(
      `${this.atlasLocatorLibrariesApiUrl}${libraryId}/library-data/${locationDataId}/shapes/${shapeId}/lofi-report`,
      {}
    );
  }

  generateShapeReportData(
    libraryId: number,
    locationDataId: number,
    shapeIds: number[]
  ) {
    return this.http.post(
      `${this.atlasLocatorLibrariesApiUrl}${libraryId}/library-data/${locationDataId}/shapes/reporting-info`,
      shapeIds
    );
  }

  createLocatorShapes(
    libraryId: number,
    locationDataIds: number[],
    shapeType: LocatorShapeTypes,
    shapeRanges: number[],
    freeFormGeoJson: string | null,
    freeFormName: string | null,
    driveCatchmentSpeed: DriveCatchmentSpeeds | null
  ) {
    return this.http.put<LocatorShape[]>(
      `${this.atlasLocatorLibrariesApiUrl}${libraryId}/library-data/shapes`,
      {
        locationDataIds,
        shapeRanges,
        shapeType,
        freeFormGeoJson,
        freeFormName,
        driveCatchmentSpeed: driveCatchmentSpeed
      }
    );
  }

  updateLocatorShapes(
    libraryId: number,
    locationDataId: number,
    shapeId: number,
    shapeRange: number,
    freeFormGeoJson: string | null,
    freeFormName: string | null,
    driveCatchmentSpeed: DriveCatchmentSpeeds | null
  ) {
    return this.http.post<LocatorShape>(
      `${this.atlasLocatorLibrariesApiUrl}${libraryId}/library-data/${locationDataId}/shapes/${shapeId}`,
      {
        shapeRange,
        freeFormGeoJson,
        freeFormName,
        driveCatchmentSpeed: driveCatchmentSpeed
      }
    );
  }

  updateLocationName(
    libraryId: number,
    libraryDataId: number,
    libraryRequest: {
      name: string;
    }
  ) {
    return this.http.put<LocatorLibraryData>(
      `${this.atlasLocatorLibrariesApiUrl}${libraryId}/library-data/${libraryDataId}`,
      libraryRequest
    );
  }

  deleteLocatorLocationShape(
    libraryId: number,
    libraryDataId: number,
    shapeId: number
  ) {
    return this.http.delete(
      `${this.atlasLocatorLibrariesApiUrl}${libraryId}/library-data/${libraryDataId}/shapes/${shapeId}`
    );
  }

  deleteLocations(libraryId: number, libraryDataIds: number[]) {
    return this.http.delete(
      `${this.atlasLocatorLibrariesApiUrl}${libraryId}/library-data`,
      { body: libraryDataIds }
    );
  }

  logPowerBiReportUsage() {
    return this.http.post(
      `${this.atlasLocatorLibrariesApiUrl}power-bi-report-usage`,
      {}
    );
  }

  sharePowerBIReportData(
    libraryId: number,
    locationDataId: number,
    shapeIds: number[]
  ) {
    return this.http.post(
      `${this.atlasLocatorLibrariesApiUrl}${libraryId}/library-data/${locationDataId}/shapes/power-bi-report/shared`,
      shapeIds
    );
  }

  updatePolygonDescription(
    libraryId: number,
    locationDataId: number,
    shapeId: number,
    name: string | null
  ) {
    return this.http.post<LocatorShape>(
      `${this.atlasLocatorLibrariesApiUrl}${libraryId}/library-data/${locationDataId}/shapes/${shapeId}/description`,
      {
        name
      }
    );
  }

  checkLocationReportDataShared(libraryId: number, locationDataId: number) {
    return this.http.get<boolean>(
      `${this.atlasLocatorLibrariesApiUrl}${libraryId}/library-data/${locationDataId}/shared-data`,
      {}
    );
  }

  checkShapeReportDataShared(
    libraryId: number,
    locationDataId: number,
    shapeId: number
  ) {
    return this.http.get<boolean>(
      `${this.atlasLocatorLibrariesApiUrl}${libraryId}/library-data/${locationDataId}/shapes/${shapeId}/shared-data`,
      {}
    );
  }

  checkLibraryReportDataShared(libraryId: number) {
    return this.http.get<boolean>(
      `${this.atlasLocatorLibrariesApiUrl}${libraryId}/shared-data`,
      {}
    );
  }

  checkLocationNameExists(libraryId: number, name: string) {
    return this.http.get<boolean>(
      `${this.atlasLocatorLibrariesApiUrl}${libraryId}/location-name-exists?name=${name}`
    );
  }

  getShapeReportDataCount(
    libraryId: number,
    locationDataId: number,
    shapeId: number
  ) {
    if (shapeId === undefined) {
      shapeId = 0;
    }

    return this.http.get<number>(
      `${this.atlasLocatorLibrariesApiUrl}${libraryId}/library-data/${locationDataId}/shapes/${shapeId}/report-data-count`,
      {}
    );
  }

  getImportLocatorLibraryDataStatus(libraryId: number) {
    return this.http.get<LocatorImportStatus>(
      `${this.atlasLocatorLibrariesApiUrl}${libraryId}/import-summary`
    );
  }

  pollForLocatorImportLibraryDataStatus(libraryId: number) {
    return timer(0, this.statusPollDelay).pipe(
      switchMap(() =>
        this.getImportLocatorLibraryDataStatus(libraryId).pipe(
          map((importStatusResponse) => importStatusResponse)
        )
      ),
      takeWhile(
        (importStatusResponse: LocatorImportStatus) =>
          this.shouldPollingContinue(libraryId, importStatusResponse),
        true
      )
    );
  }

  private shouldPollingContinue(
    libraryId: number,
    importStatus: LocatorImportStatus
  ) {
    return (
      importStatus != null &&
      importStatus.status !== 'ImportLocatorLibraryDataCompleted' &&
      this.isLocatorFeatureSelected &&
      libraryId === this.currentLocatorLibraryId &&
      importStatus.errorMessage === null
    );
  }
}
