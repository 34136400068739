import { Component, ElementRef, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromScenarioStore from 'src/app/spatial-modeller-store';
import { Subscription, combineLatest, delay, map } from 'rxjs';
import * as fromScenario from 'src/app/spatial-modeller-store/reducers/scenario.reducer';
import { getScenarioState } from 'src/app/spatial-modeller-store';
import { Scenario } from '../../models/scenario';
import * as fromUIStore from 'src/app/core/store';
import { isSMFeatureSelected } from 'src/app/core/store';
import { LayoutService } from 'src/app/shared/atlas-layout/services/layout.service';

@Component({
  selector: 'atlas-scenario-management',
  templateUrl: './scenario-management.component.html',
  styleUrls: ['./scenario-management.component.less']
})
export class ScenarioManagementComponent {
  currentScenario: Scenario;
  private subscription = new Subscription();
  @ViewChild('tooltipSpan') tooltipSpan: ElementRef<HTMLElement>;

  isSmFeatureSelected$ = this.UIStore$.select(isSMFeatureSelected);
  isLocationChanging$ = this.UIStore$.select(fromUIStore.isLocationChanging);
  isNetworkPlanningReadonlySystem$ = this.UIStore$.select(
    fromUIStore.getNetworkPlanningReadonlySystem
  );
  disableNetworkPlanningImportLocations$ = this.UIStore$.select(
    fromUIStore.getNetworkPlaningDisableImportLocations
  );

  isScenarioManagementDisabled$ = combineLatest([
    this.isLocationChanging$,
    this.isNetworkPlanningReadonlySystem$
  ]).pipe(
    delay(100),
    map(([isLocationChanging, isNetworkPlanningReadonlySystem]) => {
      return isLocationChanging || isNetworkPlanningReadonlySystem;
    })
  );

  constructor(
    private store$: Store<fromScenarioStore.State>,
    private UIStore$: Store<fromUIStore.State>,
    public layoutService: LayoutService
  ) {}

  ngOnInit() {
    this.getCurrentScenario();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private getCurrentScenario() {
    this.subscription.add(
      this.store$.select(getScenarioState).subscribe(
        (scenario: fromScenario.State) =>
          (this.currentScenario = {
            name: scenario.name,
            id: scenario.id,
            createDt: scenario.createDt!,
            editedDt: scenario.editedDt!,
            base: scenario.base,
            delta: scenario.delta,
            tests: scenario.tests,
            dependentScenarios: scenario.dependentScenarios
          })
      )
    );
  }

  isTooltipVisible() {
    return (
      this.tooltipSpan?.nativeElement.offsetHeight <
      this.tooltipSpan?.nativeElement.scrollHeight
    );
  }

  getTooltipText(): string {
    return this.currentScenario.name ? this.currentScenario.name : '';
  }
}
