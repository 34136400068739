import { MapService } from '../services/map.service';

export function styleBoundaryGeometryLines(info: any, mapService: MapService) {
  if (
    info &&
    info.layer &&
    (info.layer.props.type === 'Boundary' ||
      info.layer.props.type === 'ThematicBoundary')
  ) {
    let selectedObject = info.object;

    mapService.updateLayer(info.layer.id, {
      getLineColor: (d: any) =>
        d === selectedObject
          ? info.layer.props.highlightLineColor
          : info.layer.props.orginalLineColor,
      getLineWidth: (d: any) =>
        d === selectedObject
          ? info.layer.props.orginalLineWidth +
            info.layer.props.highlightLineWidthOffset
          : info.layer.props.orginalLineWidth,
      updateTriggers: {
        getLineColor: [selectedObject],
        getLineWidth: [selectedObject]
      }
    });
  }
}

export function styleBoundaryTilesetGeometryLines(
  info: any,
  mapService: MapService
) {
  if (
    info &&
    info.layer &&
    (info.layer.props.type === 'BoundaryTileSet' ||
      info.layer.props.type === 'ThematicBoundaryTileSet')
  ) {
    let selectedObject = info.object?.properties.area_name;

    mapService.updateLayer(info.layer.id, {
      getLineColor: (d: any) =>
        d.properties.area_name === selectedObject
          ? info.layer.props.highlightLineColor
          : info.layer.props.orginalLineColor,
      getLineWidth: (d: any) =>
        d.properties.area_name === selectedObject
          ? info.layer.props.orginalLineWidth +
            info.layer.props.highlightLineWidthOffset
          : info.layer.props.orginalLineWidth,
      updateTriggers: {
        getLineColor: [selectedObject],
        getLineWidth: [selectedObject]
      }
    });
  }
}

export function styleLineTilesetGeometryLines(
  info: any,
  mapService: MapService
) {
  // The color is styled thematically so the orginal line color is hard to figure out.
  // What can be changed is the line thickness

  if (
    info &&
    info.layer &&
    (info.layer.props.type === 'LineTileSet' ||
      info.layer.props.type === 'ThematicLineTileSet')
  ) {
    let selectedObject = info.object?.properties.area_name;

    mapService.updateLayer(info.layer.id, {
      getLineWidth: (d: any) =>
        d.properties.area_name === selectedObject
          ? info.layer.props.orginalLineWidth + 5
          : info.layer.props.orginalLineWidth,
      updateTriggers: {
        getLineWidth: [selectedObject]
      }
    });
  }
}
