import { createAction, props } from '@ngrx/store';
import { Scenario } from 'src/app/core/models/scenario';
import { DeltaSummary } from 'src/app/spatial-modeller/models/delta-summary';
import { SupplyPoint } from 'src/app/spatial-modeller/models/supply-point';

export const getScenarioSucceeded = createAction(
  '[Scenario Effect] Get Scenario Succeeded',
  props<{ scenario: Scenario }>()
);

export const createScenarioDialogSaveClicked = createAction(
  '[Add Scenario Dialog] Create Scenario Save Clicked',
  props<{
    scenarioRequest: {
      name: string;
      baseNames: string[];
      deltaNames: string[];
    };
  }>()
);

export const createScenarioSucceeded = createAction(
  '[Atlas API] Create Scenario Succeeded',
  props<{ scenario: Scenario }>()
);

export const updateScenarioDialogSaveClicked = createAction(
  '[Add Scenario Dialog] Update Scenario Save Clicked',
  props<{
    id: number;
    scenarioRequest: {
      name: string;
      baseNames: string[];
      deltaNames: string[];
    };
  }>()
);

export const updateScenarioSucceeded = createAction(
  '[Atlas API] Update Scenario Succeeded',
  props<{ scenario: Scenario }>()
);

export const scenarioErrorOccurred = createAction(
  '[Atlas Backend API] Scenario Error Ocurred',
  props<{ errorOn: string; error: string }>()
);

export const swapScenarioAttempt = createAction(
  '[Swap Scenario Dialog] Swap Scenario Attempt',
  props<{ id: number }>()
);

export const swapScenarioSucceeded = createAction(
  '[Atlas API] Swap Scenario Succeeded',
  props<{ scenario: Scenario }>()
);

export const deleteScenarioAttempt = createAction(
  '[Swap Scenario Dialog] Delete Scenario Attempt',
  props<{ id: number }>()
);

export const deleteScenarioSucceeded = createAction(
  '[Atlas API] Delete Scenario Succeeded',
  props<{ id: number }>()
);

export const getDeltaSupplyPointsSummarySucceeded = createAction(
  '[Atlas API] Get Supply Point Summary Succeeded',
  props<{ summary: DeltaSummary }>()
);

export const clearScenarioStateOnLogoff = createAction(
  '[Menu component] Reset the scenario state on log off'
);

export const getDeltaSupplyPointsForListView = createAction(
  '[Delta Supplypoints List view / Workspace] Get All Delta Supply Points Attempt'
);

export const getAllDeltaSupplyPointsSucceeded = createAction(
  '[Atlas API] Get All Delta Supply Points Succeeded',
  props<{ deltaSupplyPoints: SupplyPoint[] }>()
);

export const clearDeltaSupplyPointsState = createAction(
  '[Delta Supplypoints List view / Workspace] Clear out the delta supply points from state as only needed for that workspace'
);

export const moveDeltaSupplyPointsClicked = createAction(
  '[Delta list move button] Move supply points Attempt',
  props<{ toScenarioId: number; supplyIds: string[] }>()
);

export const moveDeltaSupplyPointsSucceeded = createAction(
  '[Atlas API] Move supply points returned Succeeded'
);

export const moveDeltaSupplyPointsValidationProblems = createAction(
  '[Atlas API] Move supply points has validation problems',
  props<{ validationMessages: string[] }>()
);

export const createUpdateScenarioValidationConflicts = createAction(
  '[Atlas API] Create/Update Scenario Validation Conflicts',
  props<{ message: string; targetScenario: string }>()
);
