<div>
  <div>
    <div *ngIf="queryItems.length === 0">
      Low-fi stats are unavailable for this profile type
    </div>
    <div class="image-container" *ngIf="selectedQueryItem">
      <div
        style="text-align: center"
        id="{{ identifier }}"
        *ngIf="queryItems.length > 1"
      >
        <span
          *ngFor="let row of queryItems; let i = index"
          style="text-align: center"
          class="dot"
          [ngClass]="{ ' active': i === 0 }"
          (click)="selectQuery(i)"
        ></span>
      </div>

      <atlas-profiler-reports-stats
        [queryItem]="selectedQueryItem"
      ></atlas-profiler-reports-stats>

      <atlas-profiler-reports-graph
        [pieGridView]="pieGridView"
        [numberCardView]="numberCardView"
        [height]="graphHeight"
        [queryItem]="selectedQueryItem"
        [graphConfig]="graphConfig"
        (graphClicked)="onGraphClicked($event)"
      >
      </atlas-profiler-reports-graph>
    </div>
  </div>
</div>
