import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromSpatialModellerStore from 'src/app/spatial-modeller-store';
import { SmImportStatusError } from '../models/sm-import-status-error';
import { GetDynamicImportTemplateHeaders } from '../helpers/import-template.helper';
import {
  readFirstLine,
  validateHeaders
} from 'src/app/shared/utils/import-file-validation-utils';
import {
  smFileImportHeaderValidationFailed,
  smFileImportHeaderValidationSuccess
} from 'src/app/spatial-modeller-store/actions/sm-import-validation.actions';
@Injectable({
  providedIn: 'root'
})
export class SmImportValidationService {
  private expectedHeaders: string[] = [];

  constructor(
    private spatialModellerStore$: Store<fromSpatialModellerStore.State>
  ) {
    this.spatialModellerStore$
      .select(fromSpatialModellerStore.getSpatialModellerSupplyPointAttributes)
      .subscribe(
        (supplyPointAttributes) =>
          (this.expectedHeaders = GetDynamicImportTemplateHeaders(
            supplyPointAttributes ?? []
          ))
      );
  }

  async validateFileHeaders(file: File) {
    let actualHeaders = await readFirstLine(file);
    let validationErrors = validateHeaders(actualHeaders, this.expectedHeaders);

    validationErrors.length === 0
      ? this.spatialModellerStore$.dispatch(
          smFileImportHeaderValidationSuccess({ file })
        )
      : this.dispatchErrors(validationErrors);
  }

  private dispatchErrors(validationErrors: string[]) {
    let errorMessageList = validationErrors.map((message) => ({
      error: message
    }));
    const SpatialModellerImportStatusError: SmImportStatusError = {
      status: 'FileValidationFailed',
      errors: errorMessageList
    };
    this.spatialModellerStore$.dispatch(
      smFileImportHeaderValidationFailed({
        errors: JSON.stringify(SpatialModellerImportStatusError)
      })
    );
  }
}
