import { Part } from '@aws-sdk/client-s3';
import { createAction, props } from '@ngrx/store';

export const smFileImportAttempt = createAction(
  '[Sm File Import Dialog] Sm File Import Attempt',
  props<{
    file: File;
    importUsingGeocoding: boolean;
  }>()
);

export const smFileImportSuccess = createAction(
  '[Sm File Import] Sm File Import Succeess',
  props<{
    file: File;
  }>()
);

export const smFileUploadError = createAction(
  '[Sm File Upload] Sm File Upload Error',
  props<{
    fileKey: string;
    errors: any;
  }>()
);

export const smFileUploadUpdateFileKey = createAction(
  '[Sm Upload Service] Sm File Upload Update FileKey',
  props<{
    fileKey: string;
  }>()
);

export const smMetaDataUploadAttempt = createAction(
  '[Sm Import Service] Sm File Upload Meta Data Attempt',
  props<{
    fileKey: string;
    fileHeaders: string[];
  }>()
);

export const smInitiateMultipartUploadAttempt = createAction(
  '[Sm Import Service] Sm Initiate Multipart Upload Attempt',
  props<{
    file: File;
    fileKey: string;
    fileHeaders: string[];
  }>()
);

export const smInitiateMultipartUploadSuccess = createAction(
  '[Sm Import Service] Sm Initiate Multipart Upload Success',
  props<{
    uploadId: string;
    file: File;
  }>()
);

export const smMultipartUploadComplete = createAction(
  '[Sm Import Service] Sm Multipart Upload Complete',
  props<{
    uploadId: string;
    filename: string;
    parts: Part[];
  }>()
);

export const smAbortMultipartUpload = createAction(
  '[Sm Import Service] Sm Abort Multipart Upload',
  props<{
    uploadId: string;
    filename: string;
    errorMessage: any;
  }>()
);

export const smMetaDataUploadSuccess = createAction(
  '[Sm Import Service] Sm File Upload Meta Data Success',
  props<{
    fileKey: string;
  }>()
);

export const smFileUploadCompleteStatusSuccess = createAction(
  '[Sm File Import] Sm File Upload Complete Status Success'
);

export const hasSmScenarioDeltaSupplyPointsLimitBeenExceededAttempt =
  createAction(
    '[Sm File Import Dialog] Check Has Sm Scenario Delta Limit Been Exceeded Attempt',
    props<{
      scenarioId: number;
    }>()
  );

export const hasSmScenarioDeltaSupplyPointsLimitBeenExceededResult =
  createAction(
    '[Sm Import Effect] Result Of Sm Scenario Delta Limit Check',
    props<{
      result: boolean;
    }>()
  );

export const smClearScenarioImportStatusAttempt = createAction(
  '[Sm File Import Dialog] Clear Scenario Import Status Attempt',
  props<{
    scenarioId: number;
  }>()
);

export const smClearScenarioImportStatusSuccess = createAction(
  '[Sm Import Effect] Clear Scenario Import Status Success',
  props<{
    scenarioId: number;
  }>()
);
