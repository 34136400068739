import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Part } from '@aws-sdk/client-s3';
import { v4 as uuidv4 } from 'uuid';
import { environment } from 'src/environments/environment';
import * as fromLocatorStore from 'src/app/locator-store';
import {
  smAbortMultipartUpload,
  smFileUploadError,
  smFileUploadUpdateFileKey,
  smInitiateMultipartUploadAttempt,
  smMetaDataUploadAttempt,
  smMultipartUploadComplete
} from 'src/app/spatial-modeller-store/actions/sm-import.actions';
import {
  uploadFileInOneGo,
  uploadFileMultiPart
} from 'src/app/shared/utils/file-upload-utils';

// The size at which point to upload using multipart upload
const singleFileUploadSize = 10 * 1024 * 1024; // 10MB in bytes

// Each mulitpart part size must be at least 5MB
const multipartFileUploadMinimumSize = 5 * 1024 * 1024; // 5MB in bytes

@Injectable({
  providedIn: 'root'
})
export class SmUploadService {
  private atlasSpatialModellerImportUrl = `${environment.baseUrl}api/spatial-modeller/import-scenario-data`;
  private guidFilename: string;

  constructor(
    private http: HttpClient,
    private store$: Store<fromLocatorStore.State>
  ) {}

  public uploadFile(file: File, fileHeaders: string[]) {
    this.guidFilename = `${uuidv4()}.csv`;
    this.store$.dispatch(
      smFileUploadUpdateFileKey({ fileKey: this.guidFilename })
    );

    if (file.size <= singleFileUploadSize) {
      this.uploadFileInOneGo(file, fileHeaders);
    } else {
      this.store$.dispatch(
        smInitiateMultipartUploadAttempt({
          file: file,
          fileKey: this.guidFilename,
          fileHeaders
        })
      );
    }
  }

  public async uploadFileInOneGo(file: File, fileHeaders: string[]) {
    try {
      await uploadFileInOneGo(
        file,
        this.http,
        this.atlasSpatialModellerImportUrl,
        this.guidFilename
      );
      this.store$.dispatch(
        smMetaDataUploadAttempt({ fileKey: this.guidFilename, fileHeaders })
      );
    } catch (error) {
      this.handleUploadError(file.name, error);
    }
  }

  public async uploadFileMultiPart(file: File, uploadId: string) {
    try {
      const eTagParts: Part[] = await uploadFileMultiPart(
        file,
        uploadId,
        this.guidFilename,
        this.http,
        this.atlasSpatialModellerImportUrl
      );

      this.store$.dispatch(
        smMultipartUploadComplete({
          uploadId,
          filename: this.guidFilename,
          parts: eTagParts
        })
      );
    } catch (err) {
      this.store$.dispatch(
        smAbortMultipartUpload({
          uploadId,
          filename: this.guidFilename,
          errorMessage: err
        })
      );
    }
  }

  private handleUploadError(filename: string, error: any) {
    this.store$.dispatch(
      smFileUploadError({ fileKey: filename, errors: error })
    );
  }
}
