import { Component, EventEmitter, Input, Output } from '@angular/core';
import { escapeLabel, formatLabel, ScaleType } from '@swimlane/ngx-charts';
import {
  LocatorLofiData,
  LocatorLofiQueryResult
} from 'src/app/locator/models/locator-lofi-report';
import { Color, colorSets } from '@swimlane/ngx-charts';
@Component({
  selector: 'atlas-locator-reports-graph',
  templateUrl: './locator-reports-graph.component.html',
  styleUrls: ['./locator-reports-graph.component.less']
})
export class LocatorReportsGraphComponent {
  private _graphConfig: any;

  @Input() queryItem: LocatorLofiQueryResult;
  @Input() pieGridView: [number, number] = [250, 620];
  @Input() numberCardView: [number, number] = [300, 620];
  @Input() height: number;

  @Input()
  get graphConfig(): any {
    return this._graphConfig;
  }

  set graphConfig(value: any) {
    this._graphConfig = value;
    this.orginalCustomColours = this.graphConfig.customColors;
  }
  @Output() graphClicked: EventEmitter<any> = new EventEmitter();

  private orginalCustomColours: any;
  private selectedNames: Set<string> = new Set();

  allowedCharts = [
    'bar-horizontal',
    'bar-vertical',
    'pie-chart',
    'advanced-pie-chart',
    'pie-grid',
    'tree-map',
    'number-card'
  ];
  constructor() {}

  schemeType = ScaleType.Ordinal;

  setColorScheme(name: string): string | Color {
    return colorSets.find((s: any) => s.name === name) as Color;
  }

  pieTooltipText(d: any) {
    return `
      <span class="tooltip-label">${d.data.name}</span>
      <span class="tooltip-val">${d.data.value}</span>
    `;
  }

  onGraphClick(evt: any) {
    this.graphClicked.emit({
      name: evt.name,
      mapLayerId: this.graphConfig?.mapLayerId
    });

    if (this.graphConfig?.mapLayerId) {
      this.graphConfig.customColors = this.spotlightColorArray(evt.name);
    }
  }

  private spotlightColorArray(selectedName: string) {
    // Toggle the selected name in the set
    if (this.selectedNames.has(selectedName)) {
      this.selectedNames.delete(selectedName);
    } else {
      this.selectedNames.add(selectedName);
    }

    // If no names are selected, return the original colours
    if (this.selectedNames.size === 0) {
      return this.orginalCustomColours;
    }

    // Generate the resulting array
    return this.orginalCustomColours.map((c: any) => {
      return {
        name: c.name,
        value: this.selectedNames.has(c.name) ? c.value : '#808080'
      };
    });
  }
}
