import { Component, Input } from '@angular/core';
import { ProfilerStatusProgressStep } from '../../model/profiler-status-progress-step';
import {
  sendingDataToTheCloud,
  preparingData,
  profilingData,
  savingProfiledData
} from '../../helpers/profiler-status-helper';

@Component({
  selector: 'atlas-profiler-progress-step',
  templateUrl: './profiler-progress-step.component.html',
  styleUrls: ['./profiler-progress-step.component.less']
})
export class ProfilerProgressStepComponent {
  @Input() progressStep: ProfilerStatusProgressStep;

  sendingDataToTheCloud: string = sendingDataToTheCloud;
  preparingData: string = preparingData;
  profilingData: string = profilingData;
  savingProfiledData: string = savingProfiledData;
}
