import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { isLabelLayer } from 'src/app/shared/atlas-mapping/helpers/map-Legend-helper';
import {
  capitalizeWord,
  replaceUnderScore
} from 'src/app/shared/atlas-mapping/helpers/map-tooltip-helper';
import { MapLayerStyleService } from 'src/app/shared/atlas-mapping/services/map-layer-style-service';

@Component({
  selector: 'atlas-layer-multiple-themes',
  templateUrl: './layer-multiple-themes.component.html',
  styleUrls: ['./layer-multiple-themes.component.less']
})
export class LayerMultipleThemesComponent {
  private _layer: any;

  dataSource: { value: string; display: string }[] = [];
  selectedStyleOption = '';

  @Input()
  set layer(newValue: any) {
    this._layer = newValue;

    if (
      this._layer &&
      this._layer?.props &&
      this._layer?.props?.multipleThemes
    ) {
      // Ensure consistent data source generation
      this.dataSource = this._layer?.props?.multipleThemes
        .map((p: any) => {
          return this.getThemingAttributeValue(p);
        })
        .filter((attr: string) => !!attr)
        .sort((a: any, b: any) => a.localeCompare(b))
        .map((attr: string) => ({
          value: attr,
          display: replaceUnderScore(capitalizeWord(attr))
        }));

      // Set initial selected option
      const initialAttr = this.getThemingAttributeValue(this._layer.props);

      if (initialAttr) {
        this.selectedStyleOption = initialAttr;
      }
    } else {
      this.dataSource = [];
      this.selectedStyleOption = '';
    }
  }

  get layer(): any {
    return this._layer;
  }

  constructor(private layerStyleService: MapLayerStyleService) {}

  onStyleChanged(value: string) {
    // Find the selected style configuration
    const selectedStyle = this._layer.props.multipleThemes.find((s: any) => {
      return this.getThemingAttributeValue(s) === value;
    });

    if (selectedStyle) {
      // Change layer style
      this.changeLayerStyle(selectedStyle);

      // Clear Orginal colors and domains to allow spotlighting
      this.layerStyleService.clearSpotLightLayerColors(this.layer.id);

      // Update the selected option
      this.selectedStyleOption = value;
    }
  }

  private getThemingAttributeValue(style: any) {
    return isLabelLayer(this.layer)
      ? style?.labelAttr
      : style.fillThematicScaleConfig.attr;
  }
  private changeLayerStyle(style: any) {
    if (isLabelLayer(this.layer)) {
      this.layerStyleService.changeLabelStyle(this._layer.id, style);
    } else {
      this.layerStyleService.changeThematicStyle(this._layer.id, style);
    }
  }
}
