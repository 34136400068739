import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { LocatorImportService } from 'src/app/locator/services/locator-import.service';
import {
  clearLocatorImportStatusAttempt,
  clearLocatorImportStatusSuccess,
  hasLocatorLibraryLocationsLimitBeenExceededAttempt,
  hasLocatorLibraryLocationsLimitBeenExceededResult,
  locatorAbortMultipartUpload,
  locatorFileImportAttempt,
  locatorFileUploadCompleteStatusSuccess,
  locatorFileUploadError,
  locatorFileUploadUpdateFileKey,
  locatorInitiateMultipartUploadAttempt,
  locatorInitiateMultipartUploadSuccess,
  locatorMetaDataUploadAttempt,
  locatorMetaDataUploadSuccess,
  locatorMultipartUploadComplete
} from '../actions/locator-import.actions';
import { LocatorUploadService } from 'src/app/locator/services/locator-upload.service';
import {
  locatorFileImportHeaderValidationAttempt,
  locatorFileImportHeaderValidationFailed,
  locatorFileImportHeaderValidationSuccess
} from '../actions/locator-import-validation.actions';
import { LocatorImportValidationService } from 'src/app/locator/services/locator-import-validation.service';
import * as fromLocatorStore from 'src/app/locator-store';
import { Store } from '@ngrx/store';
import { LocatorService } from 'src/app/locator/services/locator.service';
import { generateTempLocatorLibraryDataPointSucceded } from '../actions/locator-library.actions';
import { AppFeatureStateService } from 'src/app/shared/services/app-feature-state.service';
import { of } from 'rxjs';
import { pollLocatorImportLibraryDataStatusCompleted } from '../actions/locator-import-status.actions';
import { DataGridService } from 'src/app/core/services/datagrid.service';
import { PinDropAndSelectionService } from 'src/app/shared/atlas-mapping/services/pin-drop-and-selection-service';
import { getLocatorLibraryId } from 'src/app/locator-store';
import { deleteLibraryLocationsSucceeded } from '../actions/locator-location.actions';

@Injectable()
export class LocatorImportEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<fromLocatorStore.State>,
    private locatorImportService: LocatorImportService,
    private locatorImportValidationService: LocatorImportValidationService,
    private locatorUploadService: LocatorUploadService,
    private dataGridService: DataGridService,
    private pinDropAndSelectionService: PinDropAndSelectionService
  ) {}

  startImportFileUpload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(locatorFileImportAttempt),
      withLatestFrom(this.store$.select(fromLocatorStore.getLocatorLibraryId)),
      switchMap(([{ file }, libraryId]) =>
        this.locatorImportService
          .fileUploadStartedStatus(libraryId)
          .pipe(map(() => locatorFileImportHeaderValidationAttempt({ file })))
      )
    )
  );

  fileUploadHeaderValidationAttempt$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(locatorFileImportHeaderValidationAttempt),
        map((action) =>
          this.locatorImportValidationService.validateFileHeaders(action.file)
        )
      ),
    { dispatch: false }
  );

  fileUploadEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(locatorFileImportHeaderValidationSuccess),
        map((action) => {
          this.locatorUploadService.uploadFile(action.file);
        })
      ),
    { dispatch: false }
  );

  fileUploadUpdateFileKeyEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(locatorFileUploadUpdateFileKey),
        withLatestFrom(
          this.store$.select(fromLocatorStore.getLocatorLibraryId)
        ),
        switchMap(([action, libraryId]) =>
          this.locatorImportService.fileUploadUpdateFileKey(
            libraryId,
            action.fileKey
          )
        )
      ),
    { dispatch: false }
  );

  metadataUploadEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(locatorMetaDataUploadAttempt),
      withLatestFrom(
        this.store$.select(fromLocatorStore.getLocatorLibraryId),
        this.store$.select(
          fromLocatorStore.getSelectedLocatorImportUsingGeocoding
        )
      ),
      switchMap(([file, libraryId, importUsingGeocoding]) =>
        this.locatorImportService
          .uploadMetaDataConfig(file.fileKey, libraryId, importUsingGeocoding)
          .pipe(
            map(() => locatorMetaDataUploadSuccess({ fileKey: file.fileKey })),
            catchError((error) =>
              of(
                locatorFileUploadError({ fileKey: file.fileKey, errors: error })
              )
            )
          )
      )
    )
  );

  fileUploadCompleteStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(locatorMetaDataUploadSuccess),
      withLatestFrom(this.store$.select(fromLocatorStore.getLocatorLibraryId)),
      switchMap(([file, libraryId]) =>
        this.locatorImportService.fileUploadCompleteStatus(libraryId).pipe(
          map(() => locatorFileUploadCompleteStatusSuccess()),
          catchError((error) =>
            of(locatorFileUploadError({ fileKey: file.fileKey, errors: error }))
          )
        )
      )
    )
  );

  fileUploadError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(locatorFileUploadError, locatorFileImportHeaderValidationFailed),
        withLatestFrom(
          this.store$.select(fromLocatorStore.getLocatorLibraryId)
        ),
        switchMap(([error, libraryId]) =>
          this.locatorImportService.fileUploadFailedStatus(
            libraryId,
            error.errors
          )
        )
      ),
    { dispatch: false }
  );

  initiateMultipartUploadAttempt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(locatorInitiateMultipartUploadAttempt),
      switchMap((action) =>
        this.locatorImportService.initiateMultipartUpload(action.fileKey).pipe(
          map((result) =>
            locatorInitiateMultipartUploadSuccess({
              uploadId: result.uploadId,
              file: action.file
            })
          )
        )
      )
    )
  );

  initiateMultipartUploadSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(locatorInitiateMultipartUploadSuccess),
        switchMap((action) =>
          this.locatorUploadService.uploadFileMultiPart(
            action.file,
            action.uploadId
          )
        )
      ),
    { dispatch: false }
  );

  multipartUploadComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(locatorMultipartUploadComplete),
      withLatestFrom(this.store$.select(fromLocatorStore.getLocatorLibraryId)),
      switchMap(([action, libraryId]) =>
        this.locatorImportService
          .completeMultipartUpload(
            libraryId,
            action.filename,
            action.uploadId,
            action.parts
          )
          .pipe(
            map(() =>
              locatorMetaDataUploadAttempt({ fileKey: action.filename })
            )
          )
      )
    )
  );

  abortMultipartUpload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(locatorAbortMultipartUpload),
      switchMap((action) =>
        this.locatorImportService
          .cancelMultipartUpload(action.filename, action.uploadId)
          .pipe(
            map(() =>
              locatorFileUploadError({
                fileKey: action.filename,
                errors: action.errorMessage
              })
            )
          )
      )
    )
  );

  refreshLocationsGridAfterImportLocation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(pollLocatorImportLibraryDataStatusCompleted),
        map(({ locatorImportStatus }) => {
          this.dataGridService.refreshGridAndClearSelection();
          this.pinDropAndSelectionService.refreshLocatorLibraryLocationsTileset(
            locatorImportStatus.libraryId
          );
        })
      ),
    { dispatch: false }
  );

  hasLocatorLibraryLocationsLimitBeenExceededAttempt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(hasLocatorLibraryLocationsLimitBeenExceededAttempt),
      switchMap(({ libraryId }) =>
        this.locatorImportService
          .hasLocatorLibraryLocationsLimitBeenExceeded(libraryId)
          .pipe(
            map((hasExcceeded) =>
              hasLocatorLibraryLocationsLimitBeenExceededResult({
                result: hasExcceeded.result
              })
            )
          )
      )
    )
  );

  newLocatorLibraryDataPointSucceded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(generateTempLocatorLibraryDataPointSucceded),
      withLatestFrom(this.store$.select(getLocatorLibraryId)),
      map(([_, libraryId]) => {
        return hasLocatorLibraryLocationsLimitBeenExceededAttempt({
          libraryId
        });
      })
    )
  );

  deleteLocatorLibraryDataPointSucceded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteLibraryLocationsSucceeded),
      withLatestFrom(this.store$.select(getLocatorLibraryId)),
      map(([_, libraryId]) => {
        return hasLocatorLibraryLocationsLimitBeenExceededAttempt({
          libraryId
        });
      })
    )
  );

  clearLocatorImportStatusAttempt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(clearLocatorImportStatusAttempt),
      switchMap(({ libraryId }) =>
        this.locatorImportService
          .clearLocatorImportStatus(libraryId)
          .pipe(map(() => clearLocatorImportStatusSuccess({ libraryId })))
      )
    )
  );
}
