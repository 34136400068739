import { createReducer, on } from '@ngrx/store';
import { LocatorShapeDefaults } from '../../models/locator-shape-defaults';
import {
  deleteUserSettingsCatchment,
  getUserSettingsSuceeded,
  updateCatchmentReportingUserSettingsSucceeded,
  updateGeneralUserSettingsSucceeded,
  updateLocatorUserSettingsSucceeded,
  updateMultipleCatchmentsSelectionSucceeded,
  updateNetworkPlanningUserSettingSucceeded
} from '../actions';
import { GeneralSettings } from '../../models/general-settings';
import { NetworkPlanningSettings } from '../../models/network-planning-settings';
import { CatchmentReportingSettings } from '../../models/catchment-reporting-settings';

export interface State {
  general: GeneralSettings | null;
  locator: LocatorShapeDefaults | null;
  networkPlanning: NetworkPlanningSettings | null;
  catchmentReporting: CatchmentReportingSettings | null;
}

export const initialState: State = {
  general: null,
  locator: null,
  networkPlanning: null,
  catchmentReporting: null
};

export const userSettingsReducer = createReducer(
  initialState,
  on(
    getUserSettingsSuceeded,
    updateGeneralUserSettingsSucceeded,
    updateLocatorUserSettingsSucceeded,
    updateNetworkPlanningUserSettingSucceeded,
    updateCatchmentReportingUserSettingsSucceeded,
    (state, { userSettings }) => {
      return {
        ...state,
        general: userSettings ? userSettings.general : null,
        locator: userSettings ? userSettings.locator : null,
        networkPlanning: userSettings ? userSettings.networkPlanning : null,
        catchmentReporting: userSettings
          ? userSettings.catchmentReporting
          : null
      };
    }
  ),
  on(
    updateMultipleCatchmentsSelectionSucceeded,
    (state, { multipleCatchments }) => ({
      ...state,
      catchmentReporting: {
        ...state.catchmentReporting,
        multipleCatchmentsSelection: multipleCatchments
      } as CatchmentReportingSettings
    })
  ),
  on(deleteUserSettingsCatchment, (state, { catchments }) => ({
    ...state,
    catchmentReporting: {
      ...state.catchmentReporting,
      multipleCatchmentsSelection: catchments
    } as CatchmentReportingSettings
  }))
);
