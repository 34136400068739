@if((isLofiReportAvailable$ | async) || shapesLength >= 0 ||
(showSummaryStatsReport && summaryStatsReportAvailable)){

<div>
  @if(showSummaryStatsReport && summaryStatsReportAvailable && shapesLength ===
  0){
  <div>
    <div class="summary-text">
      Summary information is currently unavailable. Please select one catchment
      to see the summary information.
    </div>
  </div>
  } @else{ @if(shapesLength > 1 && !(isLofiReportAvailable$ | async)){
  <div>
    <span style="font-size: 12px">
      Multiple catchments selected. Please select one catchment to see the
      summary stats.
    </span>
  </div>
  } @if((isLofiReportAvailable$ | async) || shapesLength === 1){
  <div>
    <div class="spinner" *ngIf="!selectedQueryItem">
      <mat-spinner diameter="24" strokeWidth="2"> </mat-spinner>
    </div>

    <div class="image-container" *ngIf="selectedQueryItem">
      @if (!(isReportDataAvailable$ | async) && shapesLength === 1 ) { There is
      no data available for the selected catchment. } @else {
      @if(queryItems.length > 1){
      <div style="text-align: center" id="{{ identifier }}">
        @for(row of queryItems; track row; let i =$index ){
        <span
          style="text-align: center"
          class="dot"
          [ngClass]="{ ' active': i === 0 }"
          (click)="selectQuery(i)"
        >
        </span>
        }
      </div>
      } @if(titleType){
      <div style="text-align: center">
        {{ titleType }}
      </div>
      }
      <atlas-locator-reports-stats [queryItem]="selectedQueryItem">
      </atlas-locator-reports-stats>

      <atlas-locator-reports-graph
        [pieGridView]="pieGridView"
        [numberCardView]="numberCardView"
        [height]="graphHeight"
        [queryItem]="selectedQueryItem"
        [graphConfig]="graphConfig"
        (graphClicked)="onGraphClicked($event)"
      >
      </atlas-locator-reports-graph>
      }
    </div>
  </div>
  } }
</div>
}
