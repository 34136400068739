import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  deleteLibraryLocationsAttempt,
  deleteLibraryLocationsSucceeded,
  libraryLocationErrorOccurred,
  locationFromGazetteerClicked,
  locationFromLocationGridClicked,
  locatorLocationSelected,
  syncMultiCatchmentForExistingLocationAttempt,
  syncMultiCatchmentForExistingLocationSucceeded
} from '../actions/locator-location.actions';
import {
  catchError,
  delay,
  map,
  switchMap,
  withLatestFrom
} from 'rxjs/operators';
import * as fromLocatorStore from 'src/app/locator-store';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { MapService } from 'src/app/shared/atlas-mapping/services/map.service';
import { DropPinAction } from 'src/app/shared/atlas-gazetteer/helpers/search-helper';
import { PinDropAndSelectionService } from 'src/app/shared/atlas-mapping/services/pin-drop-and-selection-service';
import { showLocatorLayersEffectedByLocationSelectionLayers } from 'src/app/locator/helpers/locator-shape.helper';
import { of } from 'rxjs';
import {
  libraryLocationShapeErrorOccurred,
  logPowerBiReportAttempt,
  logPowerBiReportSucceeded
} from '../actions/locator-shape.actions';
import { LocatorService } from 'src/app/locator/services/locator.service';
import { LocatorLibraryData } from 'src/app/locator/models/locator-library-data';
import { libraryLocationClicked } from '../actions/locator-library.actions';
import {
  getApplyMultiCatchmentsToExistingLocations,
  getMultiCatchmentMode
} from 'src/app/core/store';

@Injectable()
export class LocatorLocationEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<fromLocatorStore.State>,
    private mapService: MapService,
    private pinDropAndSelectionService: PinDropAndSelectionService,
    private locatorService: LocatorService
  ) {}

  getSelectedLocationDataByGazetteer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(locationFromGazetteerClicked, libraryLocationClicked),
      switchMap(({ libraryDataId }) =>
        this.locatorService.getLocatorDataById(libraryDataId).pipe(
          map((locationData: LocatorLibraryData) => {
            return syncMultiCatchmentForExistingLocationAttempt({
              locationData
            });
          }),
          catchError((error) =>
            of(
              libraryLocationErrorOccurred({
                errorOn: 'Error Library location',
                error: `Retrieving library location has failed.`
              })
            )
          )
        )
      )
    )
  );

  locationFromGridClicked$ = createEffect(() =>
    this.actions$.pipe(
      ofType(locationFromLocationGridClicked),
      map(({ locationData }) =>
        syncMultiCatchmentForExistingLocationAttempt({ locationData })
      )
    )
  );

  syncMultiCatchmentForExistingLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(syncMultiCatchmentForExistingLocationAttempt),
      withLatestFrom(
        this.store$.select(getMultiCatchmentMode),
        this.store$.select(getApplyMultiCatchmentsToExistingLocations)
      ),
      switchMap(
        ([
          { locationData },
          multiCatchmentMode,
          applyMultipleCatchmentsToExistingLocations
        ]) => {
          if (
            multiCatchmentMode &&
            applyMultipleCatchmentsToExistingLocations
          ) {
            return this.locatorService.getLocatorDataById(locationData.id).pipe(
              map((locationResponse) =>
                syncMultiCatchmentForExistingLocationSucceeded({
                  locationData: locationResponse
                })
              )
            );
          }

          return of(
            syncMultiCatchmentForExistingLocationSucceeded({
              locationData
            })
          );
        }
      )
    )
  );

  // NOTE
  // The shapes on a map used to be called Here before the design moved on to have the UI select all
  // The Shape on the map layer will be triggered when the selected location selector changes
  // This will trigger the select all in the properties panel this will trigger the effect  getAllShapesFromState$
  // this will handle the drawing of the shapes on the map
  getSelectedShapesFromState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(syncMultiCatchmentForExistingLocationSucceeded),
      withLatestFrom(
        this.store$.select(fromLocatorStore.getSelectedLocation),
        this.store$.select(fromLocatorStore.getLocatorMapLayerTemplates)
      ),
      map(([{ locationData }]) => {
        DropPinAction(this.mapService, this.pinDropAndSelectionService, {
          latitude: locationData!.latitude,
          longitude: locationData!.longitude
        });
        return locatorLocationSelected({
          location: {
            latitude: locationData!.latitude,
            longitude: locationData!.longitude
          }
        });
      })
    )
  );

  deleteLibraryLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteLibraryLocationsAttempt),
      withLatestFrom(this.store$.select(fromLocatorStore.getLocatorLibraryId)),
      switchMap(([{ libraryDataIds }, libraryId]) => {
        return this.locatorService
          .deleteLocations(libraryId, libraryDataIds)
          .pipe(
            map(() => {
              this.pinDropAndSelectionService.refreshLocatorLibraryLocationsTileset(
                libraryId
              );
              return deleteLibraryLocationsSucceeded({
                libraryDataIds: libraryDataIds
              });
            }),
            catchError((error) =>
              of(
                libraryLocationErrorOccurred({
                  errorOn: 'Error Library location',
                  error: `Delete Library location has failed.`
                })
              )
            )
          );
      })
    )
  );

  logPowerBiReportAttempt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logPowerBiReportAttempt),
      switchMap(() => {
        return this.locatorService.logPowerBiReportUsage().pipe(
          map(() => logPowerBiReportSucceeded()),
          catchError((error) =>
            of(
              libraryLocationShapeErrorOccurred({
                errorOn: 'Error log power bi report usage',
                error: `Log power bi report usage has failed.`
              })
            )
          )
        );
      })
    )
  );
}
