import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { exhaustMap, retry, takeWhile, timer } from 'rxjs';
import { Store } from '@ngrx/store';
import * as appFeatureStore from 'src/app/core/store';
import * as spatialModellerStore from 'src/app/spatial-modeller-store';
import { SmImportStatus } from '../models/sm-import-status';

@Injectable({ providedIn: 'root' })
export class SpatialModellerImportStatusService {
  private atlasSpatialModellerScenariosApiUrl = `${environment.baseUrl}api/spatial-modeller/scenarios/`;

  statusPollDelay = 2000;
  statusMaxRetries = 5; // Maximum retry attempts
  statusBaseDelay = 500;
  isSpatialModellerFeatureSelected: boolean = false;
  currentScenarioId: number;

  constructor(
    private http: HttpClient,
    private appFeatureStore$: Store<appFeatureStore.State>,
    private spatialModellerStore$: Store<spatialModellerStore.State>
  ) {
    this.appFeatureStore$
      .select(appFeatureStore.isSMFeatureSelected)
      .subscribe(
        (isSMFeatureSelected) =>
          (this.isSpatialModellerFeatureSelected = isSMFeatureSelected)
      );

    this.spatialModellerStore$
      .select(spatialModellerStore.getScenarioId)
      .subscribe((scenarioId) => (this.currentScenarioId = scenarioId));
  }

  getImportSmScenarioStatus(scenarioId: number) {
    return this.http.get<SmImportStatus>(
      `${this.atlasSpatialModellerScenariosApiUrl}${scenarioId}/import-summary`
    );
  }

  pollForSmImportScenarioStatus(scenarioId: number) {
    return timer(0, this.statusPollDelay).pipe(
      exhaustMap(() => this.getImportSmScenarioStatus(scenarioId)),
      retry({
        count: this.statusMaxRetries,
        resetOnSuccess: true,
        delay: (error, retryCount) => {
          const jitter = Math.random() * this.statusBaseDelay;
          const backoffDelay =
            Math.pow(2, retryCount) * this.statusBaseDelay + jitter;
          console.log(
            `Retrying (attempt ${retryCount}) after ${backoffDelay}ms due to error: ${error}`
          );
          return timer(backoffDelay);
        }
      }),
      takeWhile(
        (importStatusResponse: SmImportStatus) =>
          this.shouldPollingContinue(scenarioId, importStatusResponse),
        true
      )
    );
  }

  private shouldPollingContinue(
    scenarioId: number,
    importStatus: SmImportStatus
  ) {
    return (
      importStatus != null &&
      importStatus.status !== 'ProcessingFileDataCompleted' &&
      this.isSpatialModellerFeatureSelected &&
      scenarioId === this.currentScenarioId &&
      importStatus.errorMessage === null
    );
  }
}
