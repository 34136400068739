import { from, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ThematicAtlasMapMarkerLayer } from '../layers/atlas-thematic-map-marker-layer';
import { BoundaryLayer } from '../layers/boundary-layer';
import { AtlasEditPolygonLayer } from '../layers/edit-polygon-layer';
import {
  locatorDataShapeSpatialFunctionLayerIdentifier,
  locatorDataShapesLayerIdentifier,
  locatorEditPolygonIdentifier,
  locatorLibraryLocationsTilesetLayerIdentifier,
  pinDropLayerIdentifier,
  smModelResultsCircleLayerIdentifier,
  smModelResultsDriveTimeLayerIdentifier,
  smModelTestResultsLayerIdentifier,
  smScenarioBaseLayerIdentifier,
  smScenarioBaseTilesetLayerIdentifier,
  smScenarioDeltaLayerIdentifier
} from '../layers/layer.constants';
import { PinDropLayer } from '../layers/pin-drop-layer';
import { PointLayer } from '../layers/point-layer';
import { ThematicBoundaryLayer } from '../layers/thematic-boundary-layer';

import { MapService } from '../services/map.service';
import { PinDropAndSelectionService } from '../services/pin-drop-and-selection-service';

import { DrawPolygonMode, ViewMode } from '@deck.gl-community/editable-layers';
import { ThematicPointTileSetLayer } from '../layers/thematic-point-tileset-layer';
import { buildMapLayer } from './map-layer-helper';
import { ThematicIconTileSetLayer } from '../layers/thematic-icon-tileset-layer';
import { PointTileSetLayer } from '../layers/point-tileset-layer';
import { featureActions } from 'src/app/locator/types/feature-action.types';

export const buildSystemLayers = {
  Modeller: (
    mapService: MapService,
    featureContainerId: number,
    networkPlanningUseTilesetForBaseLayers: boolean,
    locatorUseLibraryLocationsLayer: boolean
  ) =>
    addSpatialModellerSystemLayers(
      mapService,
      featureContainerId,
      networkPlanningUseTilesetForBaseLayers
    ),
  Locator: (mapService: MapService) => addLocatorSystemLayers(mapService),
  Profiler: (mapService: MapService) => addProfilerSystemLayers(mapService)
};

export async function addPinDropLayer(
  mapService: MapService,
  pinDropAndSelectionService: PinDropAndSelectionService,
  subscription: Subscription,
  pinDropLayer: PinDropLayer
) {
  // addLayer returns a Promise. That promise is converted to an Observable
  // and when that Promise finishes then the map service is asked to check
  // the PinDropLayerAvailability.

  subscription.add(
    from(mapService.addLayer(pinDropLayer))
      .pipe(
        map(() => pinDropAndSelectionService.checkPinDropLayerAvailability())
      )
      .subscribe()
  );
}

export async function addSmModelTestResultsSystemLayer(mapService: MapService) {
  if (
    !mapService.removeSystemLayerIdentifiers.includes(
      smModelTestResultsLayerIdentifier
    )
  ) {
    let thematicProps = {
      id: smModelTestResultsLayerIdentifier,
      name: 'SM Model test results',
      description:
        'The expected percentage flows of shopper populations to the selected retailer.',
      data: [],
      fillThematicScale: 'colorBins',
      fillThematicScaleConfig: {
        attr: 'flow (%)',
        domain: [0, 0.5, 1, 5, 11, 15, 20, 25, 30, 40, 50, 100],
        colors: 'Temps'
      },
      opacity: 0.3
    };

    var thematicBoundaryLayer = new ThematicBoundaryLayer(thematicProps);
    thematicBoundaryLayer.zOrder = 20;
    thematicBoundaryLayer.type = 'ThematicBoundary';
    await mapService.addLayer(thematicBoundaryLayer);
  }
}

// data for this GEOJSON layer comes from the following end point
// api/mapping/spatial-modeller/scenario/{scenarioId}/base/thematic-attribute-name/fascia/locations
// It has to be set when the scenario is loaded
export async function addSmModelBaseSystemLayer(
  mapService: MapService,
  scenarioId: number
) {
  if (
    !mapService.removeSystemLayerIdentifiers.includes(
      smScenarioBaseLayerIdentifier
    )
  ) {
    let thematicProps = {
      id: smScenarioBaseLayerIdentifier,
      name: 'Base',
      description: 'Base',
      data: `${mapService.getBaseUrl()}api/mapping/spatial-modeller/scenario/${scenarioId}/base/thematic-attribute-name/fascia/locations`,
      visible: true,
      iconThematicScale: 'colorCategories',
      iconThematicScaleConfig: {
        attr: 'fascia',
        domain: [],
        colors: 'Temps',
        othersColor: [192, 192, 192]
      }
    };

    var thematicAtlasMapMarkerLayer = new ThematicAtlasMapMarkerLayer(
      thematicProps
    );
    thematicAtlasMapMarkerLayer.zOrder = 45;
    thematicAtlasMapMarkerLayer.type = 'ThematicInSiteEverywhereMapPin';
    await mapService.addLayer(thematicAtlasMapMarkerLayer);
  }
}

// data for this Dynamic tileset layer comes from the following end point
// api/mapping/spatial-modeller/scenario/{scenarioId}/base/thematic-attribute-name/fascia/dynamic-tileset/locations
// It has to be set when the scenario is loaded
export async function addSmModelBaseTilesetSystemLayer(
  mapService: MapService,
  scenarioId: number,
  userDefinedTemplate: any
) {
  let userLayer = buildMapLayer(userDefinedTemplate.type, userDefinedTemplate);

  const thematicProps = buildSmBaseThematicTilesetProps(mapService, scenarioId);

  const layerProperties = determineUserDefinedLayerProperties(
    userLayer,
    thematicProps,
    scenarioId,
    mapService
  );

  const thematicTileSetLayer = instantiateThematicTilesetLayer(
    userDefinedTemplate.type,
    layerProperties
  );

  thematicTileSetLayer.zOrder = 44;

  await mapService.addLayer(thematicTileSetLayer);
}

function instantiateThematicTilesetLayer(type: string, layerProperties: any) {
  if (type === 'ThematicIconTileSet') {
    const thematicTileSetLayer = new ThematicIconTileSetLayer(layerProperties);
    thematicTileSetLayer.type = 'ThematicIconTileSet';
    return thematicTileSetLayer;
  } else {
    const thematicTileSetLayer = new ThematicPointTileSetLayer(layerProperties);
    thematicTileSetLayer.type = 'ThematicPointTileSet';
    return thematicTileSetLayer;
  }
}

function determineUserDefinedLayerProperties(
  userLayer: any,
  thematicProps: any,
  scenarioId: number,
  mapService: MapService
) {
  if (userLayer) {
    const baseThematicAttribute = userLayer.userDefinedProps
      .iconThematicScaleConfig
      ? userLayer.userDefinedProps.iconThematicScaleConfig.attr
      : userLayer.userDefinedProps.fillThematicScaleConfig.attr;

    return {
      ...thematicProps,
      ...userLayer.userDefinedProps,
      data: `${mapService.getBaseUrl()}api/mapping/spatial-modeller/scenario/${scenarioId}/base/thematic-attribute-name/${baseThematicAttribute}/dynamic-tileset/locations`
    };
  } else {
    return thematicProps;
  }
}

function buildSmBaseThematicTilesetProps(
  mapService: MapService,
  scenarioId: number
) {
  return {
    id: 'smScenarioBaseTilesetLayerIdentifier',
    name: 'Base',
    description: 'Base',
    data: `${mapService.getBaseUrl()}api/mapping/spatial-modeller/scenario/${scenarioId}/base/thematic-attribute-name/fascia/dynamic-tileset/locations`,
    visible: true,
    fillThematicScale: 'colorCategories',
    fillThematicScaleConfig: {
      attr: 'fascia',
      domain: [],
      colors: 'Temps',
      othersColor: [192, 192, 192]
    },
    maxTrigger: 20,
    maxZoom: 14,
    uniqueIdProperty: 'supply_key'
  };
}

function buildLocatorLibraryLocationsTilesetProps(
  mapService: MapService,
  libraryId: number
) {
  return {
    id: 'LOCATOR_LIBRARY_LOCATIONS_TILESET',
    name: 'Locations',
    description: 'Library locations',
    data: `${mapService.getBaseUrl()}api/mapping/locator/libraries/${libraryId}/dynamic-tileset/locations`,
    visible: false,
    getFillColor: [255, 255, 255],
    getLineColor: [0, 0, 0, 100],
    getPointRadius: 8,
    lineWidthMinPixels: 2,
    minTrigger: 5,
    maxTrigger: 20,
    maxZoom: 14,
    uniqueIdProperty: 'id',
    hiddenTooltipColumns: ['id']
  };
}

function applyUserLocatorLibraryLocationsTilesetProps(
  defaultProperties: any,
  mapTemplates: any[]
) {
  var userDefinedTemplate = mapTemplates.find(
    (t) => t.identifier == locatorLibraryLocationsTilesetLayerIdentifier
  );

  if (userDefinedTemplate) {
    let userLayer = buildMapLayer(
      userDefinedTemplate.type,
      userDefinedTemplate
    );

    if (userLayer) {
      return {
        ...defaultProperties,
        ...userLayer.userDefinedProps,
        data: defaultProperties.data
      };
    } else {
      return defaultProperties;
    }
  }
  return defaultProperties;
}

// data for this layer comes from the following end point
// api/mapping/spatial-modeller/scenario/{scenarioId}/delta/thematic-attribute-name/fascia/locations
// It has to be set when the scenario is loaded
export async function addSmModelDeltaSystemLayer(
  mapService: MapService,
  scenarioId: number
) {
  if (
    !mapService.removeSystemLayerIdentifiers.includes(
      smScenarioDeltaLayerIdentifier
    )
  ) {
    let thematicProps = {
      id: smScenarioDeltaLayerIdentifier,
      name: 'Changes',
      description: 'Changes',
      data: `${mapService.getBaseUrl()}api/mapping/spatial-modeller/scenario/${scenarioId}/delta/thematic-attribute-name/fascia/locations`,
      visible: true,
      iconThematicScale: 'colorCategories',
      iconThematicScaleConfig: {
        attr: 'fascia',
        domain: [],
        colors: 'Temps',
        othersColor: [192, 192, 192]
      }
    };

    var thematicAtlasMapMarkerLayer = new ThematicAtlasMapMarkerLayer(
      thematicProps
    );
    thematicAtlasMapMarkerLayer.zOrder = 50;
    thematicAtlasMapMarkerLayer.type = 'ThematicInSiteEverywhereMapPin';
    await mapService.addLayer(thematicAtlasMapMarkerLayer);
  }
}

// This layer is shown with the SM model results the defualt value is 15 min drivetime
// The drive time can be overridden if it is the description should reflect the new value
export async function addSmModelResultDrivetimeLayer(mapService: MapService) {
  if (
    !mapService.removeSystemLayerIdentifiers.includes(
      smModelResultsDriveTimeLayerIdentifier
    )
  ) {
    // data: 'api/lds/isolines?lat=51.5072&lng=0.1276&mode=car&range=900&range_type=time',
    let props = {
      id: smModelResultsDriveTimeLayerIdentifier,
      name: 'Travel time (15 min)',
      description:
        'The area that can be reached from the selected location within 15 minutes at average road speeds.',
      data: [],
      getFillColor: [255, 255, 255, 0], // transparent
      lineWidthMinPixels: 3,
      ignoreClickEvent: true,
      disableTooltip: true
    };

    var boundaryLayer = new BoundaryLayer(props);
    boundaryLayer.zOrder = 22;
    boundaryLayer.type = 'Boundary';
    await mapService.addLayer(boundaryLayer);
  }
}

export async function addSmModelResultCircleLayer(mapService: MapService) {
  if (
    !mapService.removeSystemLayerIdentifiers.includes(
      smModelResultsCircleLayerIdentifier
    )
  ) {
    // data: 'api/shape/circle?lat=51.5072&lng=0.1276&unit=km&distance=1',
    let props = {
      id: smModelResultsCircleLayerIdentifier,
      name: 'Circle (2 Miles)',
      description: 'Circle (2 Miles)',
      data: [],
      getFillColor: [255, 255, 255, 0], // transparent
      lineWidthMinPixels: 3,
      ignoreClickEvent: true,
      disableTooltip: true
    };

    var boundaryLayer = new BoundaryLayer(props);
    boundaryLayer.zOrder = 24;
    boundaryLayer.type = 'Boundary';
    await mapService.addLayer(boundaryLayer);
  }
}

export async function addLocatorContourLayer(mapService: MapService) {
  if (
    !mapService.removeSystemLayerIdentifiers.includes(
      locatorDataShapesLayerIdentifier
    )
  ) {
    //data: `${environment.baseUrl}api/mapping/locator/libraries/${libraryId}/shapes/${shapeId}/boundaries`
    let props = {
      id: locatorDataShapesLayerIdentifier,
      name: 'Catchment',
      description: 'The selected catchment for the current location.',
      data: [],
      getFillColor: [255, 255, 255, 0], // transparent
      lineWidthMinPixels: 3,
      ignoreClickEvent: true,
      disableTooltip: true
    };

    var boundaryLayer = new BoundaryLayer(props);
    boundaryLayer.zOrder = 24;
    boundaryLayer.type = 'Boundary';
    await mapService.addLayer(boundaryLayer);
  }
}

export async function addEditablePolygonLayer(mapService: MapService) {
  let props = {
    id: locatorEditPolygonIdentifier,
    name: 'Edit free form polygon ',
    description:
      'Layer is used to add and edit free form polgyons for the point.',

    mode: ViewMode
  };

  var editPolygonLayer = new AtlasEditPolygonLayer(props);
  editPolygonLayer.zOrder = 97;
  editPolygonLayer.type = 'Boundary';
  await mapService.addLayer(editPolygonLayer);
}

// this layer started as a dynamic query tileset layer, there where errors when changing data based on shape, i think it is due to finalisation
// the possible solution would be to remove the layer and re added it. As we only now need points not boundaries we can use a Geojson layer
export async function addLocatorShapeBuildingBlockSelectionSystemLayer(
  mapService: MapService
) {
  if (
    !mapService.removeSystemLayerIdentifiers.includes(
      locatorDataShapeSpatialFunctionLayerIdentifier
    )
  ) {
    // data: 'api/mapping/locator/libraries/{libraryId}/shapes/{shapeId}/building-block/within/locations',
    let pointProps = {
      id: locatorDataShapeSpatialFunctionLayerIdentifier,
      name: 'Selected Output Areas',
      description:
        'The output areas whose centre is located within the catchment',
      data: [],
      getFillColor: [0, 0, 0, 200],
      getLineColor: [50, 50, 50, 100],
      pointRadiusUnits: 'pixels',
      getPointRadius: 5,
      stroked: false,
      opacity: 0.7
    };

    var pointLayer = new PointLayer(pointProps);
    pointLayer.zOrder = 30;
    pointLayer.type = 'Point';

    await mapService.addLayer(pointLayer);
  }
}

export function hideLocatorDataShapesMapLayer(mapService: MapService) {
  if (mapService.getLayer(locatorDataShapesLayerIdentifier)) {
    mapService.updateLayer(locatorDataShapesLayerIdentifier, {
      visible: false,
      data: []
    });
  }
}

export function hideLocatorLayersEffectedBySelectionChange(
  mapService: MapService
) {
  hideLocatorDataShapesMapLayer(mapService);
  hideLocatorSelectedShapeMapLayer(mapService);
  hideLocatorSelectedLocationMapLayer(mapService);
}

export function hideLocatorSelectedShapeMapLayer(mapService: MapService) {
  const shapeLayersByFeatureAction = mapService.getLayersByFeatureAction(
    featureActions.selectedShape.actionType
  );
  shapeLayersByFeatureAction.forEach((layer) => {
    if (layer && layer.id) {
      let data = layer.props.isTilesetLayer
        ? mapService.getBaseUrl() + 'api/mapping/tile/empty-dataset/{z}/{x}/{y}'
        : [];

      mapService.updateLayer(layer.id, {
        visible: false,
        data: data
      });
    }
  });
}

export function hideLocatorSelectedLocationMapLayer(mapService: MapService) {
  const locationLayersByFeatureAction = mapService.getLayersByFeatureAction(
    featureActions.selectedLocation.actionType
  );
  locationLayersByFeatureAction.forEach((layer) => {
    let data = layer.props.isTilesetLayer
      ? mapService.getBaseUrl() + 'api/mapping/tile/empty-dataset/{z}/{x}/{y}'
      : [];

    mapService.updateLayer(layer.id, {
      visible: false,
      data: data
    });
  });
}

export function hideLocatorShapeSpatialFunctionSystemLayer(
  mapService: MapService
) {
  if (
    !mapService.removeSystemLayerIdentifiers.includes(
      locatorDataShapeSpatialFunctionLayerIdentifier
    )
  ) {
    if (mapService.getLayer(locatorDataShapeSpatialFunctionLayerIdentifier)) {
      mapService.updateLayer(locatorDataShapeSpatialFunctionLayerIdentifier, {
        visible: false,
        data: []
      });
    }
  }
}

export function hideLocatorEditPolygonSystemLayer(mapService: MapService) {
  if (mapService.getLayer(locatorEditPolygonIdentifier)) {
    mapService.updateLayer(locatorEditPolygonIdentifier, {
      visible: false,
      data: [],
      mode: DrawPolygonMode,
      selectedFeatureIndexes: []
    });
  }
}

export function hideSmLayersEffectedBySupplyPointSelection(
  mapService: MapService
) {
  hideModelTestResultsMapLayer(mapService);
  hideModelResultsDrivetimeMapLayer(mapService);
  hideModelResultsCircleMapLayer(mapService);
  hideSmSelectedSupplyPointMapLayer(mapService);
}

export function hideSmSelectedSupplyPointMapLayer(mapService: MapService) {
  const supplyPointLayersByFeatureAction = mapService.getLayersByFeatureAction(
    featureActions.selectedSupplyPoint.actionType
  );
  supplyPointLayersByFeatureAction.forEach((layer) => {
    if (layer && layer.id) {
      let data = layer.props.isTilesetLayer
        ? mapService.getBaseUrl() + 'api/mapping/tile/empty-dataset/{z}/{x}/{y}'
        : [];

      mapService.updateLayer(layer.id, {
        visible: false,
        data: data
      });
    }
  });
}

export function hideModelTestResultsMapLayer(mapService: MapService) {
  if (mapService.getLayer(smModelTestResultsLayerIdentifier)) {
    mapService.updateLayer(smModelTestResultsLayerIdentifier, {
      visible: false,
      data: []
    });
  }
}

export function hideModelResultsDrivetimeMapLayer(mapService: MapService) {
  if (mapService.getLayer(smModelResultsDriveTimeLayerIdentifier)) {
    mapService.updateLayer(smModelResultsDriveTimeLayerIdentifier, {
      visible: false,
      data: []
    });
  }
}

export function hideModelResultsCircleMapLayer(mapService: MapService) {
  if (mapService.getLayer(smModelResultsCircleLayerIdentifier)) {
    mapService.updateLayer(smModelResultsCircleLayerIdentifier, {
      visible: false,
      data: []
    });
  }
}

export function hidePinDropLayerIdentifier(mapService: MapService) {
  if (mapService.getLayer(pinDropLayerIdentifier)) {
    mapService.updateLayer(pinDropLayerIdentifier, {
      data: []
    });
  }
}

export async function addSpatialModellerSystemLayers(
  mapService: MapService,
  scenarioId: number,
  networkPlanningUseTilesetForBaseLayers: boolean
) {
  addSmModelTestResultsSystemLayer(mapService);
  addSmModelResultDrivetimeLayer(mapService);
  addSmModelResultCircleLayer(mapService);

  if (!networkPlanningUseTilesetForBaseLayers) {
    addSmModelBaseSystemLayer(mapService, scenarioId);
  }
}

/*Tilesets data source cannot be changed once the tileset has been created.
The only way to change the layer is to destroy it and recreated.
tilesets take longer to create than GeoJson.
Due to this delay the layer sync does not work in create the system layer, then update the properties on the system layer with the user overrides.
To get round this, the  layer takes the users overrides when creating the system layer to do it all in one hit.
the  layer user overrides are excluded from the usual layer creation and done last based on the base tileset layer setting.
Note Tilesets dont render if empty.
 */
export async function addLocatorLibraryLocationsSystemTilesetLayers(
  mapService: MapService,
  libraryId: number,
  mapTemplates: any[]
) {
  var defaultProperties = buildLocatorLibraryLocationsTilesetProps(
    mapService,
    libraryId
  );

  var layerProperties = applyUserLocatorLibraryLocationsTilesetProps(
    defaultProperties,
    mapTemplates
  );

  var pointTileSetLayer = new PointTileSetLayer(layerProperties);
  pointTileSetLayer.type = 'PointTileSet';

  pointTileSetLayer.zOrder = 70;

  await mapService.addLayer(pointTileSetLayer);
}

/*Tilesets data source cannot be changed once the tileset has been created.
The only way to change the layer is to destroy it and recreated.
tilesets take longer to create than GeoJson.
Due to this delay the layer sync does not work in create the system layer, then update the properties on the system layer with the user overrides.
To get round this, the base layer takes the users overrides when creating the system layer to do it all in one hit.
the base layer user overrides are excluded from the usual layer creation and done last based on the base tileset layer setting.
Only Base layer tileset is created and not Delta as Tilesets don’t render if empty.
GeoJson will be ok for delta as they tend to be in the tens or hundreds of points not thousands. */
export async function addSpatialModelSystemTilesetLayers(
  mapService: MapService,
  scenarioId: number,
  mapTemplates: any[]
) {
  addSmModelBaseTilesetSystemLayer(
    mapService,
    scenarioId!,
    mapTemplates.find(
      (t) => t.identifier == smScenarioBaseTilesetLayerIdentifier
    )
  );
}

export async function addLocatorSystemLayers(mapService: MapService) {
  addEditablePolygonLayer(mapService);
  addLocatorContourLayer(mapService);
  addLocatorShapeBuildingBlockSelectionSystemLayer(mapService);
}

export async function addProfilerSystemLayers(mapService: MapService) {}
