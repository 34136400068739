import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector
} from '@ngrx/store';
import * as fromSpatialModellerTest from './spatial-modeller-test.reducer';
import * as fromSpatialModellerStats from './spatial-modeller-stats.reducer';
import * as fromImportScenario from './sm-import-scenario-data.reducer';
import * as fromReportExport from './sm-report.reducer';
import * as fromScenario from './scenario.reducer';

export const spatialModellerFeatureKey = 'spatialModeller';

export interface State {
  spatialModellerTest: fromSpatialModellerTest.State;
  spatialModellerStats: fromSpatialModellerStats.State;
  importScenarioData: fromImportScenario.State;
  reportExport: fromReportExport.State;
  scenario: fromScenario.State;
}

export const reducers: ActionReducerMap<State> = {
  spatialModellerTest: fromSpatialModellerTest.spatialModellerTestReducer,
  spatialModellerStats: fromSpatialModellerStats.spatialModellerStatsReducer,
  importScenarioData: fromImportScenario.smImportScenarioReducer,
  reportExport: fromReportExport.smReportingReducer,
  scenario: fromScenario.scenarioReducer
};

export const getSpatialModellerModuleState = createFeatureSelector<State>(
  spatialModellerFeatureKey
);

export const getSpatialModellerTestState = createSelector(
  getSpatialModellerModuleState,
  (state) => state && state.spatialModellerTest
);

export const getSpatialModellerStatsState = createSelector(
  getSpatialModellerModuleState,
  (state) => state && state.spatialModellerStats
);

export const getSpatialModellerImportScenarioDataState = createSelector(
  getSpatialModellerModuleState,
  (state) => state && state.importScenarioData
);

export const getExportReportState = createSelector(
  getSpatialModellerModuleState,
  (state) => state && state.reportExport
);

export const getScenarioState = createSelector(
  getSpatialModellerModuleState,
  (state) => state && state.scenario
);
