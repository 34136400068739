import { KeyValue } from '@angular/common';
import { createReducer, on } from '@ngrx/store';
import {
  createScenarioSucceeded,
  deleteScenarioSucceeded,
  getScenarioSucceeded,
  swapScenarioSucceeded,
  updateScenarioSucceeded,
  getDeltaSupplyPointsSummarySucceeded,
  clearScenarioStateOnLogoff,
  clearDeltaSupplyPointsState,
  getAllDeltaSupplyPointsSucceeded
} from 'src/app/spatial-modeller-store/actions/scenario.actions';
import { Base } from 'src/app/spatial-modeller/models/base';
import { Delta } from 'src/app/spatial-modeller/models/delta';
import { DeltaSummary } from 'src/app/spatial-modeller/models/delta-summary';
import { SmImportStatus } from 'src/app/spatial-modeller/models/sm-import-status';
import { SmTest } from 'src/app/spatial-modeller/models/sm-test';
import { pollSmImportScenarioDataStatusCompleted } from '../actions/spatial-modeller-import-status.actions';

export interface State {
  id: number;
  name: string;
  createDt: Date | null;
  editedDt: Date | null;
  tests: SmTest[];
  base: Base[];
  delta: Delta[];
  dependentScenarios: KeyValue<number, string>[];
  deltaSummary: DeltaSummary;
}

export const initialState: State = {
  id: 0,
  name: '',
  createDt: null,
  editedDt: null,
  tests: [],
  base: [],
  delta: [],
  dependentScenarios: [],
  deltaSummary: { added: 0, edited: 0, closed: 0 }
};

export const scenarioReducer = createReducer(
  initialState,
  on(
    getScenarioSucceeded,
    createScenarioSucceeded,
    updateScenarioSucceeded,
    swapScenarioSucceeded,
    (state, { scenario }) => {
      return {
        ...state,
        id: scenario.id,
        name: scenario.name,
        createDt: scenario.createDt,
        editedDt: scenario.editedDt,
        tests: scenario.tests,
        base: scenario.base,
        delta: [{ ...scenario.delta[0], supplyPoints: [] }],
        dependentScenarios: scenario.dependentScenarios
      };
    }
  ),
  on(deleteScenarioSucceeded, (state, { id }) => {
    return {
      ...state,
      dependentScenarios: state.dependentScenarios.filter(
        (keyValue) => keyValue.key !== id
      )
    };
  }),
  on(getDeltaSupplyPointsSummarySucceeded, (state, { summary }) => {
    return {
      ...state,
      deltaSummary: summary
    };
  }),

  on(clearScenarioStateOnLogoff, (state) => {
    return {
      ...initialState
    };
  }),

  on(getAllDeltaSupplyPointsSucceeded, (state, { deltaSupplyPoints }) => {
    let delta = [{ ...state.delta[0], supplyPoints: deltaSupplyPoints }];

    return {
      ...state,
      delta
    };
  }),

  on(clearDeltaSupplyPointsState, (state) => {
    return {
      ...state,
      delta: [{ ...state.delta[0], supplyPoints: [] }]
    };
  }),
  on(pollSmImportScenarioDataStatusCompleted, (state, { smImportStatus }) => {
    return {
      ...state,
      deltaSummary: {
        ...state.deltaSummary,
        added: state.deltaSummary.added + smImportStatus.importedRecords
      }
    };
  })
);

export const getId = (state: State) => state && state.id;
export const getName = (state: State) => state && state.name;
export const getBase = (state: State) => state && state.base;
export const getDelta = (state: State) => state && state.delta;
export const getTests = (state: State) => state && state.tests;
export const getDeltaSummary = (state: State) => state && state.deltaSummary;
