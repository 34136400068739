import { SupplyPointAttribute } from '../models/supply-point-attribute';

const excludedHeaders: string[] = [
  'supplykey',
  'supplyid',
  'location',
  'name',
  'address1',
  'address2',
  'address3',
  'address4',
  'postal',
  'country'
];
const fixedHeaders = [
  'name',
  'address1',
  'address2',
  'address3',
  'address4',
  'postal',
  'country',
  'latitude',
  'longitude'
];

export function GetDynamicImportTemplateHeaders(
  supplyPointAttributeList: SupplyPointAttribute[]
): string[] {
  const supplyPointAttributesHeaders = supplyPointAttributeList
    ?.filter((attribute) => !excludedHeaders.includes(attribute.name))
    .sort((att1, att2) => att1.displayOrder - att2.displayOrder)
    .map((attribute) => attribute.name);
  return [...fixedHeaders, ...supplyPointAttributesHeaders];
}

export function generateNetworkPlanningImportTemplate(
  supplyPointAttributeList: SupplyPointAttribute[]
): void {
  const fileName = 'import_locations_template.csv';
  const headers = GetDynamicImportTemplateHeaders(supplyPointAttributeList);

  if (!headers || headers.length === 0) {
    throw new Error('Headers array cannot be empty.');
  }

  // Generate the header row
  const headerRow = headers.join(',') + '\n';

  // Create a Blob with the CSV content
  const csvBlob = new Blob([headerRow], { type: 'text/csv;charset=utf-8;' });

  // Create a link element for download
  const link = document.createElement('a');
  const url = URL.createObjectURL(csvBlob);
  link.setAttribute('href', url);
  link.setAttribute('download', fileName);

  // Append the link to the document and trigger the download
  document.body.appendChild(link);
  link.click();

  // Cleanup
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
}
