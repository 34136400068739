import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, filter, switchMap, withLatestFrom } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  pollLocatorImportLibraryDataStatusStarted,
  pollLocatorImportLibraryDataStatusInProgress,
  pollLocatorImportLibraryDataStatusCompleted,
  pollLocatorImportLibraryDataStatusFailed,
  openLocatorImportDialogAfterLoadingApp
} from '../actions/locator-import-status.actions';
import { LocatorService } from 'src/app/locator/services/locator.service';
import { Store } from '@ngrx/store';
import { State } from '../reducers';
import * as fromLocatorStore from 'src/app/locator-store';
import { locatorFileImportHeaderValidationSuccess } from '../actions/locator-import-validation.actions';
import { DialogService } from 'src/app/core/services/dialog.service';
import { DialogWidth } from 'src/app/shared/atlas-dialog/enums/dialog-width.enum';
import { DialogMode } from 'src/app/shared/atlas-dialog/enums/dialog-mode.enum';
import { LocatorImportLibraryDataDialogComponent } from 'src/app/core/components/library-management/locator-import-library-data-dialog/locator-import-library-data-dialog.component';
import { getLocatorLibrarySucceeded } from '../actions/locator-library.actions';

@Injectable()
export class LocatorImportStatusEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<State>,
    private locatorService: LocatorService,
    private dialogService: DialogService
  ) {}

  restartPollingAfterReuploadEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(locatorFileImportHeaderValidationSuccess),
      withLatestFrom(this.store$.select(fromLocatorStore.getLocatorLibraryId)),
      map(([_, libraryId]) => {
        return pollLocatorImportLibraryDataStatusStarted({ libraryId });
      })
    )
  );

  pollLocatorImportStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(pollLocatorImportLibraryDataStatusStarted),
      switchMap(({ libraryId }) =>
        this.locatorService
          .pollForLocatorImportLibraryDataStatus(libraryId)
          .pipe(
            withLatestFrom(
              this.store$.select(fromLocatorStore.getLocatorLibraryId)
            ),
            filter(
              ([locatorImportStatus, currentLibraryId]) =>
                locatorImportStatus?.libraryId === currentLibraryId
            ),
            map(([locatorImportStatus, _]) => {
              if (
                locatorImportStatus.status ===
                'ImportLocatorLibraryDataCompleted'
              ) {
                return pollLocatorImportLibraryDataStatusCompleted({
                  locatorImportStatus
                });
              } else {
                return locatorImportStatus.errorMessage != null
                  ? pollLocatorImportLibraryDataStatusFailed({
                      locatorImportStatus
                    })
                  : pollLocatorImportLibraryDataStatusInProgress({
                      locatorImportStatus
                    });
              }
            })
          )
      )
    )
  );

  getLocatorImportLibraryDataSucceded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getLocatorLibrarySucceeded),
      switchMap(({ library }) =>
        this.locatorService.getImportLocatorLibraryDataStatus(library.id).pipe(
          map((locatorImportStatus) => {
            return openLocatorImportDialogAfterLoadingApp({
              locatorImportStatus
            });
          })
        )
      )
    )
  );

  openLocatorImportDialogAfterLoadingApp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(openLocatorImportDialogAfterLoadingApp),
      filter(
        ({ locatorImportStatus }) =>
          locatorImportStatus &&
          locatorImportStatus?.status !== 'ImportLocatorLibraryDataCompleted' &&
          locatorImportStatus?.status !== 'FileUploadFailed'
      ),
      map(({ locatorImportStatus }) => {
        this.onOpenLocatorImportDataDialog(locatorImportStatus.libraryId);
        return pollLocatorImportLibraryDataStatusStarted({
          libraryId: locatorImportStatus.libraryId
        });
      })
    )
  );

  private onOpenLocatorImportDataDialog(libraryId: number) {
    this.dialogService.show(LocatorImportLibraryDataDialogComponent, {
      width: DialogWidth.Medium,
      panelClass: 'dialog-full-width-height',
      data: {
        entityName: '',
        mode: DialogMode.Add,
        headerPrefix: 'Import Locations',
        affirmativeButtonText: 'Continue',
        libraryId,
        tenantImportUsingGeocoding: false,
        openAfterRefreshingApp: true
      },
      disableClose: true
    });
  }
}
