import { SnackbarEffects } from './snack-bar.effects';
import { SpatialModellerTestEffects } from './spatial-modeller-test.effects';
import { SpatialModellerStatsEffects } from './spatial-modeller-stats.effects';
import { SmReportingEffects } from './sm-reporting.effects';
import { ScenarioEffects } from './scenario.effect';
import { SupplyPointEffects } from './supply-point.effect';
import { MapLayerEffects } from './map-layer.effects';
import { SpatialModellerImportStatusEffects } from './spatial-modeller-import-status.effects';
import { SmImportEffects } from './sm-import.effects';

export const effects = [
  SpatialModellerTestEffects,
  SpatialModellerStatsEffects,
  SpatialModellerImportStatusEffects,
  SnackbarEffects,
  SmReportingEffects,
  ScenarioEffects,
  SupplyPointEffects,
  MapLayerEffects,
  SmImportEffects
];
