import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Part } from '@aws-sdk/client-s3';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SmImportService {
  private atlasSpatialModellerImportUrl = `${environment.baseUrl}api/spatial-modeller/import-scenario-data`;

  constructor(private http: HttpClient) {}

  public fileUploadStartedStatus(scenarioId: number) {
    return this.http.put(
      `${this.atlasSpatialModellerImportUrl}/file-upload-started/${scenarioId}`,
      null
    );
  }

  public fileUploadCompleteStatus(scenarioId: number) {
    return this.http.put(
      `${this.atlasSpatialModellerImportUrl}/file-upload-complete/${scenarioId}`,
      null
    );
  }

  public fileUploadFailedStatus(scenarioId: number, errorMsg: string) {
    return this.http.put(
      `${this.atlasSpatialModellerImportUrl}/file-upload-failed`,
      {
        scenarioId: scenarioId,
        errorMessage: errorMsg
      }
    );
  }

  public fileUploadUpdateFileKey(scenarioId: number, fileKey: string) {
    return this.http.put(
      `${this.atlasSpatialModellerImportUrl}/update-filekey/${scenarioId}/${fileKey}`,
      null
    );
  }

  public uploadMetaDataConfig(
    fileKey: string,
    scenarioId: any,
    fileHeaders: string[],
    importUsingGeocoding: boolean
  ) {
    return this.http.post(
      `${this.atlasSpatialModellerImportUrl}/upload-meta-data/`,
      {
        fileKey: fileKey,
        scenarioId,
        fileHeaders,
        importUsingGeocoding
      }
    );
  }

  public getExportedPostcodeWithAcornUrl(filekey: string, scenarioId: any) {
    return this.http.get<{ presignedUrl: any }>(
      `${this.atlasSpatialModellerImportUrl}/exported-postcodes/presigned-url/${scenarioId}/${filekey}`
    );
  }

  public deleteFilesFromS3(fileKey: string) {
    return this.http.delete(
      `${this.atlasSpatialModellerImportUrl}/delete-filekey/${fileKey}`
    );
  }

  public initiateMultipartUpload(filename: string) {
    return this.http.get<{ uploadId: string }>(
      `${this.atlasSpatialModellerImportUrl}/initiate-multipart-upload/${filename}`
    );
  }

  async getMultipartPresignedUrl(
    filename: string,
    uploadId: string,
    partNumber: number
  ) {
    const httpResponse$ = this.http.get<{ presignedUrl: string }>(
      `${this.atlasSpatialModellerImportUrl}/presigned-url/${filename}/${uploadId}/${partNumber}`
    );
    return await firstValueFrom(httpResponse$);
  }

  public completeMultipartUpload(
    scenarioId: number,
    filename: string,
    uploadId: string,
    partsList: Part[]
  ) {
    return this.http.put(
      `${this.atlasSpatialModellerImportUrl}/complete-multipart-upload/${scenarioId}/${filename}/${uploadId}`,
      partsList.map(({ ETag, PartNumber }) => ({
        ETag: ETag,
        PartNumber: PartNumber
      }))
    );
  }

  public cancelMultipartUpload(filename: string, uploadId: string) {
    return this.http.delete(
      `${this.atlasSpatialModellerImportUrl}/cancel-multipart-upload/${filename}/${uploadId}`
    );
  }

  public hasSpatialModellerDeltaLimitBeenExceeded(scenarioId: number) {
    return this.http.get<any>(
      `${this.atlasSpatialModellerImportUrl}/${scenarioId}/exceeded-scenario-limit`
    );
  }

  public clearScenarioImportStatus(scenarioId: number) {
    return this.http.delete(
      `${this.atlasSpatialModellerImportUrl}/${scenarioId}/clear-import-status`
    );
  }
}
