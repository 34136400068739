import { createAction, props } from '@ngrx/store';
import { LocatorImportStatus } from 'src/app/locator/models/locator-import-status';

export const pollLocatorImportLibraryDataStatusStarted = createAction(
  '[Locator Import Status Effects] Poll Locator Import Status Started',
  props<{
    libraryId: number;
  }>()
);

export const pollLocatorImportLibraryDataStatusInProgress = createAction(
  '[Locator Import Status Effects] Poll Locator Import Status In Progress',
  props<{
    locatorImportStatus: LocatorImportStatus;
  }>()
);

export const pollLocatorImportLibraryDataStatusCompleted = createAction(
  '[Locator Import Status Effects] Poll Locator Import Status Completed',
  props<{
    locatorImportStatus: LocatorImportStatus;
  }>()
);

export const pollLocatorImportLibraryDataStatusFailed = createAction(
  '[Locator Import Status Effects] Poll Locator Import Status Failed',
  props<{
    locatorImportStatus: LocatorImportStatus;
  }>()
);

export const openLocatorImportDialogAfterLoadingApp = createAction(
  '[Locator Import Status Effects] Open Locator Import Dialog After Loading App',
  props<{
    locatorImportStatus: LocatorImportStatus;
  }>()
);
