import { ExportService } from './spatial-modeller-reporting.service';
import { ScenarioService } from './scenario.service';
import { SpatialModellerDataService } from './spatial-modeller-data.service';
import { SmImportValidationService } from './sm-import-validation.service';
import { SmUploadService } from './sm-upload.service';
import { SmImportService } from './sm-import.service';

export const services = [
  ExportService,
  ScenarioService,
  SpatialModellerDataService,
  SmImportValidationService,
  SmUploadService,
  SmImportService
];
