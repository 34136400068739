import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Part } from '@aws-sdk/client-s3';
import { v4 as uuidv4 } from 'uuid';
import { environment } from 'src/environments/environment';
import * as fromLocatorStore from 'src/app/locator-store';
import {
  locatorAbortMultipartUpload,
  locatorFileUploadError,
  locatorFileUploadUpdateFileKey,
  locatorInitiateMultipartUploadAttempt,
  locatorMetaDataUploadAttempt,
  locatorMultipartUploadComplete
} from 'src/app/locator-store/actions/locator-import.actions';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import {
  uploadFileInOneGo,
  uploadFileMultiPart
} from 'src/app/shared/utils/file-upload-utils';

// The size at which point to upload using multipart upload
const singleFileUploadSize = 10 * 1024 * 1024; // 10MB in bytes

// Each mulitpart part size must be at least 5MB
const multipartFileUploadMinimumSize = 5 * 1024 * 1024; // 5MB in bytes

@Injectable({
  providedIn: 'root'
})
export class LocatorUploadService {
  private atlasLocatorImportUrl = `${environment.baseUrl}api/locator/import-library-data`;
  private guidFilename: string;

  constructor(
    private http: HttpClient,
    private store$: Store<fromLocatorStore.State>
  ) {}

  public uploadFile(file: File) {
    this.guidFilename = `${uuidv4()}.csv`;
    this.store$.dispatch(
      locatorFileUploadUpdateFileKey({ fileKey: this.guidFilename })
    );

    if (file.size <= singleFileUploadSize) {
      this.uploadFileInOneGo(file);
    } else {
      this.store$.dispatch(
        locatorInitiateMultipartUploadAttempt({
          file: file,
          fileKey: this.guidFilename
        })
      );
    }
  }

  public async uploadFileInOneGo(file: File) {
    try {
      await uploadFileInOneGo(
        file,
        this.http,
        this.atlasLocatorImportUrl,
        this.guidFilename
      );
      this.store$.dispatch(
        locatorMetaDataUploadAttempt({ fileKey: this.guidFilename })
      );
    } catch (error) {
      this.handleUploadError(file.name, error);
    }
  }

  public async uploadFileMultiPart(file: File, uploadId: string) {
    try {
      const eTagParts = await uploadFileMultiPart(
        file,
        uploadId,
        this.guidFilename,
        this.http,
        this.atlasLocatorImportUrl
      );
      this.store$.dispatch(
        locatorMultipartUploadComplete({
          uploadId,
          filename: this.guidFilename,
          parts: eTagParts
        })
      );
    } catch (err) {
      this.store$.dispatch(
        locatorAbortMultipartUpload({
          uploadId,
          filename: this.guidFilename,
          errorMessage: err
        })
      );
    }
  }

  private handleUploadError(filename: string, error: any) {
    this.store$.dispatch(
      locatorFileUploadError({ fileKey: filename, errors: error })
    );
  }
}
