import { Component, Input } from '@angular/core';

@Component({
  selector: 'atlas-sm-import-scenario-data-form-3',
  templateUrl: './sm-import-scenario-data-form-3.component.html',
  styleUrl: './sm-import-scenario-data-form-3.component.less'
})
export class SmImportScenarioDataForm3Component {
  @Input() processedRecords: number;
  @Input() importedRecords: number;

  constructor() {}
}
