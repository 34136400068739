import { Component } from '@angular/core';

import * as fromProfilerStore from 'src/app/profiler-store';
import { Store } from '@ngrx/store';
import {
  baseSelectionChanged,
  classificationSelectionChanged
} from 'src/app/profiler-store/actions/profiler-library.actions';
import { MapLayerStyleService } from 'src/app/shared/atlas-mapping/services/map-layer-style-service';
import { profilerLocationsLayerIdentifier } from 'src/app/shared/atlas-mapping/layers/layer.constants';
@Component({
  selector: 'atlas-profiler-base-classification-selection',
  templateUrl: './profiler-base-classification-selection.component.html',
  styleUrls: ['./profiler-base-classification-selection.component.less']
})
export class ProfilerBaseClassificationSelectionComponent {
  selectedBaseId$ = this.store$.select(fromProfilerStore.getSelectedBaseId);

  selectedClassificationId$ = this.store$.select(
    fromProfilerStore.getSelectedClassificationId
  );

  bases$ = this.store$.select(fromProfilerStore.getBases);
  classifications$ = this.store$.select(fromProfilerStore.getClassifications);

  constructor(
    private store$: Store<fromProfilerStore.State>,
    private layerStyleService: MapLayerStyleService
  ) {}

  onBaseSelectionChange(event: any) {
    this.store$.dispatch(baseSelectionChanged({ selectedBaseId: event.value }));
  }

  onClassificationSelectionChange(event: any) {
    this.store$.dispatch(
      classificationSelectionChanged({ selectedClassificationId: event.value })
    );

    // Clear Orginal colors and domains to allow spotlighting
    this.layerStyleService.clearSpotLightLayerColors(
      profilerLocationsLayerIdentifier
    );
  }
}
