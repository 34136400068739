import { createAction, props } from '@ngrx/store';
import { Point } from 'src/app/shared/atlas-gazetteer/models/point.model';
import { MapLayerTemplate } from '../../models/map-layer-template';

export const changeLocationClicked = createAction(
  '[Location panel] Change Location Clicked',
  props<{ address: string | undefined; location: Point | null | undefined }>()
);

export const changeLocationConfirmedAttempt = createAction(
  '[Location panel] Change Location Confirmed Attempt'
);

export const changeLocationConfirmedSucceeded = createAction(
  '[Location panel] Change Location Confirmed Succeeded',
  props<{ location: Point }>()
);

export const changeLocationCancelled = createAction(
  '[Location panel] Change Location Cancelled'
);

export const changeLocationCancelledCompleted = createAction(
  '[Location panel] Change Location Cancelled Complete',
  props<{ location: Point }>()
);

export const revertToExistingSelectedLocation = createAction(
  '[Location panel] Revert To Existing Selected Location',
  props<{ location: Point }>()
);

export const newLocationSelected = createAction('[Map] New Location Selected');

export const resetChangeLocationState = createAction(
  '[Logout] Resetting change location state'
);

export const smFeatureClicked = createAction(
  '[Main Toolbar] Spatial Modeller Clicked'
);

export const smLayersSucceeced = createAction(
  '[Loading Succeeded] Layers succeeded',
  props<{ smMapLayerTemplates: MapLayerTemplate[] }>()
);

export const logoutClearSpotlightLayers = createAction(
  '[Logout] Clear Spot light layers'
);
