import { Point } from 'src/app/shared/atlas-gazetteer/models/point.model';
import { SupplyPointAttributeKeyValue } from './supply-point-attribute-key-value';

// SupplyPoint must be a class (not an interface) and also need to have
// all its properties initialised in order to get its properties at runtime
export class SupplyPoint {
  supplyKey: number = 0;
  supplyId: string = '';
  uid: number = 0;
  fascia: string = '';
  name: string = '';
  location: Point = { latitude: 0, longitude: 0 };
  attributes: SupplyPointAttributeKeyValue[] = [];
  isClosed: boolean = false;
  userAction: UserAction = UserAction.Untouched;
  supplyPointGroupId: number = 0;
  address1: string | null = null;
  address2: string | null = null;
  address3: string | null = null;
  address4: string | null = null;
  matchLevel: string | null = null;
}

export enum UserAction {
  Untouched = 0,
  Added = 1,
  Edited = 2,
  Temporary = 3
}
