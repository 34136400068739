import {
  GeocodeLevel,
  mediumGeocodeLevels,
  strongGeocodeLevels,
  weakGeocodeLevels
} from 'src/app/locator/types/geocode-level';

export function styleWeakGeocodeLevel(text: GeocodeLevel, cellElement: any) {
  if (weakGeocodeLevels.includes(text)) {
    alterGeocodeLevelTrafficLight(
      'atlas-dxi-geocode-level-column-red',
      'Low accuracy',
      cellElement
    );
  }
}

export function styleMediumGeocodeLevel(text: GeocodeLevel, cellElement: any) {
  if (mediumGeocodeLevels.includes(text)) {
    alterGeocodeLevelTrafficLight(
      'atlas-dxi-geocode-level-column-amber',
      'Medium accuracy',
      cellElement
    );
  }
}

export function styleStrongGeocodeLevel(
  text: GeocodeLevel | string,
  cellElement: any
) {
  if (strongGeocodeLevels.includes(text as GeocodeLevel)) {
    alterGeocodeLevelTrafficLight(
      'atlas-dxi-geocode-level-column-green',
      'High accuracy',
      cellElement
    );
  }
}

export function styleDefaultTrafficLight(
  text: GeocodeLevel | string,
  cellElement: any
) {
  generateDefaultTrafficLight(
    'atlas-dxi-geocode-level-column-black',
    'Location provided',
    cellElement
  );
}

function generateDefaultTrafficLight(
  className: string,
  tooltipText: string,
  cellElement: any
) {
  // Obtain td element for current cell column index
  var parent = cellElement.parentNode.childNodes[cellElement.cellIndex];

  var wrapper = document.createElement('div');
  wrapper.className = `atlas-dxi-geocode-level-column ${className}`;
  var tooltip = document.createElement('span');
  tooltip.className = 'geocode-level-tooltip';
  tooltip.innerText = tooltipText;

  wrapper.appendChild(tooltip);
  parent.appendChild(wrapper);
}

function alterGeocodeLevelTrafficLight(
  className: string,
  tooltipText: string,
  cellElement: any
) {
  // Obtain td element for current cell column index
  var parent = cellElement.parentNode.childNodes[cellElement.cellIndex];

  var wrapper = parent.getElementsByTagName('div')[0];
  wrapper.className = `atlas-dxi-geocode-level-column ${className}`;

  var tooltip = parent.getElementsByTagName('span')[0];
  tooltip.innerText = tooltipText;
}
