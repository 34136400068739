import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  ViewChild
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import * as fromLocatorStore from 'src/app/locator-store';
import * as fromUsageStore from 'src/app/reporting/store';
import { DialogMode } from 'src/app/shared/atlas-dialog/enums/dialog-mode.enum';
import { Store } from '@ngrx/store';
import { LocatorImportLibraryDataFormComponent } from '../locator-import-library-data-form/locator-import-library-data-form';
import {
  clearLocatorImportStatusAttempt,
  hasLocatorLibraryLocationsLimitBeenExceededAttempt,
  locatorFileImportAttempt
} from 'src/app/locator-store/actions/locator-import.actions';
import { LocatorImportStatusProgressStep } from 'src/app/locator/models/locator-status-progress-step';
import { LocatorImportUiDialogSteps } from 'src/app/locator/helpers/locator-status-helper';
import { LocatorStatusError } from 'src/app/locator/models/locator-import-status-error';
import {
  hasGeocodingCompanyLimitBeenExceededAttempt,
  hasGeocodingLicenseExpiredAttempt
} from 'src/app/reporting/store/actions/usage.actions';

@Component({
  selector: 'atlas-locator-import-library-data-dialog',
  templateUrl: './locator-import-library-data-dialog.component.html',
  styleUrls: ['./locator-import-library-data-dialog.component.less']
})
export class LocatorImportLibraryDataDialogComponent
  implements OnInit, AfterViewInit
{
  dialogMode: DialogMode;
  userLibraries: string[] = [];
  hasLocatorLibraryLocationsLimitBeenExceeded: boolean;
  hasGeocodingCompanyLimitBeenExceeded: boolean;
  hasGeocodingCompanyLicenseExpired: boolean;
  @ViewChild('locatorImportLibraryDataForm')
  locatorImportLibraryDataForm: LocatorImportLibraryDataFormComponent;
  geocodingSelected: boolean;

  hasLocatorLibraryLocationsLimitBeenExceeded$ = this.locatorStore$.select(
    fromLocatorStore.getHasLocatorLibraryLocationsImportLimitBeenExceeded
  );

  hasCompanyGeocodingLimitBeenExceeded$ = this.usageStore$.select(
    fromUsageStore.getHasCompanyGeocodingLimitExceeded
  );

  hasCompanyGeocodingLicenseExpired$ = this.usageStore$.select(
    fromUsageStore.getHasCompanyGeocodingLicenseExpired
  );

  importProcessStepState: LocatorImportStatusProgressStep[] = [
    {
      name: 'select_csv_file',
      state: 'NotStarted'
    } as LocatorImportStatusProgressStep,
    {
      name: 'processing_data',
      state: 'NotStarted'
    } as LocatorImportStatusProgressStep
  ];
  locatorImportProcessStepState$ = this.locatorStore$.select(
    fromLocatorStore.getLocatorImportDataProgressSteps
  );
  locatorImportProcessStatus$ = this.locatorStore$.select(
    fromLocatorStore.getLocatorImportDataStatus
  );
  locatorImportStatusError: LocatorStatusError = {
    status: 'LocatorImportDialogOpened',
    errors: []
  };

  screenToShow: LocatorImportUiDialogSteps | 'summary' = 'select_csv_file';
  affirmativeButtonText: string;
  affirmativeButtonDisabled: boolean = false;
  currentStage: number = 1;
  totalRecords: number = 0;
  processedRecords: number = 0;
  importedRecords: number = 0;

  private subscription = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      entityName: string;
      headerPrefix: string;
      mode: DialogMode;
      libraryId: number;
      affirmativeButtonText: string;
      tenantImportUsingGeocoding: boolean;
      openAfterRefreshingApp: boolean;
    },
    private locatorStore$: Store<fromLocatorStore.State>,
    private usageStore$: Store<fromUsageStore.State>
  ) {
    this.affirmativeButtonText = data.affirmativeButtonText;
    this.locatorStore$.dispatch(
      hasLocatorLibraryLocationsLimitBeenExceededAttempt({
        libraryId: this.data.libraryId
      })
    );

    if (this.data.openAfterRefreshingApp) {
      this.addCheckScreenSubscriptions();
    }

    if (this.data.tenantImportUsingGeocoding) {
      this.usageStore$.dispatch(hasGeocodingCompanyLimitBeenExceededAttempt());
      this.usageStore$.dispatch(hasGeocodingLicenseExpiredAttempt());
    }
  }

  ngOnInit() {
    this.subscription.add(
      this.hasLocatorLibraryLocationsLimitBeenExceeded$.subscribe(
        (limitExceeded) => {
          this.hasLocatorLibraryLocationsLimitBeenExceeded = limitExceeded;
          this.checkLocatorLibraryLocationsLimitBeenExceeded();
        }
      )
    );

    if (this.data.tenantImportUsingGeocoding) {
      this.subscription.add(
        this.hasCompanyGeocodingLimitBeenExceeded$.subscribe(
          (limitExceeded) => {
            this.hasGeocodingCompanyLimitBeenExceeded = limitExceeded;
            this.checkGeocodingCompanyRestrictions();
          }
        )
      );
      this.subscription.add(
        this.hasCompanyGeocodingLicenseExpired$.subscribe((licenseExpired) => {
          this.hasGeocodingCompanyLicenseExpired = licenseExpired;
          this.checkGeocodingCompanyRestrictions();
        })
      );
    }
  }

  ngAfterViewInit(): void {
    this.geocodingSelected =
      this.locatorImportLibraryDataForm.locatorImportLibraryDataForm.controls.geocodingSelection.value;
    this.subscription.add(
      this.locatorImportLibraryDataForm.locatorImportLibraryDataForm.controls.geocodingSelection.valueChanges.subscribe(
        (geocodingSelected) => {
          this.geocodingSelected = geocodingSelected;
          this.checkLocatorLibraryLocationsLimitBeenExceeded();
          if (geocodingSelected) {
            this.checkGeocodingCompanyRestrictions();
          }
        }
      )
    );
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onLocatorImportDataClicked(
    locatorImportLibraryDataForm: LocatorImportLibraryDataFormComponent
  ) {
    if (this.currentStage === 1) {
      this.locatorImportStatusError.errors = [];
      if (locatorImportLibraryDataForm.locatorImportLibraryDataForm.valid) {
        this.locatorStore$.dispatch(
          locatorFileImportAttempt({
            file: locatorImportLibraryDataForm.selectedFile,
            importUsingGeocoding:
              locatorImportLibraryDataForm.locatorImportLibraryDataForm.controls
                .geocodingSelection.value
          })
        );
        this.addCheckScreenSubscriptions();
      }
    } else {
      this.screenToShow = 'summary';
      this.currentStage = this.getCurrentStage(this.screenToShow);
      this.affirmativeButtonText = 'Close';
    }
  }

  getNextScreen(
    importProcessStepState: LocatorImportStatusProgressStep[]
  ): LocatorImportUiDialogSteps {
    if (
      importProcessStepState.length === 0 ||
      importProcessStepState[0].state === 'NotStarted' ||
      importProcessStepState[0].state === 'Processing' ||
      importProcessStepState[0].state === 'Error'
    ) {
      return 'select_csv_file';
    } else {
      return 'processing_data';
    }
  }

  getCurrentStage(
    currentScreen: LocatorImportUiDialogSteps | 'summary'
  ): number {
    if (currentScreen === 'processing_data') {
      return 2;
    }
    if (currentScreen === 'summary') {
      return 3;
    }
    return 1;
  }

  onSelectedFileChanged(newFileName: string) {
    this.locatorImportStatusError.errors = [];
    this.checkLocatorLibraryLocationsLimitBeenExceeded();
    if (this.geocodingSelected) {
      this.checkGeocodingCompanyRestrictions();
    }
  }

  isAffirmativeButtonDisabled(
    locatorImportLibraryDataForm: LocatorImportLibraryDataFormComponent
  ) {
    return (
      (!this.data.openAfterRefreshingApp &&
        locatorImportLibraryDataForm?.invalidForm()) ||
      this.locatorImportStatusError.errors.length > 0 ||
      this.importProcessStepState[0]?.state === 'Processing' ||
      (this.importProcessStepState[0]?.state === 'Completed' &&
        this.importProcessStepState[1]?.state !== 'Completed')
    );
  }

  isCancelButtonDisabled() {
    return (
      this.importProcessStepState[0]?.state === 'Completed' &&
      this.importProcessStepState[1]?.state === 'Processing'
    );
  }

  checkGeocodingCompanyRestrictions() {
    // This error has priority over the others
    if (this.hasLocatorLibraryLocationsLimitBeenExceeded) {
      return;
    }

    if (this.data.tenantImportUsingGeocoding) {
      if (this.hasGeocodingCompanyLicenseExpired) {
        this.locatorImportStatusError.errors = [];
        this.locatorImportStatusError.errors.push({
          error: `The geocoding company license has expired. Please contact your system administrator.`
        });
      }
      if (this.hasGeocodingCompanyLimitBeenExceeded) {
        this.locatorImportStatusError.errors = [];
        this.locatorImportStatusError.errors.push({
          error: `The company limit of geocoded records has been reached. Please contact your system administrator.`
        });
      }
    }
  }

  checkLocatorLibraryLocationsLimitBeenExceeded() {
    this.locatorImportStatusError.errors = [];
    if (this.hasLocatorLibraryLocationsLimitBeenExceeded) {
      this.locatorImportStatusError.errors.push({
        error: `You have reached the limit for the number of locations in this library.`
      });
    }
  }
  addCheckScreenSubscriptions() {
    this.subscription.add(
      this.locatorImportProcessStepState$.subscribe(
        (importProcessStepState) => {
          this.importProcessStepState = importProcessStepState;
          this.screenToShow = this.getNextScreen(this.importProcessStepState);
          this.currentStage = this.getCurrentStage(this.screenToShow);
        }
      )
    );
    this.subscription.add(
      this.locatorImportProcessStatus$.subscribe(
        (locatorImportProcessStatus) => {
          if (locatorImportProcessStatus) {
            if (locatorImportProcessStatus.errorMessage) {
              this.locatorImportStatusError = JSON.parse(
                locatorImportProcessStatus?.errorMessage
              );
            }
            if (
              locatorImportProcessStatus.totalRecords != null &&
              locatorImportProcessStatus.processedRecords != null
            ) {
              this.totalRecords = locatorImportProcessStatus.totalRecords;
              this.processedRecords =
                locatorImportProcessStatus.processedRecords;
              this.importedRecords = locatorImportProcessStatus.importedRecords;
            }
          }
        }
      )
    );
  }

  onLocatorCancelImportDataClicked() {
    if (
      this.importProcessStepState[0]?.state === 'Error' ||
      this.importProcessStepState[1]?.state === 'Error'
    ) {
      this.locatorStore$.dispatch(
        clearLocatorImportStatusAttempt({
          libraryId: this.data.libraryId
        })
      );
    }
  }
}
