import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Part } from '@aws-sdk/client-s3';
import { v4 as uuidv4 } from 'uuid';
import { environment } from 'src/environments/environment';
import * as fromProfilerStore from 'src/app/profiler-store';
import {
  abortMultipartUpload,
  fileUploadError,
  fileUploadUpdateFileKey,
  initiateMultipartUploadAttempt,
  metaDataUploadAttempt,
  multipartUploadComplete
} from 'src/app/profiler-store/actions/profiler-import.actions';
import {
  uploadFileInOneGo,
  uploadFileMultiPart
} from 'src/app/shared/utils/file-upload-utils';

// The size at which point to upload using multipart upload
const singleFileUploadSize = 10 * 1024 * 1024; // 10MB in bytes

// Each mulitpart part size must be at least 5MB
const multipartFileUploadMinimumSize = 5 * 1024 * 1024; // 5MB in bytes

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  private atlasProfilerImportUrl = `${environment.baseUrl}api/profiler/import`;
  private guidFilename: string;

  constructor(
    private http: HttpClient,
    private store$: Store<fromProfilerStore.State>
  ) {}

  public uploadFile(file: File) {
    this.guidFilename = `${uuidv4()}.csv`;
    this.store$.dispatch(
      fileUploadUpdateFileKey({ fileKey: this.guidFilename })
    );

    if (file.size <= singleFileUploadSize) {
      this.uploadFileInOneGo(file);
    } else {
      this.store$.dispatch(
        initiateMultipartUploadAttempt({
          file: file,
          fileKey: this.guidFilename
        })
      );
    }
  }

  public async uploadFileInOneGo(file: File) {
    try {
      await uploadFileInOneGo(
        file,
        this.http,
        this.atlasProfilerImportUrl,
        this.guidFilename
      );
      this.store$.dispatch(
        metaDataUploadAttempt({ fileKey: this.guidFilename })
      );
    } catch (error) {
      this.handleUploadError(file.name, error);
    }
  }

  public async uploadFileMultiPart(file: File, uploadId: string) {
    try {
      const eTagParts = await uploadFileMultiPart(
        file,
        uploadId,
        this.guidFilename,
        this.http,
        this.atlasProfilerImportUrl
      );
      this.store$.dispatch(
        multipartUploadComplete({
          uploadId,
          filename: this.guidFilename,
          parts: eTagParts
        })
      );
    } catch (err) {
      this.store$.dispatch(
        abortMultipartUpload({
          uploadId,
          filename: this.guidFilename,
          errorMessage: err
        })
      );
    }
  }

  private handleUploadError(filename: string, error: any) {
    this.store$.dispatch(fileUploadError({ fileKey: filename, errors: error }));
  }

  // Example code to get the progress of the file upload, leaving here for reference and future comeback.
  // The callback method example is given below, it calculates the upload progress.
  //
  // Callback method:
  // const progressCallback = (event: ProgressEvent) => {
  //   const progress = (event.loaded / event.total) * 100;
  //   console.log(`Overall Progress: ${progress.toFixed(2)}%`);
  // };
  //
  // Example call to the upload method with callback.
  // const uploadResponse = await this.uploadFileAsBuffer(response.presignedUrl, fileDataChunk, progressCallback);
  //
  // private async uploadFileAsBuffer(url: string, data: ArrayBuffer, progressCallback?: (event: ProgressEvent) => void) {
  //   let headers = new HttpHeaders({ 'Content-Type': 'application/octet-stream' });
  //   let options: { headers: HttpHeaders, observe: 'events', reportProgress?: boolean } = {
  //     headers: headers,
  //     observe: 'events', // Use 'events' to capture progress events
  //   };

  //   if (progressCallback) {
  //     options.reportProgress = true;
  //   }

  //   return await this.http.put(url, data, options)
  //     .pipe(
  //       tap((event:any) => {
  //         if (progressCallback && event.type === HttpEventType.UploadProgress) {
  //           progressCallback(event);
  //         }
  //       }),
  //       last(), // Take the last emitted event (response)
  //     )
  //     .toPromise();
  // }
}
