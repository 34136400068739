import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromLocatorStore from 'src/app/locator-store';
import { LocatorStatusError } from '../models/locator-import-status-error';
import {
  locatorFileImportHeaderValidationSuccess,
  locatorFileImportHeaderValidationFailed
} from 'src/app/locator-store/actions/locator-import-validation.actions';
import {
  readFirstLine,
  validateHeaders
} from 'src/app/shared/utils/import-file-validation-utils';

@Injectable({
  providedIn: 'root'
})
export class LocatorImportValidationService {
  private expectedHeaders = [
    'uid',
    'description',
    'address1',
    'address2',
    'address3',
    'address4',
    'address5',
    'postal',
    'country',
    'latitude',
    'longitude'
  ];

  constructor(private store$: Store<fromLocatorStore.State>) {}

  async validateFileHeaders(file: File) {
    let actualHeaders = await readFirstLine(file);
    let validationErrors = validateHeaders(actualHeaders, this.expectedHeaders);

    validationErrors.length === 0
      ? this.store$.dispatch(locatorFileImportHeaderValidationSuccess({ file }))
      : this.dispatchErrors(validationErrors);
  }

  private dispatchErrors(validationErrors: string[]) {
    let errorMessageList = validationErrors.map((message) => ({
      error: message
    }));
    const LocatorImportStatusError: LocatorStatusError = {
      status: 'FileValidationFailed',
      errors: errorMessageList
    };
    this.store$.dispatch(
      locatorFileImportHeaderValidationFailed({
        errors: JSON.stringify(LocatorImportStatusError)
      })
    );
  }
}
