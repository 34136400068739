import { createAction, props } from '@ngrx/store';
import { SmImportStatus } from 'src/app/spatial-modeller/models/sm-import-status';

export const pollSmImportScenarioDataStatusStarted = createAction(
  '[Sm Import Status Effects] Poll Sm Import Status Started',
  props<{
    scenarioId: number;
  }>()
);

export const pollSmImportScenarioDataStatusInProgress = createAction(
  '[Sm Import Status Effects] Poll Sm Import Status In Progress',
  props<{
    smImportStatus: SmImportStatus;
  }>()
);

export const pollSmImportScenarioDataStatusCompleted = createAction(
  '[Sm Import Status Effects] Poll Sm Import Status Completed',
  props<{
    smImportStatus: SmImportStatus;
  }>()
);

export const pollSmImportScenarioDataStatusFailed = createAction(
  '[Sm Import Status Effects] Poll Sm Import Status Failed',
  props<{
    smImportStatus: SmImportStatus;
  }>()
);

export const openSmImportDialogAfterLoadingApp = createAction(
  '[Sm Import Status Effects] Open Sm Import Dialog After Loading App',
  props<{
    smImportStatus: SmImportStatus;
  }>()
);
