import { createReducer, on } from '@ngrx/store';
import {
  clearScenarioStateOnLogoff,
  createScenarioSucceeded,
  getScenarioSucceeded,
  swapScenarioSucceeded,
  updateScenarioSucceeded
} from '../actions/scenario.actions';
import {
  hasSmScenarioDeltaSupplyPointsLimitBeenExceededResult,
  smClearScenarioImportStatusSuccess,
  smFileImportAttempt,
  smFileUploadError
} from '../actions/sm-import.actions';
import {
  openSmImportDialogAfterLoadingApp,
  pollSmImportScenarioDataStatusCompleted,
  pollSmImportScenarioDataStatusFailed,
  pollSmImportScenarioDataStatusInProgress
} from '../actions/spatial-modeller-import-status.actions';
import { smFileImportHeaderValidationFailed } from '../actions/sm-import-validation.actions';
import { SmImportStatus } from 'src/app/spatial-modeller/models/sm-import-status';

export interface State {
  id: number;
  importUsingGeocoding: boolean;
  importStatus: SmImportStatus | null;
  hasSmScenarioDeltaSupplyPointsLimitBeenExceeded: boolean;
}

export const initialState: State = {
  id: 0,
  importStatus: null,
  importUsingGeocoding: false,
  hasSmScenarioDeltaSupplyPointsLimitBeenExceeded: false
};

export const smImportScenarioReducer = createReducer(
  initialState,
  on(
    getScenarioSucceeded,
    updateScenarioSucceeded,
    createScenarioSucceeded,
    swapScenarioSucceeded,
    (state, { scenario }) => {
      return {
        ...state,
        id: scenario.id,
        importStatus: null,
        importUsingGeocoding: false,
        hasSmScenarioDeltaSupplyPointsLimitBeenExceeded: false
      };
    }
  ),

  on(clearScenarioStateOnLogoff, () => {
    return {
      ...initialState
    };
  }),

  on(smFileImportAttempt, (state, { importUsingGeocoding }) => {
    return {
      ...state,
      importStatus: null,
      importUsingGeocoding,
      hasSmScenarioDeltaSupplyPointsLimitBeenExceeded: false
    };
  }),
  on(
    pollSmImportScenarioDataStatusInProgress,
    pollSmImportScenarioDataStatusCompleted,
    pollSmImportScenarioDataStatusFailed,
    openSmImportDialogAfterLoadingApp,
    (state, { smImportStatus }) => {
      return {
        ...state,
        importStatus: smImportStatus
      };
    }
  ),
  on(
    smFileUploadError,
    smFileImportHeaderValidationFailed,
    (state, { errors }) => {
      const smImportStatus = createSmImportStatusFromClientSide(
        state.id,
        errors
      );
      return {
        ...state,
        importStatus: smImportStatus
      };
    }
  ),
  on(
    hasSmScenarioDeltaSupplyPointsLimitBeenExceededResult,
    (state, { result }) => {
      return {
        ...state,
        hasSmScenarioDeltaSupplyPointsLimitBeenExceeded: result
      };
    }
  ),
  on(smClearScenarioImportStatusSuccess, (state, { scenarioId }) => {
    return {
      ...state,
      id: scenarioId,
      importStatus: null,
      importUsingGeocoding: false,
      hasSmScenarioDeltaSupplyPointsLimitBeenExceeded: false
    };
  })
);

function createSmImportStatusFromClientSide(id: number, errors: string) {
  const smImportStatus: SmImportStatus = {
    scenarioId: id,
    createDt: new Date(Date.now()),
    editedDt: new Date(Date.now()),
    status: 'FileUploadFailed',
    errorMessage: errors,
    importedRecords: 0,
    processedRecords: 0,
    totalRecords: 0,
    fileKey: ''
  };

  return smImportStatus;
}

export const getImportStatus = (state: State) => state && state.importStatus;
export const getImportStatusMessage = (state: State) =>
  state && state.importStatus?.status;
export const getImportUsingGeocoding = (state: State) =>
  state && state.importUsingGeocoding;
export const getJsonImportStatusError = (state: State) =>
  state && state.importStatus?.errorMessage;
export const getHasSmScenarioDeltaSupplyPointsImportLimitBeenExceeded = (
  state: State
) => state && state.hasSmScenarioDeltaSupplyPointsLimitBeenExceeded;
