import { Layer } from '../models/layer';

import { getMapLayerDefaultProperties } from '../helpers/map-layer-helper';
import { MapService } from '../services/map.service';
import { getFromLocalStorage } from '../utils/local-storage-utils';
import { H3HexagonLayer } from '@deck.gl/geo-layers';

export class ThematicH3HexagonLayer extends Layer {
  async getLayer(jwtToken: string, apiBaseUrl: string, mapService: MapService) {
    this.setJwtTokenAndApiBaseUrl(jwtToken, apiBaseUrl);

    let defaultProps = getMapLayerDefaultProperties(
      'ThematicH3Hexagon',
      jwtToken
    );

    var layerProperties =
      this.mergeDefaultPropertiesWitUserDefinedProperties(defaultProps);

    // Highlighting the line when moving over the hexagon does not work like the other layers.
    // identifiers and properties are laid out differnetly
    //layerProperties.onHover = (info: any) => {
    //  styleBoundaryGeometryLines(info, mapService);
    //};
    // properties for highlight the geometry that is hovered border gets thicker and turns black
    // layerProperties.orginalLineColor = layerProperties.getLineColor;
    //layerProperties.highlightColor = [129, 179, 255, 0]; // transparent

    layerProperties.onDataLoad = (featureCollection: any, layer: any) => {
      if (getFromLocalStorage('selected-feature') === 'Modeller') {
        mapService.centreAndZoomExtentMap(featureCollection);
      }
    };

    return new H3HexagonLayer(layerProperties);
  }
}
