import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  filter,
  map,
  switchMap,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import * as fromLocatorStore from 'src/app/locator-store';
import * as fromUiStore from 'src/app/core/store';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { MapService } from 'src/app/shared/atlas-mapping/services/map.service';
import {
  allShapesFromLocationShapeListClicked,
  allShapesFromLocationShapeListCompleted,
  shapeFromLocationShapeListClicked,
  generateLocatorLoFiReportAttempt,
  generateLocatorDataReportAttempt,
  createLocatorShapesAttempt,
  createLocatorShapesSucceeded,
  createLocatorShapesErrorOccurred,
  deleteLibraryLocationShapeAttempt,
  deleteLibraryLocationShapeSucceeded,
  libraryLocationShapeErrorOccurred,
  addingPolygonConfirmAttempt,
  addingPolygonConfirmCancelled,
  addingPolygonConfirmComplete,
  updateLocatorShapeAttempt,
  updateLocatorShapeSucceeded,
  updateLocatorShapesErrorOccurred,
  updateLocatorPolygonDescriptionAttempt,
  editLocatorPolygonShapeAttempt,
  editLocatorPolygonShapeConfirm,
  editLocatorPolygonShapeConfirmCancelled,
  selectedLocationHasSingleShape
} from '../actions/locator-shape.actions';
import {
  showSelectedLocatorDataShapeAfterAddEdit,
  showShapesFromLibraryDataOnMap,
  showLocatorLayersEffectedByShapeSelectionLayers
} from 'src/app/locator/helpers/locator-shape.helper';
import { of } from 'rxjs';
import { LocatorService } from 'src/app/locator/services/locator.service';
import { LocatorShape } from 'src/app/locator/models/locator-shape';
import { ModifyMode } from '@deck.gl-community/editable-layers';
import { AddEditPolygonConfirmationDialogComponent } from 'src/app/core/components/properties-panel/locator-properties/add-edit-polygon-confirmation-dialog/add-edit-polygon-confirmation-dialog.component';
import { LocatorShapeTypes } from 'src/app/locator/types/locator-shape.types';
import { DialogMode } from 'src/app/shared/atlas-dialog/enums/dialog-mode.enum';
import { DialogWidth } from 'src/app/shared/atlas-dialog/enums/dialog-width.enum';
import {
  locatorDataShapesLayerIdentifier,
  locatorEditPolygonIdentifier
} from 'src/app/shared/atlas-mapping/layers/layer.constants';
import { DialogService } from 'src/app/core/services/dialog.service';
import {
  addingEditingPolygonCancelled,
  addingEditingPolygonComplete,
  addingPolygonCancelled,
  generatingCatchmentsAttempt,
  generatingCatchmentsComplete
} from 'src/app/core/store/actions/locator-ui.actions';
import {
  hideLocatorEditPolygonSystemLayer,
  hideLocatorLayersEffectedBySelectionChange
} from 'src/app/shared/atlas-mapping/helpers/system-layers-helper';
import { getLocatorLibraryId } from 'src/app/locator-store';
import { getSelectedShapes } from 'src/app/locator-store';
import { DataGridService } from 'src/app/core/services/datagrid.service';
import { generateTempLocatorLibraryDataPointErrorOccurred } from '../actions/locator-library.actions';

@Injectable()
export class LocatorShapeEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<fromLocatorStore.State>,
    private mapService: MapService,
    private locatorService: LocatorService,
    private dialogService: DialogService,
    private dataGridService: DataGridService
  ) {}

  generateLocatorLoFiReportAndShapeEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(shapeFromLocationShapeListClicked, selectedLocationHasSingleShape),
      withLatestFrom(
        this.store$.select(fromLocatorStore.getSelectedLocation),
        this.store$.select(fromLocatorStore.getLocatorMapLayerTemplates)
      ),
      map(([{ id }, selectedLocationData, locatorTemplates]) => {
        var shapeUpdatabales = {
          shapeId: id
        };

        showLocatorLayersEffectedByShapeSelectionLayers(
          selectedLocationData!.libraryId,
          selectedLocationData!.id,
          shapeUpdatabales,
          locatorTemplates,
          this.mapService
        );
        return generateLocatorLoFiReportAttempt({
          libraryId: selectedLocationData!.libraryId,
          locationDataId: selectedLocationData!.id,
          shapeId: id
        });
      })
    )
  );

  showLocatorNewLocatorShapeEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createLocatorShapesSucceeded),
        withLatestFrom(
          this.store$.select(fromLocatorStore.getSelectedLocation),
          this.store$.select(fromLocatorStore.getLocatorMapLayerTemplates)
        ),
        tap(
          ([
            { newShapes, multipleCatchments },
            selectedLocationData,
            locatorTemplates
          ]) => {
            if (newShapes.length === 1 && !multipleCatchments) {
              var shapeUpdatables = {
                shapeId: newShapes[0].id
              };
              showLocatorLayersEffectedByShapeSelectionLayers(
                selectedLocationData!.libraryId,
                selectedLocationData!.id,
                shapeUpdatables,
                locatorTemplates,
                this.mapService
              );
            }
          }
        )
      ),
    { dispatch: false }
  );

  showLocatorUpdatedLocatorShapeEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateLocatorShapeSucceeded),
        withLatestFrom(
          this.store$.select(fromLocatorStore.getSelectedLocation),
          this.store$.select(fromLocatorStore.getLocatorMapLayerTemplates)
        ),
        tap(([{ updatedShape }, selectedLocationData, locatorTemplates]) => {
          var shapeUpdatables = {
            shapeId: updatedShape.id
          };
          showLocatorLayersEffectedByShapeSelectionLayers(
            selectedLocationData!.libraryId,
            selectedLocationData!.id,
            shapeUpdatables,
            locatorTemplates,
            this.mapService
          );
        })
      ),
    { dispatch: false }
  );

  generateLocatorLoFiReportForLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(allShapesFromLocationShapeListClicked),
      withLatestFrom(this.store$.select(fromLocatorStore.getSelectedLocation)),
      switchMap(([_, selectedLocationData]) => {
        return of(
          generateLocatorLoFiReportAttempt({
            libraryId: selectedLocationData!.libraryId,
            locationDataId: selectedLocationData!.id,
            shapeId: 0
          })
        );
      })
    )
  );

  generateSelectedShapePowerBiReportData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(shapeFromLocationShapeListClicked, selectedLocationHasSingleShape),
      withLatestFrom(this.store$.select(fromLocatorStore.getSelectedLocation)),
      switchMap(([{ id }, selectedLocationData]) => {
        return of(
          generateLocatorDataReportAttempt({
            libraryId: selectedLocationData!.libraryId,
            locationDataId: selectedLocationData!.id,
            shapeIds: [id]
          })
        );
      })
    )
  );

  getAllShapesFromState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(allShapesFromLocationShapeListClicked),
      filter(({ allSelected }) => allSelected),
      withLatestFrom(this.store$.select(fromLocatorStore.getSelectedLocation)),
      switchMap(([_, selectedLocationData]) => {
        if (selectedLocationData!.shapes.length > 1) {
          showShapesFromLibraryDataOnMap(
            selectedLocationData!.libraryId,
            selectedLocationData!.id,
            this.mapService
          );
          return of(allShapesFromLocationShapeListCompleted());
        }

        return of(
          selectedLocationHasSingleShape(selectedLocationData!.shapes[0])
        );
      })
    )
  );

  createShapesAttempt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createLocatorShapesAttempt),
      withLatestFrom(
        this.store$.select(getLocatorLibraryId),
        this.store$.select(fromLocatorStore.getSelectedLocation),
        this.store$.select(fromLocatorStore.getBatchOperationLocationIds)
      ),
      switchMap(
        ([
          locatorAddShapesRequest,
          libraryId,
          selectedLocationData,
          batchLocationIds
        ]) => {
          let locationIds =
            batchLocationIds.length > 1
              ? batchLocationIds
              : [selectedLocationData!.id];

          return this.locatorService
            .createLocatorShapes(
              libraryId,
              locationIds,
              locatorAddShapesRequest.shapeType,
              locatorAddShapesRequest.shapeRanges,
              locatorAddShapesRequest.freeFormGeoJson,
              locatorAddShapesRequest.freeFormName,
              locatorAddShapesRequest.driveCatchmentSpeed
            )
            .pipe(
              map((newShapes: LocatorShape[]) => {
                return createLocatorShapesSucceeded({
                  newShapes,
                  multipleCatchments: batchLocationIds.length > 1
                });
              }),
              catchError((error) =>
                of(
                  createLocatorShapesErrorOccurred({
                    errorOn:
                      error === 'Endpoint request timed out' &&
                      locatorAddShapesRequest.shapeType ===
                        LocatorShapeTypes.PublicTransport
                        ? 'Catchment failed'
                        : '',
                    error:
                      error === 'Endpoint request timed out' &&
                      locatorAddShapesRequest.shapeType ===
                        LocatorShapeTypes.PublicTransport
                        ? 'The complexity of the requested public transport catchment led to a timeout. Please try a smaller catchment.'
                        : error
                  })
                )
              )
            );
        }
      )
    )
  );

  createLocatorShapesSucceded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createLocatorShapesSucceeded),
      filter(({ newShapes }) => newShapes.length === 1),
      withLatestFrom(this.store$.select(getLocatorLibraryId)),
      switchMap(([{ newShapes }, libraryId]) => {
        return [
          generateLocatorLoFiReportAttempt({
            libraryId,
            locationDataId: newShapes[0].libraryDataId,
            shapeId: newShapes[0].id
          }),
          addingEditingPolygonComplete()
        ];
      })
    )
  );

  deleteLibraryLocationShape$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteLibraryLocationShapeAttempt),
      withLatestFrom(this.store$.select(fromLocatorStore.getSelectedLocation)),
      switchMap(([{ shapeId }, selectedLocationData]) => {
        return this.locatorService
          .deleteLocatorLocationShape(
            selectedLocationData!.libraryId,
            selectedLocationData!.id,
            shapeId
          )
          .pipe(
            tap(() => {
              this.dataGridService.refreshGrid();
            }),
            map(() =>
              deleteLibraryLocationShapeSucceeded({
                LibraryDataId: selectedLocationData!.id,
                shapeId: shapeId
              })
            ),
            catchError((error) =>
              of(
                libraryLocationShapeErrorOccurred({
                  errorOn: 'Error Library location shape',
                  error: `Delete Library location shape has failed.`
                })
              )
            )
          );
      })
    )
  );

  addPolygonComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createLocatorShapesSucceeded),
      withLatestFrom(this.store$.select(getLocatorLibraryId)),
      filter(([{ multipleCatchments }, __]) => !multipleCatchments),
      map(([{ newShapes }, libraryId]) => {
        showSelectedLocatorDataShapeAfterAddEdit(
          this.mapService,
          libraryId,
          newShapes[0]
        );
        return addingEditingPolygonComplete();
      })
    )
  );

  addPolygonConfirmAttempt$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(addingPolygonConfirmAttempt),
        withLatestFrom(
          this.store$.select(fromLocatorStore.getSelectedLocation)
        ),
        map(([payload, selectedLocation]) => {
          this.dialogService.show(AddEditPolygonConfirmationDialogComponent, {
            width: DialogWidth.Small,
            panelClass: 'dialog-95vw-width',
            data: {
              entityName: 'Polygon',
              mode: DialogMode.Add,
              headerPrefix: 'Add a',
              affirmativeButtonText: 'Confirm',
              location: payload.location,
              geoJson: payload.geoJson,
              selectedLocation: selectedLocation
            },
            disableClose: true
          });
        })
      ),
    { dispatch: false }
  );

  addingPolygonConfirmComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addingPolygonConfirmComplete),
      map((payload) => {
        return createLocatorShapesAttempt({
          shapeType: LocatorShapeTypes.FreeForm,
          shapeRanges: [],
          freeFormGeoJson: payload.geoJson,
          freeFormName: payload.description,
          driveCatchmentSpeed: null,
          multipleCatchments: false
        });
      })
    )
  );

  addingPolygonConfirmCancelled$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          addingPolygonConfirmCancelled,
          editLocatorPolygonShapeConfirmCancelled
        ),
        map(() => {
          this.updatePolygonLayerToEditMode();
        })
      ),
    { dispatch: false }
  );

  updateShapeAttempt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateLocatorShapeAttempt),
      withLatestFrom(
        this.store$.select(fromLocatorStore.getSelectedLocation),
        this.store$.select(fromLocatorStore.getSelectedShapes)
      ),
      switchMap(
        ([locatorUpdateShapeRequest, selectedLocationData, selectedShapes]) =>
          this.locatorService
            .updateLocatorShapes(
              selectedLocationData!.libraryId,
              selectedLocationData!.id,
              selectedShapes![0].id,
              locatorUpdateShapeRequest.shapeRange,
              locatorUpdateShapeRequest.freeFormGeoJson,
              locatorUpdateShapeRequest.freeFormName === null
                ? selectedShapes![0].name
                : locatorUpdateShapeRequest.freeFormName,
              locatorUpdateShapeRequest.driveCathmentSpeed
            )
            .pipe(
              map((newShape: LocatorShape) => {
                return updateLocatorShapeSucceeded({ updatedShape: newShape });
              }),
              catchError((error) =>
                of(
                  updateLocatorShapesErrorOccurred({
                    errorOn: '',
                    error
                  })
                )
              )
            )
      )
    )
  );

  updateLocatorShapesSucceded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateLocatorShapeSucceeded),
      withLatestFrom(
        this.store$.select(getLocatorLibraryId),
        this.store$.select(getSelectedShapes)
      ),
      switchMap(([{ updatedShape }, libraryId, selectedShapes]) => {
        if (selectedShapes?.map((s) => s.id).includes(updatedShape.id)) {
          showSelectedLocatorDataShapeAfterAddEdit(
            this.mapService,
            libraryId,
            updatedShape
          );
        }
        return [
          generateLocatorLoFiReportAttempt({
            libraryId,
            locationDataId: updatedShape.libraryDataId,
            shapeId: updatedShape.id
          }),
          addingEditingPolygonComplete()
        ];
      })
    )
  );

  generateLocatorReportDataAfterUpdateShapeSucceded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateLocatorShapeSucceeded),
      withLatestFrom(this.store$.select(getLocatorLibraryId)),
      switchMap(([{ updatedShape }, libraryId]) => {
        return of(
          generateLocatorDataReportAttempt({
            libraryId,
            locationDataId: updatedShape.libraryDataId,
            shapeIds: [updatedShape.id]
          })
        );
      })
    )
  );

  cancelAddingEditingPolygon$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(addingEditingPolygonCancelled, addingPolygonCancelled),
        withLatestFrom(
          this.store$.select(fromLocatorStore.getEditingExistingPolygonShape),
          this.store$.select(getLocatorLibraryId),
          this.store$.select(getSelectedShapes)
        ),
        map(([_, editingExistingShape, libraryId, selectedShapes]) => {
          editingExistingShape
            ? showSelectedLocatorDataShapeAfterAddEdit(
                this.mapService,
                libraryId,
                selectedShapes![0]
              )
            : hideLocatorEditPolygonSystemLayer(this.mapService);
        })
      ),
    { dispatch: false }
  );

  updatePolygonDescriptionAttempt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateLocatorPolygonDescriptionAttempt),
      withLatestFrom(
        this.store$.select(fromLocatorStore.getSelectedLocation),
        this.store$.select(fromLocatorStore.getSelectedShapes)
      ),
      switchMap(
        ([locatorUpdateShapeRequest, selectedLocationData, selectedShapes]) =>
          this.locatorService
            .updatePolygonDescription(
              selectedLocationData!.libraryId,
              selectedLocationData!.id,
              selectedShapes![0].id,
              locatorUpdateShapeRequest.name
            )
            .pipe(
              map((newShape: LocatorShape) => {
                return updateLocatorShapeSucceeded({ updatedShape: newShape });
              }),
              catchError((error) =>
                of(
                  updateLocatorShapesErrorOccurred({
                    errorOn: '',
                    error
                  })
                )
              )
            )
      )
    )
  );

  editPolygonShapeAttempt$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(editLocatorPolygonShapeAttempt),
        map(() => this.updateShapeToPolygonEditMode())
      ),
    { dispatch: false }
  );

  editPolygonShapeConfirm$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(editLocatorPolygonShapeConfirm),
        map((payload) => {
          this.dialogService.show(AddEditPolygonConfirmationDialogComponent, {
            width: DialogWidth.Small,
            panelClass: 'dialog-95vw-width',
            data: {
              entityName: 'Shape',
              mode: DialogMode.Edit,
              headerPrefix: 'Confirm Polygon',
              affirmativeButtonText: 'Confirm',
              geoJson: payload.geoJson,
              editingExistingPolygonShape: true
            },
            disableClose: true
          });
        })
      ),
    { dispatch: false }
  );

  generatingCatchmentsInProgress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createLocatorShapesAttempt),
      filter(({ multipleCatchments }) => multipleCatchments),
      map(() => generatingCatchmentsAttempt())
    )
  );

  generatingCatchmentsComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createLocatorShapesSucceeded),
      filter(({ multipleCatchments }) => multipleCatchments),
      map(() => generatingCatchmentsComplete())
    )
  );

  generateTempLocatorLibraryDataPointErrorOccurred$ = createEffect(() =>
    this.actions$.pipe(
      ofType(generateTempLocatorLibraryDataPointErrorOccurred),
      withLatestFrom(
        this.store$.select(fromUiStore.getAddingNewPolygon),
        this.store$.select(fromUiStore.getAddingEditingPolygon)
      ),
      filter(
        ([_, addingNewPolygon, addingEditingPolygon]) =>
          addingNewPolygon || addingEditingPolygon
      ),
      map(() => addingPolygonCancelled())
    )
  );

  private updatePolygonLayerToEditMode() {
    this.mapService.updateLayer(locatorEditPolygonIdentifier, {
      mode: ModifyMode
    });
  }

  private updateShapeToPolygonEditMode() {
    var layer = this.mapService.getLayer(locatorDataShapesLayerIdentifier);
    this.mapService.updateLayer(locatorEditPolygonIdentifier, {
      data: layer.props.data,
      mode: ModifyMode,
      visible: true,
      selectedFeatureIndexes: [0]
    });
    hideLocatorLayersEffectedBySelectionChange(this.mapService);
  }
}
