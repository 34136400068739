import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { loadAppFeaturesSucceeded } from 'src/app/core/store/actions/app-feature-ui.actions';
import { ProfilerService } from 'src/app/profiler/services/profiler.service';
import { AppFeatureStateService } from 'src/app/shared/services/app-feature-state.service';
import {
  getProfilerLibrarySucceded,
  getProfilerLoFiReportAttempt,
  getProfilerLoFiReportErrorOccurred,
  getProfilerLoFiReportSucceded,
  getProfilerPowerBiReportingInfoErrorOccurred,
  getProfilerPowerBiReportingInfoSucceded
} from '../actions/profiler-library.actions';
import { of } from 'rxjs';
import { ProfilerPowerBiReportingInfo } from 'src/app/profiler/model/profiler-power-bi-reporting-info';
import { ProfilerLofiQueryResult } from 'src/app/profiler/model/profiler-lofi-report';

@Injectable()
export class ProfilerReportingEffects {
  constructor(
    private actions$: Actions,
    private appFeatureStateService: AppFeatureStateService,
    private profilerService: ProfilerService
  ) {}

  getProfilerPowerBiReportingInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAppFeaturesSucceeded),
      this.appFeatureStateService.allowWhenProfilerFeatureAllowed(),
      switchMap(() =>
        this.profilerService.getProfilerPowerBiReportCollection().pipe(
          map((pbiReports: ProfilerPowerBiReportingInfo[]) => {
            return getProfilerPowerBiReportingInfoSucceded({ pbiReports });
          }),
          catchError((error) =>
            of(
              getProfilerPowerBiReportingInfoErrorOccurred({
                errorOn: '',
                error
              })
            )
          )
        )
      )
    )
  );

  getLofiReportForLibraryAttempt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getProfilerLoFiReportAttempt),
      switchMap(({ libraryId }) => {
        return this.profilerService.getLofiReportForLibrary(libraryId).pipe(
          map((report: ProfilerLofiQueryResult[]) => {
            return getProfilerLoFiReportSucceded({
              report: report
            });
          }),
          catchError((error) =>
            of(
              getProfilerLoFiReportErrorOccurred({
                errorOn: 'Low fidelity report:',
                error
              })
            )
          )
        );
      })
    )
  );
  //commenting this code as we have to generate the report instead of getReport based on classificationId

  // getLofiReportOnlibraryLoad$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(getProfilerLibrarySucceded),
  //     map(({ library }) => {
  //       return getProfilerLoFiReportAttempt({
  //         libraryId: library.id
  //       });
  //     })
  //   )
  // );
}
