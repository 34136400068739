import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { smFileImportHeaderValidationAttempt } from '../actions/sm-import-validation.actions';
import * as fromSmStore from 'src/app/spatial-modeller-store';
import { Store } from '@ngrx/store';
import {
  hasSmScenarioDeltaSupplyPointsLimitBeenExceededAttempt,
  hasSmScenarioDeltaSupplyPointsLimitBeenExceededResult,
  smAbortMultipartUpload,
  smClearScenarioImportStatusAttempt,
  smClearScenarioImportStatusSuccess,
  smFileImportAttempt,
  smFileUploadCompleteStatusSuccess,
  smFileUploadError,
  smFileUploadUpdateFileKey,
  smInitiateMultipartUploadAttempt,
  smInitiateMultipartUploadSuccess,
  smMetaDataUploadAttempt,
  smMetaDataUploadSuccess,
  smMultipartUploadComplete
} from '../actions/sm-import.actions';
import { SmImportService } from 'src/app/spatial-modeller/services/sm-import.service';
import { SmImportValidationService } from 'src/app/spatial-modeller/services/sm-import-validation.service';
import { SmUploadService } from 'src/app/spatial-modeller/services/sm-upload.service';
import { of } from 'rxjs';
import {
  smFileImportHeaderValidationFailed,
  smFileImportHeaderValidationSuccess
} from '../actions/sm-import-validation.actions';
import { pollSmImportScenarioDataStatusCompleted } from '../actions/spatial-modeller-import-status.actions';
import { GetDynamicImportTemplateHeaders } from 'src/app/spatial-modeller/helpers/import-template.helper';
import { PinDropAndSelectionService } from 'src/app/shared/atlas-mapping/services/pin-drop-and-selection-service';

@Injectable()
export class SmImportEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<fromSmStore.State>,
    private smImportService: SmImportService,
    private smImportValidationService: SmImportValidationService,
    private smUploadService: SmUploadService,
    private pinDropAndSelectionService: PinDropAndSelectionService
  ) {}

  startImportFileUpload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(smFileImportAttempt),
      withLatestFrom(this.store$.select(fromSmStore.getScenarioId)),
      switchMap(([{ file }, scenarioId]) =>
        this.smImportService
          .fileUploadStartedStatus(scenarioId)
          .pipe(map(() => smFileImportHeaderValidationAttempt({ file })))
      )
    )
  );

  fileUploadHeaderValidationAttempt$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(smFileImportHeaderValidationAttempt),
        map((action) =>
          this.smImportValidationService.validateFileHeaders(action.file)
        )
      ),
    { dispatch: false }
  );

  fileUploadEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(smFileImportHeaderValidationSuccess),
        withLatestFrom(
          this.store$.select(
            fromSmStore.getSpatialModellerSupplyPointAttributes
          )
        ),
        map(([action, supplyPointAttributeList]) => {
          const fileHeaders = GetDynamicImportTemplateHeaders(
            supplyPointAttributeList ?? []
          );
          this.smUploadService.uploadFile(action.file, fileHeaders);
        })
      ),
    { dispatch: false }
  );

  fileUploadUpdateFileKeyEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(smFileUploadUpdateFileKey),
        withLatestFrom(this.store$.select(fromSmStore.getScenarioId)),
        switchMap(([action, scenarioId]) =>
          this.smImportService.fileUploadUpdateFileKey(
            scenarioId,
            action.fileKey
          )
        )
      ),
    { dispatch: false }
  );

  metadataUploadEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(smMetaDataUploadAttempt),
      withLatestFrom(
        this.store$.select(fromSmStore.getScenarioId),
        this.store$.select(fromSmStore.getSelectedSmImportUsingGeocoding)
      ),
      switchMap(([file, scenarioId, importUsingGeocoding]) =>
        this.smImportService
          .uploadMetaDataConfig(
            file.fileKey,
            scenarioId,
            file.fileHeaders,
            importUsingGeocoding
          )
          .pipe(
            map(() => smMetaDataUploadSuccess({ fileKey: file.fileKey })),
            catchError((error) =>
              of(smFileUploadError({ fileKey: file.fileKey, errors: error }))
            )
          )
      )
    )
  );

  fileUploadCompleteStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(smMetaDataUploadSuccess),
      withLatestFrom(this.store$.select(fromSmStore.getScenarioId)),
      switchMap(([file, scenarioId]) =>
        this.smImportService.fileUploadCompleteStatus(scenarioId).pipe(
          map(() => smFileUploadCompleteStatusSuccess()),
          catchError((error) =>
            of(smFileUploadError({ fileKey: file.fileKey, errors: error }))
          )
        )
      )
    )
  );

  fileUploadError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(smFileUploadError, smFileImportHeaderValidationFailed),
        withLatestFrom(this.store$.select(fromSmStore.getScenarioId)),
        switchMap(([error, scenarioId]) =>
          this.smImportService.fileUploadFailedStatus(scenarioId, error.errors)
        )
      ),
    { dispatch: false }
  );

  initiateMultipartUploadAttempt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(smInitiateMultipartUploadAttempt),
      switchMap((action) =>
        this.smImportService.initiateMultipartUpload(action.fileKey).pipe(
          map((result) =>
            smInitiateMultipartUploadSuccess({
              uploadId: result.uploadId,
              file: action.file
            })
          )
        )
      )
    )
  );

  initiateMultipartUploadSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(smInitiateMultipartUploadSuccess),
        switchMap((action) =>
          this.smUploadService.uploadFileMultiPart(action.file, action.uploadId)
        )
      ),
    { dispatch: false }
  );

  multipartUploadComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(smMultipartUploadComplete),
      withLatestFrom(
        this.store$.select(fromSmStore.getScenarioId),
        this.store$.select(fromSmStore.getSpatialModellerSupplyPointAttributes)
      ),
      switchMap(([action, scenarioId, supplyPointAttributeList]) =>
        this.smImportService
          .completeMultipartUpload(
            scenarioId,
            action.filename,
            action.uploadId,
            action.parts
          )
          .pipe(
            map(() => {
              const fileHeaders = GetDynamicImportTemplateHeaders(
                supplyPointAttributeList ?? []
              );
              return smMetaDataUploadAttempt({
                fileKey: action.filename,
                fileHeaders
              });
            })
          )
      )
    )
  );

  abortMultipartUpload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(smAbortMultipartUpload),
      switchMap((action) =>
        this.smImportService
          .cancelMultipartUpload(action.filename, action.uploadId)
          .pipe(
            map(() =>
              smFileUploadError({
                fileKey: action.filename,
                errors: action.errorMessage
              })
            )
          )
      )
    )
  );

  refreshLocationsGridAfterImportLocation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(pollSmImportScenarioDataStatusCompleted),
        map(({ smImportStatus }) => {
          this.pinDropAndSelectionService.refreshBaseAndDeltaLayersScenarioData(
            smImportStatus.scenarioId
          );
        })
      ),
    { dispatch: false }
  );

  hasSpatialModellerDeltaLimitBeenExceededAttempt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(hasSmScenarioDeltaSupplyPointsLimitBeenExceededAttempt),
      switchMap(({ scenarioId }) =>
        this.smImportService
          .hasSpatialModellerDeltaLimitBeenExceeded(scenarioId)
          .pipe(
            map((hasExcceeded) =>
              hasSmScenarioDeltaSupplyPointsLimitBeenExceededResult({
                result: hasExcceeded.result
              })
            )
          )
      )
    )
  );

  clearScenarioImportStatusAttempt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(smClearScenarioImportStatusAttempt),
      switchMap(({ scenarioId }) =>
        this.smImportService
          .clearScenarioImportStatus(scenarioId)
          .pipe(map(() => smClearScenarioImportStatusSuccess({ scenarioId })))
      )
    )
  );
}
