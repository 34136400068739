import { createSelector } from '@ngrx/store';
import * as fromImportScenarioData from '../reducers/sm-import-scenario-data.reducer';
import * as fromFeature from '../reducers';
import {
  buildSmImportProgressSteps,
  SmImportStatusTypes
} from 'src/app/spatial-modeller/helpers/spatial-modeller-status-helper';
import { getImportUsingGeocoding } from '../reducers/sm-import-scenario-data.reducer';
import { SmImportStatusProgressStep } from 'src/app/spatial-modeller/models/sm-status-progress-step';

export const getSmImportDataStatus = createSelector(
  fromFeature.getSpatialModellerImportScenarioDataState,
  fromImportScenarioData.getImportStatus
);

export const getSmImportDataStatusMessage = createSelector(
  fromFeature.getSpatialModellerImportScenarioDataState,
  fromImportScenarioData.getImportStatusMessage
);

export const getSmImportDataProgressSteps = createSelector(
  getSmImportDataStatusMessage,
  generateProgressSteps
);

export const getSelectedSmImportUsingGeocoding = createSelector(
  fromFeature.getSpatialModellerImportScenarioDataState,
  getImportUsingGeocoding
);

export const getHasSmScenarioDeltaImportLimitBeenExceeded = createSelector(
  fromFeature.getSpatialModellerImportScenarioDataState,
  fromImportScenarioData.getHasSmScenarioDeltaSupplyPointsImportLimitBeenExceeded
);

function generateProgressSteps(
  statusMessage: SmImportStatusTypes | undefined
): SmImportStatusProgressStep[] {
  if (statusMessage) {
    return buildSmImportProgressSteps(
      statusMessage as SmImportStatusTypes
    ) as SmImportStatusProgressStep[];
  }

  return [];
}
