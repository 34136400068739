import { createAction, props } from '@ngrx/store';

export const smFileImportHeaderValidationAttempt = createAction(
  '[Sm Import Service] Sm Import Header Validation Attempt',
  props<{
    file: File;
  }>()
);

export const smFileImportHeaderValidationSuccess = createAction(
  '[Sm Import Service] Sm Import Header Validation Success',
  props<{
    file: File;
  }>()
);

export const smFileImportHeaderValidationFailed = createAction(
  '[Sm Import Service] Sm Import Header Validation Failed',
  props<{
    errors: string;
  }>()
);
