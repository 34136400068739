import { SmImportStatusProgressStep } from '../models/sm-status-progress-step';

export type SmImportStatusTypes =
  | 'SmImportDialogOpened'
  | 'FileUploadStarted'
  | 'FileUploadCompleted'
  | 'FileUploadFailed'
  | 'FileValidationStarted'
  | 'FileValidationFailed'
  | 'FileValidationCompleted'
  | 'ProcessingFileDataStarted'
  | 'ProcessingFileDataCompleted'
  | 'ProcessingFileDataFailed';

export type SmProgressStepsState =
  | 'NotStarted'
  | 'Processing'
  | 'Completed'
  | 'Error';

export type SmImportUiDialogSteps = 'select_csv_file' | 'processing_data';

export function buildSmImportProgressSteps(
  currentStatus: SmImportStatusTypes
): SmImportStatusProgressStep[] {
  const progressStates = {
    SmImportDialogOpened: () => [
      {
        name: 'select_csv_file',
        state: 'NotStarted'
      } as SmImportStatusProgressStep,
      {
        name: 'processing_data',
        state: 'NotStarted'
      } as SmImportStatusProgressStep
    ],
    FileUploadStarted: () => [
      {
        name: 'select_csv_file',
        state: 'Processing'
      } as SmImportStatusProgressStep,
      {
        name: 'processing_data',
        state: 'NotStarted'
      } as SmImportStatusProgressStep
    ],
    FileUploadCompleted: () => [
      {
        name: 'select_csv_file',
        state: 'Completed'
      } as SmImportStatusProgressStep,
      {
        name: 'processing_data',
        state: 'Processing'
      } as SmImportStatusProgressStep
    ],
    FileUploadFailed: () => [
      { name: 'select_csv_file', state: 'Error' } as SmImportStatusProgressStep,
      {
        name: 'processing_data',
        state: 'NotStarted'
      } as SmImportStatusProgressStep
    ],
    FileValidationStarted: () => [
      {
        name: 'select_csv_file',
        state: 'Completed'
      } as SmImportStatusProgressStep,
      {
        name: 'processing_data',
        state: 'Processing'
      } as SmImportStatusProgressStep
    ],
    FileValidationFailed: () => [
      {
        name: 'select_csv_file',
        state: 'Completed'
      } as SmImportStatusProgressStep,
      { name: 'processing_data', state: 'Error' } as SmImportStatusProgressStep
    ],
    FileValidationCompleted: () => [
      {
        name: 'select_csv_file',
        state: 'Completed'
      } as SmImportStatusProgressStep,
      {
        name: 'processing_data',
        state: 'Processing'
      } as SmImportStatusProgressStep
    ],
    ProcessingFileDataStarted: () => [
      {
        name: 'select_csv_file',
        state: 'Completed'
      } as SmImportStatusProgressStep,
      {
        name: 'processing_data',
        state: 'Processing'
      } as SmImportStatusProgressStep
    ],
    ProcessingFileDataFailed: () => [
      {
        name: 'select_csv_file',
        state: 'Completed'
      } as SmImportStatusProgressStep,
      { name: 'processing_data', state: 'Error' } as SmImportStatusProgressStep
    ],
    ProcessingFileDataCompleted: () => [
      {
        name: 'select_csv_file',
        state: 'Completed'
      } as SmImportStatusProgressStep,
      {
        name: 'processing_data',
        state: 'Completed'
      } as SmImportStatusProgressStep
    ]
  };

  return progressStates[currentStatus]();
}
