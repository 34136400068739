<div
  class="progress-step-container"
  [ngClass]="{
    'not-Started ': progressStep.state === 'NotStarted',
    'processing-background': progressStep.state === 'Processing',
    'error-background': progressStep.state === 'Error'
  }"
>
  <div class="progress-step-name">
    <div class="icon">
      <span
        *ngIf="progressStep.name === sendingDataToTheCloud"
        class="material-symbols-outlined"
        >cloud_upload</span
      >
      <span
        *ngIf="progressStep.name === preparingData"
        class="material-symbols-outlined"
        >Cycle</span
      >
      <span
        *ngIf="progressStep.name === profilingData"
        class="material-symbols-outlined"
        >group</span
      >
      <span
        *ngIf="progressStep.name === savingProfiledData"
        class="material-symbols-outlined"
        >save</span
      >
    </div>
    <div class="status-name" *ngIf="progressStep.state != 'Error'">
      {{ progressStep.name }}
    </div>
    <div *ngIf="progressStep.state === 'Error'" class="error-text status-name">
      ({{ progressStep.name }}) Error-see below for details
    </div>
  </div>
  <div class="status-name">
    <div *ngIf="progressStep.state === 'NotStarted'" class="not-Started">
      <mat-icon>done</mat-icon>
    </div>
    <div *ngIf="progressStep.state === 'Processing'">
      <mat-spinner diameter="25" strokeWidth="2"></mat-spinner>
    </div>
    <div *ngIf="progressStep.state === 'Completed'" class="done">
      <mat-icon>done</mat-icon>
    </div>
    <div *ngIf="progressStep.state === 'Error'" class="error">
      <mat-icon>close</mat-icon>
    </div>
  </div>
</div>
