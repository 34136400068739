import { Component, Input } from '@angular/core';
import { DialogWidth } from 'src/app/shared/atlas-dialog/enums/dialog-width.enum';
import { DialogMode } from 'src/app/shared/atlas-dialog/enums/dialog-mode.enum';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { getScenarioId } from 'src/app/spatial-modeller-store';
import * as fromAppFeatureUIStore from 'src/app/core/store';
import * as fromScenarioStore from 'src/app/spatial-modeller-store';
import { DialogService } from 'src/app/core/services/dialog.service';
import { SmImportScenarioDataDialogComponent } from '../sm-import-scenario-data-dialog/sm-import-scenario-data-dialog.component';

@Component({
  selector: 'atlas-sm-import-scenario-data',
  templateUrl: './sm-import-scenario-data.component.html',
  styleUrl: './sm-import-scenario-data.component.less'
})
export class SmImportScenarioDataComponent {
  @Input() disabled: boolean | null = false;
  private subscription = new Subscription();
  private scenarioId$ = this.store$.select(getScenarioId);
  private scenarioId: number;
  private tenantImportUsingGeocoding: boolean;

  private importUsingGeocoding$ = this.featureUIStore$.select(
    fromAppFeatureUIStore.getNetworkPlanningImportUsingGeocoding
  );

  isNpReadonlySystem$ = this.featureUIStore$.select(
    fromAppFeatureUIStore.getNetworkPlanningReadonlySystem
  );

  constructor(
    private featureUIStore$: Store<fromAppFeatureUIStore.State>,
    public dialogService: DialogService,
    private store$: Store<fromScenarioStore.State>
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.scenarioId$.subscribe((id) => {
        this.scenarioId = id;
      })
    );

    this.subscription.add(
      this.importUsingGeocoding$.subscribe((useGeocoding) => {
        this.tenantImportUsingGeocoding = useGeocoding;
      })
    );
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onOpenNpImportDataDialog() {
    this.dialogService.show(SmImportScenarioDataDialogComponent, {
      width: DialogWidth.Medium,
      panelClass: 'dialog-full-width-height',
      data: {
        entityName: '',
        mode: DialogMode.Add,
        headerPrefix: 'Import Locations',
        affirmativeButtonText: 'Continue',
        scenarioId: this.scenarioId,
        tenantImportUsingGeocoding: this.tenantImportUsingGeocoding
      },
      disableClose: true
    });
  }
}
